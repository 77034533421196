import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import ApiHandler from '../../ApiHandler';
import { RWebShare } from 'react-web-share';

const NewsDetails = ({ match }) => {
    const classes = useStyles();
    const [currentNews, setCurrentNews] = useState();
    const history = useHistory();

    let newsID = match.params._id;

    useEffect(() => {
        fetchNewsData();
    }, [newsID]);

    const fetchNewsData = () => {
        ApiHandler.getNewsDetails(newsID, res => {
            if (res.status == 200) {
                setCurrentNews(res.data.data)
            }
        })
    }

    const formatDate = (date) => {
        let postedDate = new Date(date);
        var relativeTime = moment(postedDate).fromNow();
        return relativeTime;
    }

    return (
        <Dialog fullScreen open >
            <DialogTitle style={{ padding: '10px', boxShadow: '0px 1px 6px 0px #00000012' }}>
                <Grid wrap='nowrap' container alignItems='center'>
                    <ArrowBackIcon
                        onClick={() => {
                            history.push({
                                pathname: '/newsfeeds'
                            })
                        }}
                    />
                </Grid>
            </DialogTitle>
            <DialogContent classes={{ root: classes.newsDetailsDialog }} style={{ padding: '10px' }}>
                <Grid className={`${classes.postContainer} ${classes.mb2}`} item container direction='column'>
                    <Grid className='newsHeader' wrap='nowrap' item container alignItems='center' justifyContent='space-between'>
                        <Grid xs={8} item container direction='column'>
                            <Typography variant='h3'>{currentNews?.newsTitle}</Typography>
                        </Grid>
                        <RWebShare
                            data={{
                                title: currentNews?.newsTitle,
                                text: currentNews?.news_desc,
                                url: window.location.origin + match.url
                            }}
                            onClick={() => console.log("shared successfully!")}
                        >
                            <Button color='primary' className={`${classes.ttc} ${classes.shareBtn}`} startIcon={<ShareOutlinedIcon fontSize='small' />}>
                                Share
                            </Button>
                        </RWebShare>
                    </Grid>
                    <Grid container justifyContent='flex-end' alignItems='center'>
                        <Typography className={classes.mr1} variant='caption'>Posted: {formatDate(currentNews?.createdAt)}</Typography>
                    </Grid>
                    <div className={classes.horizontalLineBreak}></div>
                    <Grid className='newsContent' item container direction='column'>
                        <Typography className={classes.my1} variant='body2'>{currentNews?.newsTitle}</Typography>
                        <Typography className={classes.my1} variant='body2'>Register here: <span style={{ color: '#55c6a9' }}>{currentNews?.registration_url}</span></Typography>
                        <Grid item container direction='column' >
                            <Typography className={classes.my1} variant='body2'>{currentNews?.news_desc}</Typography>
                            <Grid item container alignItems='center' justifyContent='center'>
                                <img src={currentNews?.news_image_url} width='100%' className={classes.mt3} style={{ width: 'fit-content' }} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default NewsDetails