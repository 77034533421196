import React, { useEffect, useState } from 'react'
import { useStyles } from './styles'
import { Dialog, DialogContent, DialogTitle, FormControl, Grid, OutlinedInput, TextField, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SendIcon from '@material-ui/icons/Send';
import bgIcon from '../../assets/Iconwallpaper2.png';
import chatLogoDesktop from '../../assets/chat_home_desktop.svg';

const ChatboxUi = ({ classes }) => {
    return (
        <Grid container justifyContent='space-between' direction='column' className={classes.chatBox}>
            <Grid item container className={classes.my2} >
                <Grid item container justifyContent='flex-start' wrap='nowrap'>
                    <Grid item xs={2} justifyContent='flex-end' container>
                        <img src='https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png' width={30} height={30} />
                    </Grid>
                    <Grid className={`${classes.chatTextWrapperLeft} ${classes.ml2}`} item>
                        <Typography variant='body2'>
                            Hi, my name is Rohit Karodia. I work as a freelance wedding photographer. How can I help you in your career?</Typography>
                    </Grid>
                </Grid>
                <Grid item container justifyContent='flex-end' wrap='nowrap'>
                    <Grid className={`${classes.chatTextWrapperRight} ${classes.mr2}`} item>
                        <Typography variant='body2'>
                            Hi,</Typography>
                    </Grid>
                    <Grid item xs={2} justifyContent='flex-start' container>
                        <img src='https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png' width={30} height={30} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container alignItems='center' className={classes.sendMessage} >
                <TextField
                    variant='outlined'
                    placeholder='Message'
                />
                <SendIcon color='primary' />
            </Grid>
        </Grid>
    )
}

const ChatMobile = () => {
    const classes = useStyles();
    const [isChatBoxOpen, setIsChatBoxOpen] = useState(false);

    useEffect(() => {
        document.body.classList.remove('wallpaper--icon')
    }, []);

    return (
        <>
            <FormControl className={classes.my1} style={{ backgroundColor: '#fff', width: '95%', marginLeft: 7 }} variant="outlined" onClick={() => setOpenSearchedMentorsDialog(true)}>
                <OutlinedInput
                    id="outlined-adornment-weight"
                    //   value={searchQuery}
                    //   onChange={e => {

                    //   }}
                    placeholder='Search...'
                    className={classes.searchinput}
                    startAdornment={<SearchIcon />}
                    //   endAdornment={searchQuery.length > 0 ? <InputAdornment position="end">
                    //     <ClearIcon style={{ cursor: 'pointer', color: '#797979' }}
                    //     //   onClick={() => {
                    //     //     setSearchQuery('');
                    //     //     setOpenJobRoles(postedJobs.filter(item => item.enable_status === true))
                    //     //     setClosedJobRoles(postedJobs.filter(item => item.enable_status === false))
                    //     //   }}
                    //     />
                    //   </InputAdornment> : null}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                        'aria-label': 'weight',
                    }}
                    labelWidth={0}
                />
            </FormControl>
            <Grid container direction='column' className={classes.allChats}>
                <Grid item container wrap='nowrap' className={`${classes.singleChat} ${classes.px1} ${classes.py2}`} onClick={() => setIsChatBoxOpen(true)}>
                    <Grid xs={9} wrap='nowrap' item container>
                        <img className={classes.mr3} src='https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png' width={30} height={30} />
                        <Grid container item direction='column'>
                            <Typography variant='h4'>Akshita Jain</Typography>
                            <Typography className={classes.colorLight} variant='caption'>Thank you!</Typography>
                        </Grid>
                    </Grid>
                    <Grid xs={3} item container alignItems='center' direction='column'>
                        <Typography className={classes.colorLight} variant='caption'>11:30 PM</Typography>
                        <Typography className={classes.messageCount} variant='body2'>1</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog fullScreen open={isChatBoxOpen}
            // onClose={closeMentorshipReqDialog} 
            >
                <DialogTitle style={{ padding: '10px', boxShadow: '0px 1px 6px 0px #00000012' }}>
                    <Grid wrap='nowrap' container alignItems='center'>
                        <ArrowBackIcon className={classes.mr2}
                            onClick={() => setIsChatBoxOpen(false)}
                        />
                        <Typography variant='h3'>Akshita Jain</Typography>
                    </Grid>
                </DialogTitle>
                <DialogContent className={classes.px2}>
                    <ChatboxUi classes={classes} />
                </DialogContent>
            </Dialog>

        </>
    )
}

export default ChatMobile

export const ChatDesktop = () => {
    const classes = useStyles();
    const [isDesktopChatBoxOpen, setIsDesktopChatBoxOpen] = useState(false);

    useEffect(() => {
        document.body.classList.remove('wallpaper--icon')
    }, []);

    return (
        <>
            <Grid container wrap='nowrap' style={{ margin: '10px auto', width: '80%', backgroundColor: '#fff', height: '70vh' }}>
                <Grid style={{ boxShadow: '0px 1px 6px 0px #00000012', padding: 15 }} item xs={4}>
                    <FormControl fullWidth className={classes.my1} style={{ backgroundColor: '#fff' }} variant="outlined"
                    // onClick={() => setOpenSearchedMentorsDialog(true)}
                    >
                        <OutlinedInput
                            id="outlined-adornment-weight"
                            //   value={searchQuery}
                            //   onChange={e => {

                            //   }}
                            placeholder='Search...'
                            className={classes.searchinput}
                            startAdornment={<SearchIcon />}
                            //   endAdornment={searchQuery.length > 0 ? <InputAdornment position="end">
                            //     <ClearIcon style={{ cursor: 'pointer', color: '#797979' }}
                            //     //   onClick={() => {
                            //     //     setSearchQuery('');
                            //     //     setOpenJobRoles(postedJobs.filter(item => item.enable_status === true))
                            //     //     setClosedJobRoles(postedJobs.filter(item => item.enable_status === false))
                            //     //   }}
                            //     />
                            //   </InputAdornment> : null}
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                                'aria-label': 'weight',
                            }}
                            labelWidth={0}
                        />
                    </FormControl>
                    <Grid container direction='column' className={classes.allChats}>
                        <Grid item container wrap='nowrap' className={`${classes.singleChat} ${classes.px1} ${classes.py2}`} onClick={() => setIsDesktopChatBoxOpen(true)}>
                            <Grid xs={9} wrap='nowrap' item container>
                                <img className={classes.mr3} src='https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png' width={30} height={30} />
                                <Grid container item direction='column'>
                                    <Typography variant='h4'>Akshita Jain</Typography>
                                    <Typography className={classes.colorLight} variant='caption'>Thank you!</Typography>
                                </Grid>
                            </Grid>
                            <Grid xs={3} item container alignItems='center' direction='column'>
                                <Typography className={classes.colorLight} variant='caption'>11:30 PM</Typography>
                                <Typography className={classes.messageCount} variant='body2'>1</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid style={{ background: `url(${bgIcon}) no-repeat center center/cover` }} className={classes.position_relative} container alignItems={isDesktopChatBoxOpen ? 'flex-start' : 'center'} justifyContent='center' item xs={8}>
                    {!isDesktopChatBoxOpen ? <Grid item container xs={8} alignItems='center' direction='column'>
                        <img src={chatLogoDesktop} width={120} height={120} />
                        <Typography color='primary' className={`${classes.font_semi_bold} ${classes.my1}`} variant='title15'>Unlock personalized mentorship through Live Chat</Typography>
                        <Typography className={`${classes.textAlignCenter} ${classes.colorLight}`} variant='body1'>Discover the power of real-time mentorship with our new chat feature!</Typography>
                    </Grid> :
                        <Grid item container alignItems='center' direction='column'>
                            <Grid wrap='nowrap' style={{backgroundColor: '#fff', padding: '20px 15px', boxShadow: '0px 1px 6px 0px #00000012'}} container alignItems='center'>
                                <img className={classes.mr3} src='https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png' width={30} height={30} />
                                <Typography variant='h3'>Akshita Jain</Typography>
                            </Grid>
                            <ChatboxUi classes={classes} />
                        </Grid>}
                </Grid>

            </Grid>
        </>
    )
}