import { makeStyles, useTheme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    tabsRoot: {
        backgroundColor: theme.palette.primary.contrastText,
        '& > div': {
            '& > div': {
                justifyContent: 'center',
                overflowX: 'scroll',
                scrollbarWidth: 'none'
            }
        }
    },
    tabRoot: {
        textTransform: 'capitalize'
    },
    tabsIndicator: {
        backgroundColor: theme.palette.primary.main
    },
    activeTab: {
        color: theme.palette.primary.main
    },
    mentorshipTabsContainer: {
        paddingBottom: 60,
        paddingLeft: 8,
        paddingRight: 8,
        '@media (min-width: 700px)': {
            // padding: '10px 30px 60px 30px'
            width: '75vw',
            margin: 'auto'
        },
    },
    tileContainer: {
        border: '1px solid #d9d9d9',
        width: '100%',
        position: 'relative',
        backgroundColor: theme.palette.primary.contrastText,
        padding: 10,
        borderRadius: 6,
        boxShadow: '0px 1px 6px 0px #00000012',
        position: 'relative',
        '@media (min-width: 600px)': {
            width: '80%',
            margin: 'auto'
        },
        // '@media (min-width: 700px)': {
        //     width: '60%',
        //     margin: 'auto'
        // },
    },
    tileContainerBanner: {
        border: '1px solid #d9d9d9',
        width: '100%',
        position: 'relative',
        backgroundColor: theme.palette.primary.contrastText,
        padding: 10,
        borderRadius: 6,
        boxShadow: '0px 1px 6px 0px #00000012',
        position: 'relative',
        '@media (min-width: 600px)': {
            width: '60%',
            margin: '60px auto 0px auto'
        },
        // '@media (min-width: 700px)': {
        //     width: '60%',
        //     margin: 'auto'
        // },
    },
    mentorSearchbar: {
        backgroundColor: '#fff',
        width: '95%',
        marginLeft: 7,
        '@media (min-width: 600px)': {
            width: '98%'
        },
    },
    sliderContainerMentors: {
        width: 250,
        // position: 'relative',
    },
    sliderContainerMentees: {
        width: 150,
        // position: 'relative'
    },
    sectorTiles: {
        boxShadow: '0px 1px 6px 0px #00000012',
        backgroundColor: theme.palette.primary.contrastText,
        borderRadius: 5,
        minWidth: 150
    },
    horizontalLineBreak: {
        height: 1,
        backgroundColor: '#D9D9D9',
        width: '100%',
        margin: '10px 0'
    },
    profileSectionContainer: {
        border: '1px solid #d9d9d9',
        boxShadow: '0px 1px 6px 0px #00000012',
        padding: '5px 10px',
        borderRadius: 5,
        backgroundColor: theme.palette.primary.contrastText,
        margin: '0 auto',
        width: '95%'
    },
    editProfileDetailsIcon: {
        color: theme.palette.common.grey,
        fontSize: '1.1rem',
        marginLeft: '5px',
        '&:hover': {
            cursor: 'pointer',
        }
    },
    addProfileDetailIcon: {
        '&:hover': {
            cursor: 'pointer',
        }
    },
    editDeleteDetailsContainer: {
        position: 'absolute',
        right: 0,
        top: '5%',
        '& svg': {
            color: theme.palette.common.grey,
            fontSize: '1.2rem'
        },
        '&:hover': {
            cursor: 'pointer',
        }
    },
    dialogHeader: {
        padding: '10px',
        boxShadow: '0px 1px 6px 0px #00000012'
    },
    imgWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    profilePicUpload: {
        border: `1px dotted ${theme.palette.primary.main}`,
        '& label': {
            position: 'absolute',
            '& > span': {
                border: 'none',
                '&:hover': {
                    border: 'none'
                }
                ,
                '& > span': {
                    // display: 'flex', 
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    // border: 'none'
                    '& > span': {
                        textAlign: 'center',
                        fontSize: '0.75rem',
                        fontWeight: 400
                    }
                }
            }
        }
    },
    uploadMentorImgIcon: {
        position: 'absolute',
        bottom: '-20px',
        right: '15px',
        zIndex: 9,
        backgroundColor: '#fff',
        borderRadius: '50%',
        boxShadow: '0px 1px 6px 0px #00000012',
        '& span': {
            padding: 3
        }
    },
    requiredFields: {
        color: theme.palette.common.red
    },
    sendReqBtn: {
        borderRadius: 50,
        position: 'sticky',
        bottom: '3%',
        left: '50%',
        transform: 'translateX(-50%)',
        fontSize: 13
    },
    requestEditBtn: {
        '@media (min-width: 700px)': {
            position: 'absolute',
            top: 0,
            right: '3%'
        },
    },
    mobileNotificationIcon: {
        position: 'absolute',
        top: 0,
        right: 43,
        zIndex: 9,
        padding: '12px 0'
    },
    notificationDialogRoot: {
        padding: 0,
        '&:first-child': {
            paddingTop: 0
        }
    },
    pageInfo: {
        display: 'flex',
        justifyContent: 'flex-start',
        paddingTop: 'calc(80vh - 500px);',
        alignItems: 'center',
        flexDirection: 'column',
        height: '75vh',
    },
    pageInfoText: {
        color: "#555",
        lineHeight: '2em'
    },
    buttonRed: {
        color: theme.palette.common.redDark
    },
    pageButton: {
        textTransform: "none",
        width: 100,
        height: 35,
        marginTop: 20,
    },
    pageButton_pri: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
        borderRadius: 5,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    sortingChip: {
        minWidth: 45,
        height: 26,
        marginRight: 5,
        color: '#afafaf',
        border: '1px solid #afafaf',
        backgroundColor: '#fff'
    },
    sortingChipActive: {
        minWidth: 45,
        height: 26,
        marginRight: 5,
        color: '#55c6a9',
        border: '1px solid #55c6a9',
        backgroundColor: '#fff'
    },
    sortingChipClicked: {
        backgroundColor: '#fff'
    },

    // Chat 
    singleChat: {
        backgroundColor: '#fff',
        boxShadow: '0px 1px 6px 0px #00000012',

    },
    messageCount: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        borderRadius: '50%',
        padding: '0px 5px'
    },
    dialogPaper: {
        '@media (min-width: 700px)': {
            width: '70%',
            height: '80vh'
        },
    },
    sendMessage: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        boxShadow: '0px 1px 6px 0px #00000012',
        padding: '10px 0',
        backgroundColor: theme.palette.primary.contrastText,
        '& div': {
            width: '88%',
            marginLeft: 10
        }
    },
    chatTextWrapperLeft: {
        '& p': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            padding: 10,
            position: 'relative',
            borderRadius: '0 5px 5px',
            width: '90%',
            '&::before': {
                content: '" "',
                position: 'absolute',
                display: 'block',
                zIndex: 9,
                borderTop: '0px solid transparent',
                borderRight: `7px solid ${theme.palette.primary.main}`,
                borderBottom: '12px solid #0000',
                top: 0,
                left: -6,
                borderRadius: '3px 0'
            },
        },
        marginBottom: 10,
    },
    chatTextWrapperRight: {
        '& p': {
            backgroundColor: theme.palette.primary.contrastText,
            color: theme.palette.common.blackDark,
            padding: 10,
            position: 'relative',
            borderRadius: '5px 0 5px 5px',
            boxShadow: '0px 1px 6px 0px #00000012',
            width: '90%',
            '&::before': {
                content: '" "',
                position: 'absolute',
                display: 'block',
                zIndex: 9,
                borderTop: '0px solid transparent',
                borderLeft: `7px solid ${theme.palette.primary.contrastText}`,
                borderBottom: '12px solid #0000',
                boxShadow: '0px 0px 0px 0px #00000012',
                top: 0,
                right: -6,
                borderRadius: '0 3px'
            }
        },
    },

    reqPending: {
        position: 'absolute',
        right: '3%',
        top: '6%',
        '@media (min-width: 700px)': {
            bottom: '10%',
            top: 'initial'
        },
    },
    tilesConnectBtn: {
        position: 'absolute',
        bottom: 8,
        left: '50%',
        transform: 'translateX(-50%)',
        width: '80%'
    },

    sliderArrow: {
        width: 40,
        height: 40,
        borderRadius: '50%',
        background: '#f6fffde3',
        color: '#555',
        position: 'absolute',
        top: '40%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 2,
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
        cursor: 'pointer',
        fontSize: 30,
        '&:hover': {
            background: 'white',
            color: '#5bc2a8',
            boxShadow: '0 0 8px rgba(0,0,0,0.15)',
        },
    },
    sliderArrow_L: {
        left: 10,
    },
    sliderArrow_R: {
        right: 10,
    },
    nameInitials: {
        fontSize: 20,
        fontWeight: 400,
        backgroundColor: '#fff',
        textAlign: 'center',
        borderRadius: '50%',
        color: '#55c6a9',
        boxShadow: '0px 1px 6px 0px #00000012',
        margin: '0px 10px 0px 0px',
        padding: 10,
        border: '1px solid',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 45,
        height: 45
    },
    nameInitialsAbs: {
        marginRight: 10,
        position: 'absolute',
        bottom: -18,
        left: '50%',
        transform: 'translateX(-50%)'
    },
    days: {
        width: '25px',
        height: '25px',
        border: '1px solid #5BC2A8',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#797979',
        borderRadius: '2px',
        marginRight: '1em',
        boxShadow: '0px 2px 4px rgba(20, 150, 123, 0.1)',
        '& label': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '0.72em',
            wordBreak: 'break-word',
            width: 'inherit',
            height: 'inherit',
            borderRadius: '3px',
            margin: '-1px 0 -1px -1px',
            '&:hover': {
                cursor: 'pointer'
            },
        },
        '& input[type="checkbox"]': {
            display: 'none',
            '&:checked + label': {
                color: '#fff',
                backgroundColor: '#5BC2A8',
            }
        },
    },
    timeFormControl: {
        flexDirection: 'row !important',
    },

    weekday_chip: {
        height: 25
    },
    allEvents: {
        paddingBottom: 100, 
        marginTop: 10, 
        display: 'grid', 
        gridTemplateColumns: '1fr 1fr 1fr', 
        width: '95%', 
        margin: 'auto', 
        gap: 10,
        '@media (max-width: 850px)': {
            gridTemplateColumns: '1fr 1fr'
        },
        '@media (max-width: 600px)': {
            gridTemplateColumns: 'auto'
        },
    },
    eventContainer: {
        border: '1px solid #d9d9d9',
        width: '80%',
        position: 'relative',
        backgroundColor: theme.palette.primary.contrastText,
        padding: '20px',
        borderRadius: 6,
        boxShadow: '0px 1px 6px 0px #00000012',
        position: 'relative',
        margin: 'auto',
        '@media (min-width: 600px)': {
            width: '46vw',
            margin: 0
        },
        '@media (min-width: 700px)': {
            width: '46vw',
            margin: 0
        },
        '@media (min-width: 850px)': {
            width: '100%',
            margin: 0
        },
    },
    eventTextFields: {
        '& label': {
            transform: 'translate(14px, 10px) scale(1)'
        }
    },
    eventBtns: {
        borderRadius: 30,
        height: 30
    },
    eventJoinBtn: {
        color: 'blue',
        border: 'none',
        backgroundColor: '#0000ff14'
    },
    eventCancelBtn: {
        color: '#DA2932',
        border: 'none',
        backgroundColor: '#da293214'
    },
    updateEventDialogPaper: {
        minWidth: '46vw',
        maxWidth: 'inherit'
    },

    //utility classes
    colorLight: {
        color: theme.palette.common.grey
    },
    position_relative: {
        position: 'relative'
    },
    boxShadowImg: {
        boxShadow: '0px 1px 6px 0px #00000012'
    },
    cursor_pointer: {
        cursor: 'pointer',
        '&:hover': {
            cursor: 'pointer',
            boxShadow: '0px 1px 12px 6px #00000012'
        }
    },
    desktopContainer: {
        '@media (min-width: 800px)': {
            width: '90%',
            margin: 'auto'
        },
    },
    min_width_50: {
        minWidth: '50vw'
    },
    w50: {
        width: '50% !important'
    },
    w100: {
        width: '100% !important'
    },
    border_rounded: {
        borderRadius: '50%'
    },
    my1: {
        marginTop: 5,
        marginBottom: 5
    },
    my2: {
        marginTop: 10,
        marginBottom: 10
    },
    my3: {
        marginTop: 15,
        marginBottom: 15
    },
    mx1: {
        marginLeft: 5,
        marginRight: 5
    },
    mx2: {
        marginLeft: 10,
        marginRight: 10
    },
    mx3: {
        marginLeft: 15,
        marginRight: 15
    },
    mr1: {
        marginRight: '5px !important'
    },
    mr2: {
        marginRight: '10px !important'
    },
    mr3: {
        marginRight: '15px !important'
    },
    ml1: {
        marginLeft: '5px !important'
    },
    ml2: {
        marginLeft: '10px !important'
    },
    ml3: {
        marginLeft: '15px !important'
    },
    mt1: {
        marginTop: '5px !important'
    },
    mt2: {
        marginTop: '10px !important'
    },
    mt3: {
        marginTop: '15px !important'
    },
    mb1: {
        marginBottom: '5px !important'
    },
    mb2: {
        marginBottom: '10px !important'
    },
    mb3: {
        marginBottom: '15px !important'
    },
    p0: {
        padding: 0
    },
    px1: {
        paddingLeft: 5,
        paddingRight: 5
    },
    px2: {
        paddingLeft: 10,
        paddingRight: 10
    },
    px3: {
        paddingLeft: 15,
        paddingRight: 15
    },
    py1: {
        paddingTop: 5,
        paddingBottom: 5
    },
    py2: {
        paddingTop: 10,
        paddingBottom: 10
    },
    py3: {
        paddingTop: 15,
        paddingBottom: 15
    },
    pr1: {
        paddingRight: 5
    },
    pr2: {
        paddingRight: 10,
    },
    pr3: {
        paddingRight: 15,
    },
    pl1: {
        paddingLeft: 5
    },
    pl2: {
        paddingLeft: 10,
    },
    pl3: {
        paddingLeft: 15,
    },
    ttc: {
        textTransform: 'capitalize'
    },
    ttn: {
        textTransform: 'none'
    },
    textAlignCenter: {
        textAlign: 'center'
    },
    font_bold: {
        fontWeight: 700
    },
    font_semi_bold: {
        fontWeight: 500
    },
    font_non_bold: {
        fontWeight: 400
    },
    //media queries
    desktopView: {
        display: 'initial',
        '@media (max-width: 600px)': {
            display: 'none'
        },
    },
    mobileView: {
        display: 'block',
        '@media (min-width: 600px)': {
            display: 'none'
        },
    },
}));