import { makeStyles, useTheme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    newsFeedsContainer: {
        padding: '65px 10px',
        '@media (min-width: 600px)': {
            maxWidth: '80vw',
            margin: 'auto'
        },
        '@media (min-width: 800px)': {
            maxWidth: '60vw',
            margin: 'auto'
        },
    },
    postContainer: {
        padding: 10,
        boxShadow: '0px 1px 6px 0px #00000012',
        backgroundColor: '#fff',
        position: 'relative'
    },
    mobileNotificationIcon: {
        position: 'absolute',
        top: 0,
        right: 43,
        zIndex: 9,
        padding: '12px 0'
    },
    shareBtn: {
        fontSize:14,
        fontWeight: 400,
        '& span': {
            '& span': {
                '& svg': {
                    fontSize: '14px !important'
                }
            }
        }
    },
    bannerImgContainer: {
        height: 100,
        // position: 'relative',
        // display: 'inline-block',
        overflow: 'hidden',
        // margin: 0,
        '& > img': {
            display: 'block',
            minHeight: '100%',
            minWidth: '100%',
        }
    },
    newsDetailsDialog: {
        '@media (min-width: 600px)': {
            width: '80vw',
            margin: 'auto'
        },
        '@media (min-width: 800px)': {
            width: '60vw',
            margin: 'auto'
        },
    },
    //utility classes
    colorLight: {
        color: theme.palette.common.grey
    },
    horizontalLineBreak: {
        height: 1,
        backgroundColor: '#D9D9D9',
        width: '100%',
        margin: '10px 0'
    },
    position_relative: {
        position: 'relative'
    },
    cursor_pointer: {
        cursor: 'pointer',
        '&:hover': {
            cursor: 'pointer',
            boxShadow: '0px 1px 12px -6px #00000012'
        }
    },
    my1: {
        marginTop: 5,
        marginBottom: 5
    },
    my2: {
        marginTop: 10,
        marginBottom: 10
    },
    my3: {
        marginTop: 15,
        marginBottom: 15
    },
    mx1: {
        marginLeft: 5,
        marginRight: 5
    },
    mx2: {
        marginLeft: 10,
        marginRight: 10
    },
    mx3: {
        marginLeft: 15,
        marginRight: 15
    },
    mr1: {
        marginRight: '5px !important'
    },
    mr2: {
        marginRight: '10px !important'
    },
    mr3: {
        marginRight: '15px !important'
    },
    ml1: {
        marginLeft: '5px !important'
    },
    ml2: {
        marginLeft: '10px !important'
    },
    ml3: {
        marginLeft: '15px !important'
    },
    mt1: {
        marginTop: '5px !important'
    },
    mt2: {
        marginTop: '10px !important'
    },
    mt3: {
        marginTop: '15px !important'
    },
    mb1: {
        marginBottom: '5px !important'
    },
    mb2: {
        marginBottom: '10px !important'
    },
    mb3: {
        marginBottom: '15px !important'
    },
    p0: {
        padding: 0
    },
    px1: {
        paddingLeft: 5,
        paddingRight: 5
    },
    px2: {
        paddingLeft: 10,
        paddingRight: 10
    },
    px3: {
        paddingLeft: 15,
        paddingRight: 15
    },
    py1: {
        paddingTop: 5,
        paddingBottom: 5
    },
    py2: {
        paddingTop: 10,
        paddingBottom: 10
    },
    py3: {
        paddingTop: 15,
        paddingBottom: 15
    },
    pr1: {
        paddingRight: 5
    },
    pr2: {
        paddingRight: 10,
    },
    pr3: {
        paddingRight: 15,
    },
    pl1: {
        paddingLeft: 5
    },
    pl2: {
        paddingLeft: 10,
    },
    pl3: {
        paddingLeft: 15,
    },
    ttc: {
        textTransform: 'capitalize'
    },
    ttn: {
        textTransform: 'none'
    },
    textAlignCenter: {
        textAlign: 'center'
    },
    font_bold: {
        fontWeight: 700
    },
    font_semi_bold: {
        fontWeight: 500
    },
    font_non_bold: {
        fontWeight: 400
    },
    textSmall: {
        fontSize: 12
    },
    //media queries
    desktopView: {
        display: 'initial',
        '@media (max-width: 600px)': {
            display: 'none'
        },
    },
    mobileView: {
        display: 'initial',
        '@media (min-width: 600px)': {
            display: 'none'
        },
    },
}));