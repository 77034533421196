// src/googleApi.js
import { gapi } from 'gapi-script';

const CLIENT_ID = '254248855159-lbmtg5qh6gdmnlp3mefkgjcid35prjkg.apps.googleusercontent.com';
// const API_KEY = 'AIzaSyAcUA_eeLxXevJsKZqmsDbkZBgjs9yPjLA';
const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"];
const SCOPES = "https://www.googleapis.com/auth/calendar";

export const initClient = () => {
  gapi.load('client:auth2', () => {
    gapi.client.init({
      // apiKey: API_KEY,
      clientId: CLIENT_ID,
      discoveryDocs: DISCOVERY_DOCS,
      scope: SCOPES
    }).then(() => {
      console.log('GAPI client initialized.');
    });
  });
};

export const authenticate = () => {
  const authInstance = gapi.auth2.getAuthInstance();
  return authInstance.signIn({
    prompt: 'select_account'
  })
};

export const createEvent = (event) => {
  return gapi.client.calendar.events.insert({
    calendarId: 'primary',
    resource: event,
    conferenceDataVersion: 1
  }).then(response => response.result);
};

export function checkIfUserIsSignedIn() {
  const authInstance = gapi.auth2.getAuthInstance();

  if (authInstance.isSignedIn.get()) {
    // User is already signed in
    const googleUser = authInstance.currentUser.get();
    const profile = googleUser.getBasicProfile();
    const email = profile.getEmail(); // User's email
    const googleId = profile.getId(); // User's unique Google ID
    return true;
  } else {
    return false;
    // User is not signed in
  }
}

export function signOutUser() {
  const authInstance = gapi.auth2.getAuthInstance();

  authInstance.signOut().then(() => {
    // You can also redirect the user or update the UI here
  }).catch(error => {
    console.error('Error signing out:', error);
  });
}