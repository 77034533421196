import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogContent, DialogTitle, FormControl, FormGroup, Grid, InputLabel, MenuItem, NativeSelect, Select, TextField, Typography } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createEvent } from '../../googleApi';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const MeetingScheduler = (props) => {
    const { classes } = props;
    const [date, setDate] = useState(new Date());
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [meetingDuration, setMeetingDuration] = useState(30);
    const [eventTitle, setEventTitle] = useState('');
    const [eventDescription, setEventDescription] = useState('');
    const [availableSlots, setAvailableSlots] = useState([]);
    const [allEvents, setAllEvents] = useState([]);

    function fetchUserEvents() {
        const calendarId = 'primary';

        gapi.client.calendar.events.list({
            calendarId: calendarId,
            timeMin: (new Date()).toISOString(),
            showDeleted: false,
            singleEvents: true,
            maxResults: 10,
            orderBy: 'startTime'
        }).then(response => {
            const events = response.result.items;

            // Filter events by the unique identifier
            const myAppEvents = events.filter(event => {
                // return event.description && event.description.includes('Scheduled via MyApp');
                // Or use extendedProperties if that's where you stored the identifier
                if (props.displayMentorProile) {
                    return event.extendedProperties && event.extendedProperties.private && event.extendedProperties.private.appId === 'meetMentorship' && event.extendedProperties.private.type === 'mentorToMentee';
                } else {
                    return event.extendedProperties && event.extendedProperties.private && event.extendedProperties.private.appId === 'meetMentorship' && event.extendedProperties.private.type === 'menteeToMentor';
                }
            });

            if (myAppEvents.length > 0) {
                setAllEvents(myAppEvents);
                myAppEvents.forEach(event => {
                    const start = event.start.dateTime || event.start.date;
                });
            } else {
                console.log('No events found for MyApp.');
            }
        }).catch(error => {
            console.error('Error fetching events:', error);
        });
    }

    useEffect(() => {
        fetchUserEvents();
    }, [])
    

    const handleCreateEvent = () => {
        // Combine date with time to create the start and end datetime strings
        const startDateTime = `${startTime}`;
        const endDateTime = `${endTime}`;

        const event = {
            summary: eventTitle,
            description: eventDescription,
            start: {
                dateTime: startDateTime,
                timeZone: 'Asia/Kolkata',
            },
            end: {
                dateTime: endDateTime,
                timeZone: 'Asia/Kolkata',
            },
            conferenceData: {
                createRequest: {
                    requestId: 'sample123',
                    conferenceSolutionKey: {
                        type: 'hangoutsMeet'
                    }
                }
            },
            attendees: [
                {
                    email: props.attendee_email,
                    responseStatus: 'needsAction',
                    displayName: props.currentUser.full_name,
                }
            ],
            extendedProperties: {
                private: {
                    appId: 'meetMentorship', // Unique identifier for events created by our app
                    imgUrl: props.currentUser.user_image_url || '',
                    type: props.type
                }
            },
            reminders: {
                useDefault: false,
                overrides: [
                    { method: 'email', minutes: 10 },  // Send an email 10 minutes before the event
                    { method: 'popup', minutes: 10 },  // Show a popup 10 minutes before the event
                ]
            },
            guestsCanModify: false,
            sendUpdates: 'all',
            sendNotifications: true,
        };

        createEvent(event).then(response => {
            if (response.status == 'confirmed') {
                props.handleSnackBar('Event generated successfully');
                props.setOpenMeetingScheduler(false);
            }
        });
    };

    const TimeSlotSelector = ({ selectedDate, onSlotSelect, duration, allEvents }) => {
        const [timeSlots, setTimeSlots] = useState([]);
        const [selectedSlot, setSelectedSlot] = useState('');

        useEffect(() => {
            if (selectedDate && duration) {
                generateTimeSlots();
            }
        }, [selectedDate, duration]);

        // Function to generate time slots
        const generateTimeSlots = () => {
            const slots = [];
            const currentDate = new Date();
            const isToday = new Date(selectedDate).toDateString() === currentDate.toDateString(); // Check if the selected date is today

            const startOfDay = new Date(selectedDate);
            startOfDay.setHours(9, 0, 0, 0); //starting time range

            const endOfDay = new Date(selectedDate);
            endOfDay.setHours(19, 0, 0, 0); //ending time range

            let currentTime = new Date(startOfDay);

            // Helper function to check if a slot conflicts with any existing events
            const isSlotConflict = (slotStart, slotEnd, events) => {
                return events.some(event => {
                    const eventStart = new Date(event.start.dateTime);
                    const eventEnd = new Date(event.end.dateTime);
                    return (slotStart < eventEnd && slotEnd > eventStart); // Check for overlap
                });
            };

            // Generate time slots based on the duration
            while (currentTime <= endOfDay) {
                const slotStart = new Date(currentTime);
                currentTime.setMinutes(currentTime.getMinutes() + duration); // Increment by the given duration
                const slotEnd = new Date(currentTime);

                // Skip past times if scheduling for today
                if (isToday && slotStart < currentDate) {
                    continue;
                }

                // Skip slots that conflict with existing events
                if (isSlotConflict(slotStart, slotEnd, allEvents)) {
                    continue;
                }

                if (slotEnd <= endOfDay) {
                    slots.push({
                        start: slotStart.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                        end: slotEnd.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                        slotStart, // Save the actual Date object for conversion
                        slotEnd, // Save the actual Date object for conversion
                    });
                }
            }

            setTimeSlots(slots);
        };

        // Handle selecting a time slot and pass it in Google Calendar format
        const handleSlotSelect = (event) => {
            const selectedSlot = event.target.value;
            // setSelectedSlot(selectedSlot); // Update selected slot

            // Convert to Google Calendar RFC3339 format
            const startDateTime = combineDateTime(selectedDate, selectedSlot.slotStart);
            const endDateTime = combineDateTime(selectedDate, selectedSlot.slotEnd);

            // Pass the RFC3339 formatted start and end time to parent component
            onSlotSelect({
                start: startDateTime,
                end: endDateTime,
            });
        };

        // Helper function to combine date and time and convert to RFC3339
        const combineDateTime = (date, time) => {
            const combined = new Date(date);
            combined.setHours(time.getHours(), time.getMinutes(), 0, 0);

            // Convert to RFC3339 format: YYYY-MM-DDTHH:mm:ss.sssZ
            return combined.toISOString();
        };

        return (
            <>
                {timeSlots.length > 0 && <FormControl className={classes.mt2} variant="outlined" fullWidth>
                    <InputLabel id="time-slot-label">Available Time Slot*</InputLabel>
                    <Select
                        labelId="time-slot-label"
                        id="time-slot"
                        value={selectedSlot}
                        onChange={e => {
                            setSelectedSlot(e.target.value);
                            handleSlotSelect(e);
                        }}
                        label="Select Time Slot"
                    >
                        {timeSlots.map((slot, index) => (
                            <MenuItem key={index} value={slot}>
                                {`${slot.start} - ${slot.end}`}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>}
            </>
        );
    };

    const handleSlotSelect = (selectedSlot) => {
        setStartTime(selectedSlot.start);
        setEndTime(selectedSlot.end);
    };


    return (
        <>
            <Dialog fullScreen={!props.matchesWidth ? true : false} open={props.openMeetingScheduler}>
                <DialogTitle>
                    {props.currentUser && <Grid container alignItems='center'>
                        <ArrowBackIcon className={`${classes.mr3} ${classes.cursor_pointer}`} onClick={() => props.setOpenMeetingScheduler(false)} />
                        {props.currentUser.user_image_url ? <img className={`${classes.mr3} ${classes.border_rounded}`} src={props.currentUser.user_image_url} width={45} height={45} /> :
                            <p className={`${classes.nameInitials}`}> {props.setInitials(props.currentUser)} </p>}
                        <Typography className={classes.ttc}>{props.currentUser.full_name ? props.currentUser.full_name : props.currentUser.user_first_name + ' ' + props.currentUser.user_last_name}</Typography>
                    </Grid>}
                </DialogTitle>
                <DialogContent>
                    {/****** datepicker ******/}
                    <Grid
                        item
                        container
                        justifyContent="space-between"
                        alignItems="flex-start"
                        direction="row"
                        style={{ position: 'relative', marginBottom: 30 }}
                    >
                        <TextField label='Event Title*' fullWidth variant='outlined' className={`${classes.eventTextFields} ${classes.my2}`} value={eventTitle} onChange={(e) => setEventTitle(e.target.value)} />
                        <TextField label='Event Description*' fullWidth variant='outlined' className={`${classes.eventTextFields} ${classes.my3}`} value={eventDescription} multiline minRows={4} onChange={(e) => setEventDescription(e.target.value)} />
                        <TextField className={`${classes.eventTextFields} ${classes.my3}`} fullWidth placeholder='Duration*' label='Event duration (minutes)' variant='outlined' value={meetingDuration} onChange={(e) => setMeetingDuration(e.target.value)} />
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid className={classes.my3} item xs={12}>
                                <FormControl component="fieldset" style={{ width: '100%' }}>
                                    <DatePicker
                                        autoOk
                                        openTo="date"
                                        variant="inline"
                                        format="dd MMM yyyy"
                                        views={["date", "month", "year"]}
                                        id={"dob_id"}
                                        label='Event Date*'
                                        // value={dob ? dob : null}
                                        // minDate={new Date("2000-01-01")}
                                        value={date}
                                        onChange={(date) => {
                                            let newItem = moment(date).format();
                                            // newItem = moment(date).format('DD-MMM-YYYY');
                                            setDate(date);
                                        }}
                                        inputVariant="outlined"
                                    />
                                </FormControl>
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <TimeSlotSelector selectedDate={date} duration={meetingDuration} onSlotSelect={handleSlotSelect} allEvents={allEvents} />
                        <Grid className={classes.mt3} item container justifyContent='flex-end'>
                            <Button className={`${classes.ttc} ${classes.font_non_bold} ${classes.mt3}`} variant='contained' color='primary' size='small'
                                onClick={handleCreateEvent}
                            >Schedule Event</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default MeetingScheduler