import React, { useEffect, useState } from 'react'
import BottomAppBar from '../../components/BottomAppBar';
import SignInUpDrawer from '../../components/SignInDrawer';
import LogoutDrawer from '../../components/LogoutDrawer';
import { AppBar, Badge, Box, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, InputLabel, List, MenuItem, OutlinedInput, Radio, RadioGroup, Select, Tab, Tabs, TextField, TextareaAutosize, Toolbar, Typography } from '@material-ui/core';
import logo from '../../assets/logo_header.png';
import { useStyles } from './styles';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import PropTypes from 'prop-types';
import RecommendedMentorsList, { ConnectedMenteesList, ConnectionsList, RecentMenteesList, RecentMentorsList, RecommendedMenteesList, RequestReceived, RequestReceivedByMentors, RequestSent, RequestSentByMentors, SearchedMenteesList, SearchedMentorsList, SectorTiles } from './TilesItem';
import SearchIcon from '@material-ui/icons/Search';
import mentorBanner from '../../assets/be_mentor_new.png';
import menteeBanner from '../../assets/be_mentee_new.png';
import BeMentorDesktop from '../../assets/Be_a_mentor_banner_desktop.svg'
import chatBanner from '../../assets/chatBanner.png';
import bgIcon from '../../assets/Iconwallpaper2.png';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FullProfile from './FullProfile';
import MenteeFullProfile from './MenteeFullProfile';
import { useHistory, useLocation } from 'react-router-dom';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import Chat, { ChatDesktop } from './Chat';
import ApiHandler from '../../ApiHandler';
import Wallpaper from '../../assets/Iconwallpaper2.png';
import BeMentorImg from '../../assets/BeMentor.svg';
import BeMenteeImg from '../../assets/BeMentee.svg';
import ViewMenteeProfileImg from '../../assets/view_mentee_profile_new.png';
import ViewMenteeProfileDesktop from '../../assets/view_mentee_desktop.png';
import ViewMentorProfileImg from '../../assets/view_mentor_profile.png';
import { Autocomplete, Rating } from '@material-ui/lab';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Notifications from '../../components/Notifications';
import SnackBarGeneric from '../../components/SnackBarGeneric';
import ClearIcon from '@material-ui/icons/Clear';
import LinkedIn from '../../assets/linkedin.png';
import Instagram from '../../assets/insta.png';
import Facebook from '../../assets/fb.png';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import ChatMobile from './Chat';
import Location from './Location';
import mentorSignInBanner from '../../assets/signIn_mentorship.png'
import AllEvents from './AllEvents';
import { initClient } from '../../googleApi';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
function a12yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export const MentorSearchBar = ({ classes, searchedQuery, setSearchedQuery, searchMentors, displayMentorProile, setOpenSearchedMentorsDialog }) => {

    var delay;
    return (
        <>
            <FormControl className={`${classes.my2} ${classes.mentorSearchbar}`} variant="outlined">
                <OutlinedInput
                    id="outlined-adornment-weight"
                    value={searchedQuery}
                    onChange={e => {
                        setSearchedQuery(e.target.value);
                        clearTimeout(delay);
                        if (e.target.value.length > 2) {
                            delay = setTimeout(() => {
                                searchMentors();
                            }, 1500);
                        }
                    }}
                    placeholder={!displayMentorProile ? 'Search mentors' : 'Search mentees by name'}
                    className={classes.searchinput}
                    autoFocus
                    startAdornment={<SearchIcon onClick={() => searchMentors()} />}
                    endAdornment={searchedQuery.length > 0 ? <InputAdornment position="end">
                        <ClearIcon style={{ cursor: 'pointer', color: '#797979' }}
                            onClick={() => {
                                setSearchedQuery('');
                            }}
                        />
                    </InputAdornment> : null}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                        'aria-label': 'weight',
                    }}
                    labelWidth={0}
                // onKeyDown={(e) => {
                //     if (e.key === 'Enter') searchMentors();
                // }}
                />
            </FormControl>
        </>
    )
}

const MentorshipHome = () => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const matchesWidth = useMediaQuery('(min-width:700px)');
    const matchesWidth2 = useMediaQuery('(min-width:600px)');

    const [searchedQuery, setSearchedQuery] = useState('');
    const [navValue, setNavValue] = useState(2);
    let sessionCookie = process.env.REACT_APP_NODE_ENV === 'development' ? true : document.cookie.indexOf('connect.sid') === -1 ? null : true;
    //let sessionCookie = document.cookie.indexOf('connect.sid') === -1 ? null : true;
    const [isSignedIn, setIsSignedIn] = useState((sessionCookie && localStorage.getItem("is_logged_in")) || false);
    const [jobseeker_id, setJobseeker_id] = useState(localStorage.getItem('jobseeker_id') || "");
    const [userDetails, setUserDetails] = useState('');
    const [isMentee, setIsMentee] = useState(JSON.parse(localStorage.getItem("is_mentee")) || false);
    const [isMentor, setIsMentor] = useState(JSON.parse(localStorage.getItem("is_mentor")) || false);
    const [displayMenteeProile, setDisplayMenteeProile] = useState(location && location?.state?.displayMenteeProile ? location.state.displayMenteeProile : false);
    const [displayMentorProile, setDisplayMentorProile] = useState(location && location?.state?.displayMentorProile ? location.state.displayMentorProile : false);
    const [requestChipValue, setRequestChipValue] = useState(location && location?.state?.requestedChipVal ? location.state.requestedChipVal : 0);
    const [roles, setRoles] = useState([]);
    const [topTenRoles, setTopTenRoles] = useState([]);
    const [mentorsListByRole, setMentorsListByRole] = useState([]);
    const [menteesListByRole, setMenteesListByRole] = useState([]);
    const [recommendedMentors, setRecommendedMentors] = useState([]);
    const [recommendedMentees, setRecommendedMentees] = useState([]);
    const [autocompleteMentorsList, setAutocompleteMentorsList] = useState([]);
    const [intrestedMentorshipRole, setIntrestedMentorshipRole] = useState('');
    const [requestMessageToMentor, setRequestMessegeToMentor] = useState('');
    const [requestMessageToMentee, setRequestMessegeToMentee] = useState('');
    const [openRequestSuccessAlert, setOpenRequestSuccesAlert] = useState('');
    // const [mentorLocation, setMentorLocation] = React.useState([]);
    const [locationValue, setLocationValue] = React.useState();
    const [latitude, setLatitude] = React.useState();
    const [longitude, setLongitude] = React.useState();
    const [selectedMentorImg, setSelectedMentorImg] = useState(null);
    const [mentorshipScrQues, setMentorshipScrQues] = useState({
        "mentor_id": jobseeker_id,
        "topics": "",
        "mentor_role": [],
        "work_exp": "",
        "curr_org": "",
        "curr_role": "",
        "industry": "",
        "prev_companies": "",
        "facebook": "",
        "instagram": "",
        "linkedin": "",
        "frequency": "",
        "no_of_hours": "",
        "days_of_week": [],
        "portfolio_url": ""
    });
    const [mentorDetails, setMentorDetails] = useState({
        "mentor_id": jobseeker_id,
        "first_name": "",
        "last_name": "",
        "email": ""
    });
    const [mentorFormErrors, setMentorFormErrors] = useState({});
    const [isFormSubmit, setIsFormSubmit] = useState(false);
    const [currentMentorshipId, setCurrentMentorshipId] = useState("");
    const [isUpdateRequestClicked, setIsUpdateRequestClicked] = useState(false);
    const [connectionId, setConnectionId] = useState("");
    const [isFeedbackFormOpen, setIsFeedbackFormOpen] = useState(location && location?.state?.isFeedbackFormOpen ? location.state.isFeedbackFormOpen : false);
    const [tabValue, setTabValue] = React.useState(location && location?.state?.tabVal ? location.state.tabVal : 0);
    const [connectionTabValue, setConnectionTabValue] = React.useState(0);
    const [recentMentors, setRecentMentors] = useState([]);
    const [recentMentees, setRecentMentees] = useState([]);
    const [sendReqError, setSendReqError] = useState({});

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const handleConnectionTabChange = (event, newValue) => {
        setConnectionTabValue(newValue);
    };

    const handleSignedInStatus = (v) => {
        setIsSignedIn(v);
    }

    useEffect(() => {
        document.body.classList.add('wallpaper--icon');
        return () => {
            document.body.classList.remove('wallpaper--icon')
        }
    }, []);

    //notification callback start
    const [openNotificationDialog, setOpenNotificationDialog] = React.useState(false);
    const [notificationBadgeCount, setNotificationBadgeCount] = React.useState(0);

    const closeNotificationDialog = () => {
        setOpenNotificationDialog(false);
    }

    //fetch all notifications
    React.useEffect(() => {
        let jobseekerID = localStorage.getItem("jobseeker_id");
        let payload = {
            "offset": 0,
            "limit": 100,
            "jobseeker_id": jobseekerID
        }
        ApiHandler.getAllNotifications(payload, res => {
            let unreadNotif = 0;
            if (res.status == 200) {
                unreadNotif = res.data[0].results.filter(val => !val.is_read).length;
                setNotificationBadgeCount(unreadNotif);
            }
        })
    }, []);

    React.useEffect(() => {
        initClient();
    }, []);

    //notification callback ends

    //dialog for searched mentors results & mentor request
    const [openSearchedMentorsDialog, setOpenSearchedMentorsDialog] = useState(false);
    const [openMenteeFullProfileDialog, setOpenMenteeFullProfileDialog] = useState(false);
    const [openFullProfileDialog, setOpenFullProfileDialog] = useState(false);
    const [openMentorshipReqDialog, setOpenMentorshipReqDialog] = useState(false);
    const [openConnectWithMenteeReqDialog, setOpenConnectWithMenteeReqDialog] = useState(false);
    const [openReqNoteDialog, setOpenReqNoteDialog] = useState(false);
    const [openBecomeMentorDialog, setOpenBecomeMentorDialog] = useState(false);
    const [noResultText, setNoResultText] = useState('');

    const closeMentorshipReqDialog = () => {
        setOpenMentorshipReqDialog(false);
    }

    const closeFullProfileDialog = () => {
        setOpenFullProfileDialog(false);
    }

    const closeSearchedMentorDialog = () => {
        setOpenSearchedMentorsDialog(false);
    }

    const closeBecomeMentorDialog = () => {
        setOpenSearchedMentorsDialog(false);
    }


    // drawer callback starts
    const [refresh, doRefresh] = React.useState(false);
    const [forRefreshOnly, setForRefreshOnly] = React.useState(false);
    const [canDrawerOpen, setCanDrawerOpen] = React.useState(false);
    const handleDrawer = () => {
        setCanDrawerOpen(true)
        doRefresh(prev => !prev)
    }
    // drawer callback ends

    // snackbar callback starts
    const [snackBarMessage, setSnackBarMessage] = React.useState('')
    const [refreshSnackBar, doRefreshSnackBar] = React.useState(false);
    const [canSnackBarOpen, setCanSnackBarOpen] = React.useState(false);
    const [snackBarMessageSeverity, setSnackBarMessageSeverity] = React.useState("success");
    const handleSnackBar = (message, severity) => {
        if (severity) {
            setSnackBarMessageSeverity(severity);
        } else {
            setSnackBarMessageSeverity("success");
        }
        setCanSnackBarOpen(true);
        setSnackBarMessage(message);
        doRefreshSnackBar(prev => !prev);
    }
    // snackbar callback ends

    // Settings drawer callback starts
    const [forRefreshSettingsOnly, setForRefreshSettingsOnly] = React.useState(false);
    const [canSettingsDrawerOpen, setCanSettingsDrawerOpen] = React.useState(false);
    const [refreshSettings, doRefreshSettings] = React.useState(false);

    const handleSettingsDrawer = () => {
        setCanSettingsDrawerOpen(true)
        doRefreshSettings(prev => !prev)
    }
    // Settings drawer callback ends

    //search mentors from searchbar
    const searchMentors = () => {
        if (searchedQuery.length >= 2) {
            displayMenteeProile ? ApiHandler.searchForMentors(searchedQuery, jobseeker_id, { "limit": "100", "offset": "0" }, res => {
                if (res.status == 200) {
                    if (res.data.data.length > 0) {
                        setMentorsListByRole(res.data.data);
                        setNoResultText('');
                    } else {
                        setMentorsListByRole([]);
                        setNoResultText('No result found');
                    }
                }
            }) : ApiHandler.searchForMentees(searchedQuery, jobseeker_id, { "limit": "100", "offset": "0" }, res => {
                if (res.status == 200) {
                    if (res.data[0].results.length > 0) {
                        setMenteesListByRole(res.data[0].results);
                        setNoResultText('');
                    } else {
                        setMenteesListByRole(res.data[0].results);
                        setNoResultText('No result found');
                    }
                }
            })
        }
    }

    //get current user details
    React.useEffect(() => {
        if (isSignedIn) {
            let payload = {
                jobseeker_id
            }
            ApiHandler.getAllJobRolesandCount("AllJobs", response => {
                if (response.status == 200) {
                    setRoles(response.data);
                    setTopTenRoles(response.data.slice(0, 10));
                }
            })
            setIsMentee(JSON.parse(localStorage.getItem("is_mentee")));
            setIsMentor(JSON.parse(localStorage.getItem("is_mentor")));

            displayMenteeProile && getAllRecommendedMentors();
            displayMentorProile && getAllRecommendedMentees();
            displayMenteeProile && getRecentlyJoinedMentors();
            displayMentorProile && getRecentlyJoinedMentees();
        }
    }, [isSignedIn, displayMenteeProile, displayMentorProile, refresh]);

    const saveUserAsMentor = () => {
        ApiHandler.updateMentorFlag({ "mentor_id": jobseeker_id }, res => {
            if (res.status == 200) {
                localStorage.setItem('is_mentor', true);
                setIsMentor(true);
            }
        });
    }

    const saveUserAsMentee = () => {
        const isProfileCompleted = JSON.parse(localStorage.getItem("profile_completed"));
        if (isProfileCompleted) {
            ApiHandler.updateMenteeFlag({ "mentee_id": jobseeker_id }, res => {
                if (res.status == 200) {
                    localStorage.setItem('is_mentee', true);
                    setIsMentee(true);
                    setDisplayMenteeProile(true);
                    setDisplayMentorProile(false);
                }
            });
        } else {
            handleSnackBar("Please complete your profile before registering as Mentee", "error");
        }
    }

    //get recommended mentors list
    const getAllRecommendedMentors = () => {
        let data = {
            "mentee_id": jobseeker_id,
            "limit": "100", "offset": "0"
        }
        ApiHandler.getRecommendedMentors(data, res => {
            if (res.status == 200) {
                setRecommendedMentors(res.data[0].results);
            }
        })
    }

    //get recommended mentors list
    const getAllRecommendedMentees = () => {
        let data = {
            "mentor_id": jobseeker_id,
            "limit": "100", "offset": "0"
        }
        ApiHandler.getRecommendedMentees(data, res => {
            if (res.status == 200) {
                setRecommendedMentees(res.data[0].results);
            }
        })
    }

    const getRecentlyJoinedMentors = () => {
        ApiHandler.getRecentMentors(jobseeker_id, res => {
            if (res.status == 200) {
                let filteredData = res.data.data.filter(user => user.user_details.full_name !== null);
                setRecentMentors(filteredData);
            }
        })
    }

    const getRecentlyJoinedMentees = () => {
        ApiHandler.getRecentMentees(jobseeker_id, res => {
            if (res.status == 200) {
                let filteredData = res.data.data.filter(user => user.full_name !== null);
                setRecentMentees(filteredData);
            }
        })
    }

    //get mentor list when user clicks on role slides
    const searchMentorsByJobRole = (role) => {
        let data = {
            "role_name": role,
            "mentee_id": jobseeker_id,
            "offset": 0,
            "limit": 100
        }
        ApiHandler.getMentorsByRole(data, res => {
            if (res.status == 200) {
                setMentorsListByRole(res.data.data);
                setSearchedQuery(role);
                setOpenSearchedMentorsDialog(true);
            }
        })
    }

    const updateMentorDetails = (e, state, setState) => {
        setMentorFormErrors({ ...mentorFormErrors, [e.target.name]: "" })
        setState({ ...state, [e.target.name]: e.target.value });
    }

    const setSelectedImg = (e) => {
        if (e.target.files[0]) {
            if (!(e.target.files[0].type === "image/jpg" || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/png")) {
                alert("Please upload a valid file");
            } else {
                setSelectedMentorImg(e.target.files[0]);
            }
        }
    }

    const validateDetails = () => {
        const errors = {}
        if (!mentorDetails.first_name) {
            errors.first_name = 'First name is required*'
        }
        if (!mentorDetails.last_name) {
            errors.last_name = 'Last name is required*'
        }
        if (!mentorDetails.email) {
            errors.email = 'Email is required*';
        }
        if (!locationValue) {
            errors.location = 'Location is required*'
        }
        if (mentorshipScrQues.mentor_role.length === 0) {
            errors.mentor_role = 'This field is required*'
        }
        if (!mentorshipScrQues.topics) {
            errors.topics = 'This field is required*'
        }
        if (!mentorshipScrQues.work_exp) {
            errors.work_exp = 'Work experience is required*'
        }
        if (!mentorshipScrQues.curr_org) {
            errors.curr_org = 'This field is required*'
        }
        if (!mentorshipScrQues.curr_role) {
            errors.curr_role = 'This field is required*'
        }
        if (!mentorshipScrQues.industry) {
            errors.industry = 'This field is required*'
        }
        return errors
    }

    const registerAsMentor = () => {
        setMentorFormErrors(validateDetails());
        setIsFormSubmit(true)
    }

    useEffect(() => {
        if (Object.keys(mentorFormErrors).length === 0 && isFormSubmit) {
            ApiHandler.addScreeningQuesForMentors(mentorshipScrQues, res => {
                console.log(res);
            })

            ApiHandler.createMentor(mentorDetails, res => {
                if (res.status == 200) {
                    saveUserAsMentor();
                    setOpenBecomeMentorDialog(false);
                    setDisplayMentorProile(true);
                    handleSnackBar("Profile created successfully. Please wait while our team verify your mentor profile. Meanwhile you can continue exploring our app.")
                }
            })

            if (selectedMentorImg) {
                var form_data = new FormData();
                form_data.append('jobseeker_id', jobseeker_id)
                form_data.append('user_image', selectedMentorImg)
                ApiHandler.uploadProfilePic(form_data, res => {
                    console.log(res)
                })
            }
            ApiHandler.addEditLocation({
                "jobseeker_id": jobseeker_id,
                "user_city": locationValue,
                "user_latitude": latitude,
                "user_longitude": longitude
            }, res => {
                console.log(res);
            })

        }

        // return () => {
        //     second
        // }
    }, [mentorFormErrors]);

    const handleDays = (e) => {
        if (e.target.checked) {
            setMentorshipScrQues({ ...mentorshipScrQues, days_of_week: [...mentorshipScrQues.days_of_week, e.target.id] })
        } else {
            setMentorshipScrQues({ ...mentorshipScrQues, days_of_week: mentorshipScrQues.days_of_week.filter(item => item !== e.target.id) })
        }
    }

    return (
        <>
            {isSignedIn ?
                <>
                    {/* Top App Bar on Mobile */}
                    <AppBar position="fixed" style={{ background: '#fff' }}>
                        <Toolbar variant="dense" style={{ padding: 0 }}>
                            <div style={{ width: '100%' }}>
                                <div className={classes.sectionMobile}>
                                    <Button
                                        style={{ display: 'flex' }}
                                        id="meetLogo_id"
                                        onClick={() => {
                                            history.push({
                                                pathname: '/'
                                            });
                                        }}>
                                        <img alt="meet logo" style={{ width: 80, marginRight: '11%', paddingLeft: 10 }} src={logo} />
                                    </Button>
                                    <Grid
                                        item xs={9} md={11}
                                        container
                                        direction='column'
                                        justifyContent="center"
                                        alignItems='flex-start'>

                                    </Grid>
                                    {isSignedIn && <IconButton className={classes.mobileNotificationIcon} aria-label="show 10 new notifications" color="inherit">
                                        <Badge badgeContent={notificationBadgeCount} color="error">
                                            <NotificationsOutlinedIcon color='action'
                                                onClick={() => setOpenNotificationDialog(true)}
                                            />
                                        </Badge>
                                    </IconButton>}
                                    <IconButton
                                        id="shareButton"
                                        style={{ position: 'absolute', top: 0, right: 10, zIndex: 9, padding: '12px 0' }}
                                        onClick={() => {
                                            if (isSignedIn) handleSettingsDrawer()
                                            else handleDrawer()
                                        }}
                                    >
                                        <SettingsOutlinedIcon />
                                    </IconButton>
                                </div>
                            </div>
                        </Toolbar>
                    </AppBar>
                    <div style={{ backgroundColor: '#fff', padding: '15px 10px', boxShadow: '0px -4px 12px rgba(0, 0, 0, 0.14)', marginBottom: 1, marginTop: 48 }} className={classes.mobileView} >
                        <Typography variant='p'>{!isMentee && !isMentor ? 'Mentorship' : displayMenteeProile ? 'Mentee' : 'Mentorship'}</Typography>
                    </div>
                    {/* check if user is already a mentor or mentee */}
                    {!displayMenteeProile && !displayMentorProile ?
                        <Grid className={`${classes.px3} ${classes.py1}`} container direction='column'>
                            <Grid item container direction='column' alignItems='center' className={`${classes.tileContainerBanner} ${classes.px2} ${classes.py1}`} >
                                <img src={BeMentorImg} width={150} height={100} />
                                <Typography variant='h3' className={`${classes.px3} ${classes.textAlignCenter}`}>Become a Mentor & Guide Unstoppable Talent!</Typography>
                                <Typography variant='caption' className={`${classes.colorLight} ${classes.px2} ${classes.textAlignCenter}`} >Share your expertise and inspire the next generation. Join our community of mentors and make a lasting impact on future talent.</Typography>
                                {!isMentor ? <Button variant='outlined' fullWidth color='primary' className={`${classes.ttn} ${classes.mt2}`}
                                    onClick={() => {
                                        // handleMentorMenteeFlag();
                                        setOpenBecomeMentorDialog(true);
                                        setJobseeker_id(localStorage.getItem('jobseeker_id'));
                                    }}
                                >
                                    Be a Mentor
                                </Button> :
                                    <Button variant='outlined' fullWidth color='primary' className={`${classes.ttn} ${classes.mt2}`}
                                        onClick={() => {
                                            // handleMentorMenteeFlag();
                                            setDisplayMentorProile(true);
                                        }}
                                    >
                                        View mentor profile
                                    </Button>
                                }
                            </Grid>
                            <Grid item container direction='column' alignItems='center' className={`${classes.tileContainerBanner} ${classes.mt2} ${classes.px2} ${classes.py1}`} >
                                <img src={BeMenteeImg} width={150} height={100} />
                                <Typography variant='h3' className={`${classes.px3} ${classes.textAlignCenter}`}>Find Your Mentor & Reach Your Potential!</Typography>
                                <Typography variant='caption' className={`${classes.colorLight} ${classes.px2} ${classes.textAlignCenter}`} >Discover experienced mentors ready to guide you. Start your journey to success today!</Typography>
                                {!isMentee ? <Button variant='outlined' fullWidth color='primary' className={`${classes.ttn} ${classes.mt2}`}
                                    onClick={() => {
                                        saveUserAsMentee();
                                    }}
                                >
                                    Be a Mentee
                                </Button> :
                                    <Button variant='outlined' fullWidth color='primary' className={`${classes.ttn} ${classes.mt2}`}
                                        onClick={() => {
                                            setDisplayMenteeProile(true);
                                        }}
                                    >
                                        View mentee profile
                                    </Button>}
                            </Grid>
                        </Grid> :
                        <>
                            <Tabs classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }} value={tabValue} onChange={handleChange} aria-label="mentorsip tabs" style={{ marginTop: matchesWidth2 ? 50 : 'initial' }}>
                                <Tab classes={{ root: classes.tabRoot, selected: classes.activeTab }} label="All" {...a11yProps(0)} />
                                <Tab classes={{ root: classes.tabRoot, selected: classes.activeTab }} label="Requests" {...a11yProps(1)} />
                                <Tab classes={{ root: classes.tabRoot, selected: classes.activeTab }} label="Connections" {...a11yProps(2)} />
                                {displayMentorProile && <Tab classes={{ root: classes.tabRoot, selected: classes.activeTab }} label="Profile" {...a11yProps(3)} />}
                            </Tabs>
                            <TabPanel value={tabValue} index={0}>
                                <div className={classes.mentorshipTabsContainer}>
                                    <FormControl className={`${classes.my2} ${classes.mentorSearchbar}`} variant="outlined">
                                        <OutlinedInput
                                            id="outlined-adornment-weight"
                                            placeholder={!displayMentorProile ? 'Search mentors' : 'Search mentees by name'}
                                            className={classes.searchinput}
                                            startAdornment={<SearchIcon />}
                                            aria-describedby="outlined-weight-helper-text"
                                            inputProps={{
                                                'aria-label': 'weight',
                                            }}
                                            labelWidth={0}
                                            readOnly
                                            onClick={() => setOpenSearchedMentorsDialog(true)}
                                        />
                                    </FormControl>
                                    <Typography variant='h4' className={`${classes.mb2} ${classes.mt2} ${classes.px1}`}>{displayMenteeProile ? recentMentors && 'Recently joined Mentors' : recentMentees.length > 0 && 'Recently joined Mentees'}</Typography>
                                    <div className={classes.px1} style={{ display: 'flex', overflowX: 'scroll', scrollbarWidth: 'none', scrollBehavior: 'smooth' }}>
                                        {displayMenteeProile ? recentMentors.filter(item => item.status !== 'accepted').map((user, index) => {
                                            return <RecentMentorsList key={index} user_data={user} setOpenFullProfileDialog={setOpenFullProfileDialog} setCurrentMentorId={setCurrentMentorshipId} setOpenMentorshipReqDialog={setOpenMentorshipReqDialog} />
                                        }) : (
                                            recentMentees?.filter(item => item.status !== 'accepted').map((user, index) => {
                                                return <RecentMenteesList key={index} user_data={user} setOpenFullProfileDialog={setOpenMenteeFullProfileDialog} setCurrentMentorId={setCurrentMentorshipId} setOpenMentorshipReqDialog={setOpenReqNoteDialog} />
                                            })
                                        )}

                                    </div>
                                    <Typography variant='h4' className={`${classes.mb2} ${classes.mt2} ${classes.px1}`}>{displayMenteeProile ? 'Mentors for different roles' : ''}</Typography>
                                    {displayMenteeProile && <div className={classes.px1} style={{ display: 'flex', overflowX: 'scroll', scrollbarWidth: 'none', scrollBehavior: 'smooth' }}>
                                        {topTenRoles.map((role, index) => {
                                            return <SectorTiles key={index} data={role} searchByJobRole={searchMentorsByJobRole} />
                                        })}
                                    </div>}

                                    <Typography variant='h4' className={`${classes.mb2} ${classes.mt3} ${classes.px1}`}>{displayMenteeProile ? recommendedMentors.length > 0 ? 'Recommended Mentors' : '' : recommendedMentees.length > 0 ? 'Recommended Mentees' : ''}</Typography>
                                    <div className={classes.px1} style={{ display: 'flex', overflowX: 'scroll', scrollbarWidth: 'none', scrollBehavior: 'smooth' }}>
                                        {displayMenteeProile ? recommendedMentors?.map((mentor) => {
                                            return <RecommendedMentorsList key={mentor._id} user_data={mentor} setOpenFullProfileDialog={setOpenFullProfileDialog} setOpenMentorshipReqDialog={setOpenMentorshipReqDialog} setCurrentMentorId={setCurrentMentorshipId} />
                                        }) : (
                                            recommendedMentees?.map((mentee) => {
                                                return <RecommendedMenteesList key={mentee._id} user_data={mentee} setOpenFullProfileDialog={setOpenMenteeFullProfileDialog} setOpenMentorshipReqDialog={setOpenReqNoteDialog} setCurrentMentorId={setCurrentMentorshipId} />
                                            })
                                        )}
                                    </div>

                                    {displayMenteeProile && !isMentor && <div style={{ width: '97%', margin: '10px auto' }} className={classes.pr1}>
                                        <img className={classes.mobileView} style={{ marginBottom: -10, padding: 0 }} src={mentorBanner} width='100%' height='100%' onClick={() => setOpenBecomeMentorDialog(true)} />
                                    </div>}

                                    {displayMentorProile && !isMentee && <div style={{ width: '97%', margin: '10px auto' }} className={classes.pr1}>
                                        <img style={{ marginBottom: -10, padding: 0 }} src={menteeBanner} width='100%' height='100%' onClick={() => {
                                            saveUserAsMentee();
                                        }} />
                                    </div>}

                                    {displayMenteeProile && !isMentor && <img className={`${classes.desktopView} ${classes.my2} ${classes.cursor_pointer}`} src={BeMentorDesktop} width='100%' height='100%' onClick={() => setOpenBecomeMentorDialog(true)} />}

                                    {displayMenteeProile && isMentor && <div>
                                        <img className={`${classes.cursor_pointer} ${classes.my2}`} src={ViewMentorProfileImg} width='100%' height='100%' onClick={() => {
                                            setDisplayMenteeProile(false);
                                            setDisplayMentorProile(true);
                                        }} />
                                    </div>}
                                    {displayMentorProile && isMentee && <img className={`${classes.mobileView} ${classes.my2}`} src={ViewMenteeProfileImg} width='100%' height='100%' onClick={() => {
                                        setDisplayMenteeProile(true);
                                        setDisplayMentorProile(false);
                                    }} />}
                                    {displayMentorProile && isMentee && <img className={`${classes.desktopView} ${classes.cursor_pointer} ${classes.my3}`} src={ViewMenteeProfileDesktop} width='100%' height='100%' onClick={() => {
                                        setDisplayMenteeProile(true);
                                        setDisplayMentorProile(false);
                                    }} />}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={1}>
                                <Grid className={` ${classes.px1}`} style={{ margin: '10px 0' }} container justifyContent='center' wrap='nowrap'>
                                    <Chip
                                        label="Inbox"
                                        className={classes.mr2}
                                        variant="outlined"
                                        size='small'
                                        clickable
                                        color='primary'
                                        onClick={() => setRequestChipValue(0)}
                                        classes={{ root: requestChipValue === 0 ? classes.sortingChipActive : classes.sortingChip, clickable: classes.sortingChipClicked }}
                                    />
                                    <Chip
                                        label="Sent"
                                        variant="outlined"
                                        size='small'
                                        clickable
                                        onClick={() => setRequestChipValue(1)}
                                        classes={{ root: requestChipValue === 1 ? classes.sortingChipActive : classes.sortingChip, clickable: classes.sortingChipClicked }}
                                    />
                                </Grid>
                                <div className={classes.mentorshipTabsContainer}>
                                    {requestChipValue === 0 && (!displayMentorProile ? <RequestReceived handleSnackBar={handleSnackBar} setOpenFullProfileDialog={setOpenFullProfileDialog} setCurrentMentorId={setCurrentMentorshipId} /> : <RequestReceivedByMentors handleSnackBar={handleSnackBar} setOpenFullProfileDialog={setOpenMenteeFullProfileDialog} setCurrentMentorId={setCurrentMentorshipId} />)}
                                    {requestChipValue === 1 && (!displayMentorProile ? <RequestSent setOpenMentorshipReqDialog={setOpenMentorshipReqDialog} setRole={setIntrestedMentorshipRole} setNote={setRequestMessegeToMentor} setConnectionId={setConnectionId} setIsUpdateRequestClicked={setIsUpdateRequestClicked} openRequestSuccess={openRequestSuccessAlert} setOpenFullProfileDialog={setOpenFullProfileDialog} setCurrentMentorId={setCurrentMentorshipId} /> :
                                        <RequestSentByMentors setOpenMentorshipReqDialog={setOpenMentorshipReqDialog} setRole={setIntrestedMentorshipRole} setNote={setRequestMessegeToMentor} setConnectionId={setConnectionId} setIsUpdateRequestClicked={setIsUpdateRequestClicked} openRequestSuccess={openRequestSuccessAlert} setOpenFullProfileDialog={setOpenMenteeFullProfileDialog} setCurrentMentorId={setCurrentMentorshipId} />)}

                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={2}>
                                {/* secondary tabs for connection tab */}
                                <Tabs classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }} value={connectionTabValue} onChange={handleConnectionTabChange} aria-label="mentorsip tabs">
                                    <Tab classes={{ root: classes.tabRoot, selected: classes.activeTab }} label="All Connections" {...a12yProps(0)} />
                                    <Tab classes={{ root: classes.tabRoot, selected: classes.activeTab }} label="Events" {...a12yProps(1)} />
                                </Tabs>
                                <TabPanel value={connectionTabValue} index={0}>
                                    <div className={classes.mentorshipTabsContainer}>
                                        {!displayMentorProile ? <ConnectionsList setOpenFullProfileDialog={setOpenFullProfileDialog} setCurrentMentorId={setCurrentMentorshipId} handleSnackBar={handleSnackBar} matchesWidth={matchesWidth} /> :
                                            <ConnectedMenteesList setOpenFullProfileDialog={setOpenMenteeFullProfileDialog} setCurrentMentorId={setCurrentMentorshipId} handleSnackBar={handleSnackBar} matchesWidth={matchesWidth} />}
                                    </div>
                                </TabPanel>
                                <TabPanel value={connectionTabValue} index={1}>
                                    <AllEvents handleSnackBar={handleSnackBar} displayMentorProile={displayMentorProile} matchesWidth={matchesWidth} />
                                </TabPanel>
                            </TabPanel>
                            {/* <TabPanel value={tabValue} index={3}>
                                <ChatMobile />
                                <ChatDesktop />
                            </TabPanel> */}
                            <TabPanel value={tabValue} index={3}>
                                <FullProfile isMentee={true} displayMentorProile={displayMentorProile} handleSnackBar={handleSnackBar} />
                            </TabPanel>
                        </>}
                </> :
                <div className={classes.pageInfo}
                    style={{
                        // paddingTop: matchesMinHeight ? '70px' : 0,
                        paddingTop: 0,
                        height: '92vh',
                        justifyContent: 'space-between',
                        backgroundImage: `url(${Wallpaper})`,
                        backgroundColor: '#fcfcfc'
                    }}
                >
                    <img src={mentorSignInBanner} style={{ marginTop: 65 }} />
                    <Grid container alignItems='center' direction='column' style={{ marginBottom: 50 }}>
                        <Typography className={`${classes.pageInfoText} ${classes.font_bold}`} variant="subtitle1">Start करिये अपनी profile</Typography>
                        {/* <Typography className={classes.pageInfoText} variant="h3">अगर आपका Meeत पे account है sign in करें।</Typography> */}
                        <Button
                            id="Search_all_positions"
                            variant="contained"
                            className={`${classes.pageButton} ${classes.pageButton_pri}`}
                            style={{ width: 170 }}
                            onClick={() => {
                                // AnalyticsHandler.track('Profile_signIn_BtnClick');
                                handleDrawer();
                            }}
                        >
                            Sign in
                        </Button>
                    </Grid>

                </div>}
            <BottomAppBar
                isSignedIn={isSignedIn}
                setIsSignedIn={handleSignedInStatus}
                handleDrawer={handleDrawer}
                handleSettingsDrawer={handleSettingsDrawer}
                navValue={navValue}
                setNavValue={setNavValue}
                setIsFeedbackFormOpen={setIsFeedbackFormOpen}
            />
            <SignInUpDrawer
                canDrawerOpen={canDrawerOpen}
                refresh={refresh}
                isSignedIn={isSignedIn}
                setIsSignedIn={handleSignedInStatus}
                setForRefreshOnly={setForRefreshOnly}
                setCanDrawerOpen={setCanDrawerOpen}
                doRefresh={doRefresh}
            />
            <LogoutDrawer
                canDrawerOpen={canSettingsDrawerOpen}
                refresh={refreshSettings}
                isSignedIn={isSignedIn}
                setIsSignedIn={handleSignedInStatus}
                setForRefreshOnly={setForRefreshSettingsOnly}
                setCanDrawerOpen={setCanSettingsDrawerOpen}
                doRefresh={doRefreshSettings}
                handleSnackBar={handleSnackBar}
            />
            <SnackBarGeneric id="snackBarMessage" canSnackBarOpen={canSnackBarOpen} refresh={refreshSnackBar} message={snackBarMessage} severity={snackBarMessageSeverity} />

            {/* Notification dialog */}
            <Dialog
                open={openNotificationDialog}
                onClose={closeNotificationDialog}
                fullScreen
            >
                <DialogContent classes={{ root: classes.notificationDialogRoot }}>
                    <Grid style={{ height: 60, position: 'fixed', top: 0, left: 0, backgroundColor: '#fff', boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.17)' }} alignItems='center' container>
                        <ArrowBackIcon style={{ margin: '0 20px 0 10px' }} className={classes.cursor_pointer} onClick={() => setOpenNotificationDialog(false)} />
                        <Typography variant='body1' >Notifications</Typography>
                    </Grid>
                    <Notifications classes={classes} localIsLoggedIn={isSignedIn} setNotificationBadgeCount={setNotificationBadgeCount} setNotificationBar={setOpenNotificationDialog} setIsFeedbackFormOpen={setIsFeedbackFormOpen} setDisplayMentorProile={setDisplayMentorProile} setDisplayMenteeProile={setDisplayMenteeProile} setTabVal={setTabValue} setRequestChipVal={setRequestChipValue} variant='mobile' />
                </DialogContent>
            </Dialog>

            {/* Dialog for list of searched mentors/mentees */}
            <Dialog fullScreen open={openSearchedMentorsDialog} onClose={closeSearchedMentorDialog} >
                <DialogTitle style={{ padding: '10px', boxShadow: '0px 1px 6px 0px #00000012' }}>
                    <Grid wrap='nowrap' container alignItems='center'>
                        <ArrowBackIcon className={classes.cursor_pointer} onClick={() => {
                            setOpenSearchedMentorsDialog(false);
                            setSearchedQuery('');
                            setMentorsListByRole([]);
                            setMenteesListByRole([]);
                        }} />
                        <MentorSearchBar classes={classes} setOpenSearchedMentorsDialog={setOpenSearchedMentorsDialog} searchedQuery={searchedQuery} setSearchedQuery={setSearchedQuery} setMentorsListByRole={setMentorsListByRole} isMentee={isMentee} isMentor={isMentor} searchMentors={searchMentors} displayMentorProile={displayMentorProile} />
                    </Grid>
                </DialogTitle>
                <DialogContent style={{ padding: '10px' }}>
                    {displayMenteeProile ? mentorsListByRole?.length > 0 ? mentorsListByRole?.map((user, index) => {
                        return <SearchedMentorsList key={index} setOpenFullProfileDialog={setOpenFullProfileDialog} user_data={user} setCurrentMentorId={setCurrentMentorshipId} setOpenMentorshipReqDialog={setOpenMentorshipReqDialog} />
                    }) : <Grid container alignItems='center' justifyContent='center'>{noResultText}</Grid> :
                        menteesListByRole?.length > 0 ? menteesListByRole?.map((user, index) => {
                            return <SearchedMenteesList key={index} setOpenFullProfileDialog={setOpenMenteeFullProfileDialog} user_data={user} setCurrentMentorId={setCurrentMentorshipId} setOpenMentorshipReqDialog={setOpenReqNoteDialog} />
                        }) : <Grid container alignItems='center' justifyContent='center'>{noResultText}</Grid>
                    }
                </DialogContent>
            </Dialog>

            {/* Dialog for full profile of mentee */}
            <Dialog fullScreen={!matchesWidth ? true : false} open={openMenteeFullProfileDialog} >
                <DialogTitle style={{ padding: '10px', boxShadow: '0px 1px 6px 0px #00000012' }}>
                    <Grid wrap='nowrap' container alignItems='center'>
                        <ArrowBackIcon className={`${classes.mr2} ${classes.cursor_pointer}`} onClick={() => setOpenMenteeFullProfileDialog(false)} />
                        <Typography variant='h3'>Full Profile</Typography>
                    </Grid>
                </DialogTitle>
                <DialogContent style={{ padding: 0, overflowX: 'hidden' }}>
                    <MenteeFullProfile setOpenMentorshipReqDialog={setOpenReqNoteDialog} setOpenFullProfileDialog={setOpenMenteeFullProfileDialog} isMentee={isMentee} setCurrentMentorId={setCurrentMentorshipId} currentMentorId={currentMentorshipId} />
                </DialogContent>
            </Dialog>

            {/* Dialog for full profile of mentor */}
            <Dialog fullScreen={!matchesWidth ? true : false} open={openFullProfileDialog} >
                <DialogTitle style={{ padding: '10px', boxShadow: '0px 1px 6px 0px #00000012' }}>
                    <Grid wrap='nowrap' container alignItems='center'>
                        <ArrowBackIcon className={`${classes.cursor_pointer} ${classes.mr2}`} onClick={() => setOpenFullProfileDialog(false)} />
                        <Typography variant='h3'>Full Profile</Typography>
                    </Grid>
                </DialogTitle>
                <DialogContent style={{ padding: 0, overflowX: 'hidden' }}>
                    <FullProfile setOpenMentorshipReqDialog={setOpenMentorshipReqDialog} isMentee={isMentee} displayMentorProile={displayMentorProile} setCurrentMentorId={setCurrentMentorshipId} currentMentorId={currentMentorshipId} />
                </DialogContent>
            </Dialog>

            {/* Send request dialog for mentee to mentors */}
            <Dialog fullScreen={!matchesWidth ? true : false} open={openMentorshipReqDialog} classes={{ paper: classes.dialogPaper }} onClose={closeMentorshipReqDialog} >
                <DialogTitle style={{ padding: '10px', boxShadow: '0px 1px 6px 0px #00000012' }}>
                    <Grid wrap='nowrap' container alignItems='center'>
                        <ArrowBackIcon className={`${classes.cursor_pointer} ${classes.mr2}`} onClick={() => {
                            setOpenMentorshipReqDialog(false);
                            setRequestMessegeToMentor('');
                            setIntrestedMentorshipRole('');
                        }} />
                        <Typography variant='h3'>{!isUpdateRequestClicked ? 'Send request' : 'Update request'}</Typography>
                    </Grid>
                </DialogTitle>
                <DialogContent className={classes.px2}>
                    <Grid container direction='column' className={classes.tileContainer} >
                        <Typography className={classes.mb1} variant='body2' >Interested in<span className={classes.requiredFields}>*</span></Typography>
                        <FormControl variant="outlined" className={classes.mb3}>
                            <Autocomplete
                                id="select-roles"
                                options={roles}
                                className={classes.mt1}
                                value={intrestedMentorshipRole}
                                getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    // Regular option
                                    return option.job_role;
                                }}
                                onChange={(e, newValue) => {
                                    setSendReqError({...sendReqError, role: ''});
                                    if (newValue) {
                                        setIntrestedMentorshipRole(newValue.job_role);
                                    } else {
                                        setIntrestedMentorshipRole('');
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        error={sendReqError.role}
                                        helperText={sendReqError.role}
                                    // placeholder='Search or add new'
                                    />
                                )}
                            />
                        </FormControl>
                        <Typography className={classes.mb1} variant='body2' >Specify what you want to learn<span className={classes.requiredFields}>*</span></Typography>
                        <TextField
                            color='primary'
                            className={`${classes.py2}`}
                            multiline
                            minRows={8}
                            variant='outlined'
                            placeholder="Why do you want to connect?"
                            value={requestMessageToMentor}
                            onChange={(e) => {
                                setSendReqError({...sendReqError, message: ''});
                                setRequestMessegeToMentor(e.target.value);
                            }}
                            error={sendReqError.message}
                            helperText={sendReqError.message}
                        />
                        <Button variant='contained' color='secondary' className={`${classes.ttc} ${classes.my2}`}
                            onClick={() => {
                                if (!intrestedMentorshipRole) {
                                    setSendReqError({ ...sendReqError, role: 'This field is required' });
                                } else if (!requestMessageToMentor) {
                                    setSendReqError({ ...sendReqError, message: 'This field is required' });
                                } else {
                                    !isUpdateRequestClicked ? ApiHandler.sendMenteeRequest(jobseeker_id, {
                                        "mentor_id": currentMentorshipId,
                                        "request_note": requestMessageToMentor,
                                        "request_role": intrestedMentorshipRole
                                    }, res => {
                                        if (res.status == 200) {
                                            setOpenMentorshipReqDialog(false);
                                            setOpenRequestSuccesAlert(true);
                                            setIntrestedMentorshipRole('');
                                            setRequestMessegeToMentor('');
                                            doRefresh(p => !p);
                                        } else {
                                            handleSnackBar("You can not send request to this user", 'error')
                                        }
                                    }) : ApiHandler.updateMenteeRequest({
                                        "connection_id": connectionId,
                                        "request_note": requestMessageToMentor,
                                        "request_role": typeof (intrestedMentorshipRole) === 'object' ? intrestedMentorshipRole.job_role : intrestedMentorshipRole
                                    }, res => {
                                        if (res.status == 200) {
                                            setOpenMentorshipReqDialog(false);
                                            setOpenRequestSuccesAlert(true);
                                            setIntrestedMentorshipRole('');
                                            setRequestMessegeToMentor('');
                                        }
                                    })
                                }
                            }}>{!isUpdateRequestClicked ? 'Send mentoring request' : 'Update request'}</Button>
                    </Grid>
                </DialogContent>
            </Dialog>
            {/* Send request dialog for mentor to mentee */}
            <Dialog fullScreen={!matchesWidth ? true : false} open={openConnectWithMenteeReqDialog} classes={{ paper: classes.dialogPaper }} >
                <DialogTitle style={{ padding: '10px', boxShadow: '0px 1px 6px 0px #00000012' }}>
                    <Grid wrap='nowrap' container alignItems='center'>
                        <ArrowBackIcon className={`${classes.cursor_pointer} ${classes.mr2}`} onClick={() => {
                            setOpenConnectWithMenteeReqDialog(false);
                            setRequestMessegeToMentee('');

                        }} />
                        <Typography variant='h3'>{!isUpdateRequestClicked ? 'Send request' : 'Update request'}</Typography>
                    </Grid>
                </DialogTitle>
                <DialogContent className={classes.px2}>
                    <Grid container direction='column' className={classes.tileContainer} >
                        <Typography className={classes.mb1} variant='body2' >Request note</Typography>
                        <TextField
                            color='primary'
                            className={`${classes.py2}`}
                            multiline
                            minRows={8}
                            variant='outlined'
                            placeholder="Why do you want to connect?"
                            value={requestMessageToMentee}
                            onChange={(e) => setRequestMessegeToMentee(e.target.value)}
                        />
                        <Button variant='contained' color='secondary' className={`${classes.ttc} ${classes.my2}`}
                            onClick={() => {
                                ApiHandler.sendMentorRequest(jobseeker_id, {
                                    "mentee_id": currentMentorshipId,
                                    "request_note": requestMessageToMentee,
                                }, res => {
                                    if (res.status == 200) {
                                        setOpenConnectWithMenteeReqDialog(false);
                                        setOpenRequestSuccesAlert(true);
                                        setRequestMessegeToMentee('');
                                        getAllRecommendedMentees();
                                        doRefresh(p => !p);
                                    } else {
                                        handleSnackBar("You can not send request to this user", "error");
                                        setOpenConnectWithMenteeReqDialog(false);
                                    }
                                })
                            }}>Send mentoring request</Button>
                    </Grid>
                </DialogContent>
            </Dialog>

            {/* Add request note dialog */}
            <Dialog open={openReqNoteDialog}>
                <DialogTitle>
                    <Typography>Would you like to add a request note to your mentee?</Typography>
                </DialogTitle>
                <DialogActions>
                    <Grid container justifyContent='flex-end'>
                        <Button color='primary' className={classes.ttc}
                            onClick={() => {
                                setOpenConnectWithMenteeReqDialog(true);
                                setOpenReqNoteDialog(false);
                            }}
                        >Yes</Button>
                        <Button color='primary' className={classes.ttc}
                            onClick={() => {
                                ApiHandler.sendMentorRequest(jobseeker_id, {
                                    "mentee_id": currentMentorshipId,
                                    "request_note": requestMessageToMentee,
                                }, res => {
                                    if (res.status == 200) {
                                        setOpenConnectWithMenteeReqDialog(false);
                                        setOpenRequestSuccesAlert(true);
                                        setRequestMessegeToMentee('');
                                        getAllRecommendedMentees();
                                        setOpenReqNoteDialog(false);
                                        doRefresh(p => !p);
                                    }
                                    else if (res.response.data.statusCode == 400) {
                                        if (res.response.data.message == 'unverified mentor') {
                                            handleSnackBar("Please wait while your mentor profile is being verified before sending a connection request", "error");
                                            setOpenConnectWithMenteeReqDialog(false);
                                            setOpenReqNoteDialog(false);
                                        }
                                    }
                                    else {
                                        handleSnackBar("You can not send request to this user", "error");
                                        setOpenConnectWithMenteeReqDialog(false);
                                        setOpenReqNoteDialog(false);
                                    }
                                })
                            }}
                        >No</Button>
                    </Grid>
                </DialogActions>
            </Dialog>
            {/* Be a mentor dialog */}
            <Dialog fullScreen={!matchesWidth ? true : false} open={openBecomeMentorDialog} onClose={closeBecomeMentorDialog} >
                <DialogTitle style={{ padding: '10px', boxShadow: '0px 1px 6px 0px #00000012' }}>
                    <Grid wrap='nowrap' container alignItems='center'>
                        <ArrowBackIcon className={`${classes.cursor_pointer} ${classes.mr2}`} onClick={() => setOpenBecomeMentorDialog(false)} />
                        <Typography variant='h3'>Become a Mentor</Typography>
                    </Grid>
                </DialogTitle>
                <DialogContent className={classes.px2}>
                    <Grid container direction='column' className={`${classes.tileContainer} ${classes.px3} ${classes.py3}`} >
                        <Typography className={classes.mb1} variant='body2' >Please fill out this form. It will help us connect you with the right students at your preferred time.</Typography>
                        <div className={classes.horizontalLineBreak}></div>
                        <Grid item container direction='column'>
                            <Grid
                                item
                                xs={12}
                                container
                                justifyContent="space-between"
                                alignItems="flex-end"
                                style={{ minHeight: 50, backgroundColor: '#55c6a9', borderRadius: '3px 3px 0 0' }}
                            ></Grid>

                            <Grid container item xs={4} md={3} style={{ background: 'white', padding: '0 0 0px', marginTop: 10, position: 'relative', maxWidth: 100 }}>
                                {!selectedMentorImg ? <div className={classes.imgWrapper}
                                    style={{ width: 60, height: 60, position: 'absolute', top: '-45px', left: '15%', backgroundColor: '#d9d9d9', borderRadius: '50%' }}
                                    id="imgWrapper">
                                </div> :
                                    <img src={URL.createObjectURL(selectedMentorImg)} width={60} height={60} style={{ position: 'absolute', top: '-45px', left: '15%', backgroundColor: '#d9d9d9', borderRadius: '50%' }} />
                                }
                                <input accept="image/*" style={{ display: 'none' }} onChange={(e) => setSelectedImg(e)} id="icon-button-file" type="file" />
                                <label className={classes.uploadMentorImgIcon} htmlFor="icon-button-file">
                                    <IconButton color="primary" aria-label="upload picture" component="span">
                                        <PhotoCamera />
                                    </IconButton>
                                </label>

                                {/* {isImgLoading && <CircularProgress color="primary" style={{ position: 'absolute' }} />} */}
                            </Grid>
                            <Grid item style={{ marginTop: 30 }} className={classes.mb3}>
                                <Typography variant='body1' className={classes.mb1}>First Name<span className={classes.requiredFields}>*</span></Typography>
                                <TextField
                                    fullWidth
                                    required
                                    id="firstInput_id"
                                    variant="outlined"
                                    name='first_name'
                                    classes={{ root: classes.tfRoot2 }}
                                    value={mentorDetails.first_name}
                                    onChange={(e) => updateMentorDetails(e, mentorDetails, setMentorDetails)}
                                    error={mentorFormErrors.first_name ? true : false}
                                    helperText={mentorFormErrors.first_name}
                                />
                            </Grid>
                            <Grid item className={classes.mb3}>
                                <Typography variant='body1' className={classes.mb1}>Last Name<span className={classes.requiredFields}>*</span></Typography>
                                <TextField
                                    fullWidth
                                    required
                                    id="firstInput_id"
                                    variant="outlined"
                                    name='last_name'
                                    classes={{ root: classes.tfRoot2 }}
                                    value={mentorDetails.last_name}
                                    onChange={(e) => updateMentorDetails(e, mentorDetails, setMentorDetails)}
                                    error={mentorFormErrors.last_name ? true : false}
                                    helperText={mentorFormErrors.last_name}
                                />
                            </Grid>
                            <Grid item className={classes.mb3}>
                                <Typography variant='body1' className={classes.mb1}>Mobile Number<span className={classes.requiredFields}>*</span></Typography>
                                <TextField
                                    fullWidth
                                    required
                                    id="firstInput_id"
                                    variant="outlined"
                                    // placeholder="Full Name*"
                                    classes={{ root: classes.tfRoot2 }}
                                    defaultValue={localStorage.getItem('user_mobile')}
                                    disabled
                                />
                            </Grid>
                            <Grid item className={classes.mb3}>
                                <Typography variant='body1' className={classes.mb1}>Email ID<span className={classes.requiredFields}>*</span></Typography>
                                <TextField
                                    fullWidth
                                    required
                                    id="firstInput_id"
                                    name='email'
                                    variant="outlined"
                                    classes={{ root: classes.tfRoot2 }}
                                    onChange={(e) => updateMentorDetails(e, mentorDetails, setMentorDetails)}
                                    error={mentorFormErrors.email ? true : false}
                                    helperText={mentorFormErrors.email}

                                />
                            </Grid>
                            <Grid item className={classes.mb3}>
                                <Typography variant='body1' className={classes.mb1}>Location<span className={classes.requiredFields}>*</span></Typography>
                                <Location
                                    locationValue={locationValue}
                                    setLocationValue={setLocationValue}
                                    setLatitude={setLatitude}
                                    setLongitude={setLongitude}
                                    errors={mentorFormErrors}
                                    setErrors={setMentorFormErrors}
                                />
                            </Grid>
                            <Grid item className={classes.mb3}>
                                <Typography variant='body1' className={classes.mb1}>Areas of expertise<span className={classes.requiredFields}>*</span></Typography>
                                <Autocomplete
                                    multiple
                                    id="select-sector"
                                    options={roles}
                                    className={classes.mt1}
                                    getOptionLabel={(option) => option.job_role}
                                    onChange={(e, newValue) => {
                                        setMentorFormErrors({ ...mentorFormErrors, mentor_role: "" })
                                        if (newValue) {
                                            let sectorVal = [];
                                            newValue.map((item, index) => {
                                                sectorVal.push(item.job_role)
                                            });
                                            setMentorshipScrQues({ ...mentorshipScrQues, mentor_role: sectorVal });
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            error={mentorFormErrors.mentor_role ? true : false}
                                            helperText={mentorFormErrors.mentor_role}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item className={classes.mb3}>
                                <Typography variant='body1' className={classes.mb1}>Mention topics in which you can mentor<span className={classes.requiredFields}>*</span></Typography>
                                <TextField
                                    style={{ width: '100%' }}
                                    variant='outlined'
                                    name='topics'
                                    multiline
                                    minRows={6}
                                    placeholder='Example: Get your Resume/CV reviewed, Change Career'
                                    value={mentorshipScrQues.topics}
                                    onChange={(e) => updateMentorDetails(e, mentorshipScrQues, setMentorshipScrQues)}
                                    error={mentorFormErrors.topics ? true : false}
                                    helperText={mentorFormErrors.topics}
                                />
                            </Grid>
                            <Grid item className={classes.mb3}>
                                <Typography variant='body1' className={classes.mb1}>Availability<span className={classes.requiredFields}>*</span></Typography>
                                <RadioGroup style={{ flexDirection: 'row' }} aria-label="gender" name="gender1"
                                    value={mentorshipScrQues.frequency} onChange={e => setMentorshipScrQues({ ...mentorshipScrQues, frequency: e.target.value })}
                                >
                                    <FormControlLabel value="Weekly" control={<Radio />} label="Weekly" />
                                    <FormControlLabel value="Fortnightly" control={<Radio />} label="Fortnightly" />
                                    <FormControlLabel value="Monthly" control={<Radio />} label="Monthly" />
                                </RadioGroup>

                            </Grid>
                            <Grid item className={classes.mb3}>
                                <Typography variant='body1' className={classes.mb1}>No. of hours<span className={classes.requiredFields}>*</span></Typography>
                                <RadioGroup style={{ flexDirection: 'row' }} aria-label="gender" name="gender1"
                                    value={mentorshipScrQues.no_of_hours} onChange={e => setMentorshipScrQues({ ...mentorshipScrQues, no_of_hours: e.target.value })}
                                >
                                    <FormControlLabel value="1 hour" control={<Radio />} label="1 hour" />
                                    <FormControlLabel value="2 hours" control={<Radio />} label="2 hours" />
                                    <FormControlLabel value="3 hours" control={<Radio />} label="3 hours" />
                                    <FormControlLabel value="4 hours" control={<Radio />} label="4 hours" />
                                </RadioGroup>
                            </Grid>
                            <Grid item className={classes.mb3}>
                                <Typography variant='body1' className={classes.mb2}>Days available<span className={classes.requiredFields}>*</span></Typography>
                                <FormControl className={`${classes.flexbox} ${classes.timeFormControl}`} >
                                    <FormGroup className={`${classes.days} ${classes.flexboxCol}`} >
                                        <input type="checkbox" id='Monday' onChange={(e, index) => handleDays(e, index)} />
                                        <label htmlFor='Monday' >M</label>
                                    </FormGroup>
                                    <FormGroup className={`${classes.days} ${classes.flexboxCol}`} >
                                        <input type="checkbox" id='Tuesday' onChange={(e, index) => handleDays(e, index)} />
                                        <label htmlFor='Tuesday' >T</label>
                                    </FormGroup>
                                    <FormGroup className={`${classes.days} ${classes.flexboxCol}`} >
                                        <input type="checkbox" id='Wednesday' onChange={(e, index) => handleDays(e, index)} />
                                        <label htmlFor='Wednesday' >W</label>
                                    </FormGroup>
                                    <FormGroup className={`${classes.days} ${classes.flexboxCol}`} >
                                        <input type="checkbox" id='Thursday' onChange={(e, index) => handleDays(e, index)} />
                                        <label htmlFor='Thursday' >T</label>
                                    </FormGroup>
                                    <FormGroup className={`${classes.days} ${classes.flexboxCol}`} >
                                        <input type="checkbox" id='Friday' onChange={(e, index) => handleDays(e, index)} />
                                        <label htmlFor='Friday' >F</label>
                                    </FormGroup>
                                    <FormGroup className={`${classes.days} ${classes.flexboxCol}`} >
                                        <input type="checkbox" id='Saturday' onChange={(e, index) => handleDays(e, index)} />
                                        <label htmlFor='Saturday' >S</label>
                                    </FormGroup>
                                    <FormGroup className={`${classes.days} ${classes.flexboxCol}`} >
                                        <input type="checkbox" id='Sunday' onChange={(e, index) => handleDays(e, index)} />
                                        <label htmlFor='Sunday' >S</label>
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                            <Typography variant='h4' className={`${classes.mt2} ${classes.mb3}`}>Work Experience</Typography>
                            <Grid item className={classes.mb3}>
                                <Typography variant='body1' className={classes.mb1}>Total year of Work Experience<span className={classes.requiredFields}>*</span></Typography>
                                <TextField
                                    fullWidth
                                    required
                                    id="firstInput_id"
                                    name="work_exp"
                                    type='number1'
                                    variant="outlined"
                                    classes={{ root: classes.tfRoot2 }}
                                    value={mentorshipScrQues.work_exp}
                                    onChange={e => updateMentorDetails(e, mentorshipScrQues, setMentorshipScrQues)}
                                    error={mentorFormErrors.work_exp ? true : false}
                                    helperText={mentorFormErrors.work_exp}
                                />
                            </Grid>
                            <Grid item className={classes.mb3}>
                                <Typography variant='body1' className={classes.mb1}>Current Organisation<span className={classes.requiredFields}>*</span></Typography>
                                <TextField
                                    fullWidth
                                    required
                                    id="firstInput_id"
                                    name='curr_org'
                                    variant="outlined"
                                    classes={{ root: classes.tfRoot2 }}
                                    value={mentorshipScrQues.curr_org}
                                    onChange={e => updateMentorDetails(e, mentorshipScrQues, setMentorshipScrQues)}
                                    error={mentorFormErrors.curr_org ? true : false}
                                    helperText={mentorFormErrors.curr_org}

                                />
                            </Grid>
                            <Grid item className={classes.mb3}>
                                <Typography variant='body1' className={classes.mb1}>Current Designation<span className={classes.requiredFields}>*</span></Typography>
                                <TextField
                                    fullWidth
                                    required
                                    id="firstInput_id"
                                    name='curr_role'
                                    variant="outlined"
                                    classes={{ root: classes.tfRoot2 }}
                                    onChange={e => updateMentorDetails(e, mentorshipScrQues, setMentorshipScrQues)}
                                    error={mentorFormErrors.curr_role ? true : false}
                                    helperText={mentorFormErrors.curr_role}
                                />
                            </Grid>
                            <Grid item className={classes.mb3}>
                                <Typography variant='body1' className={classes.mb1}>Industry<span className={classes.requiredFields}>*</span></Typography>
                                <TextField
                                    fullWidth
                                    required
                                    id="firstInput_id"
                                    name='industry'
                                    variant="outlined"
                                    classes={{ root: classes.tfRoot2 }}
                                    value={mentorshipScrQues.industry}
                                    onChange={e => updateMentorDetails(e, mentorshipScrQues, setMentorshipScrQues)}
                                    error={mentorFormErrors.industry ? true : false}
                                    helperText={mentorFormErrors.industry}
                                    placeholder='Eg. IT, Telecommunication'
                                />
                            </Grid>
                            <Grid item className={classes.mb3}>
                                <Typography variant='body1' className={classes.mb1}>Previous Companies</Typography>
                                <TextField
                                    fullWidth
                                    required
                                    id="firstInput_id"
                                    name='prev_companies'
                                    variant="outlined"
                                    classes={{ root: classes.tfRoot2 }}
                                    value={mentorshipScrQues.prev_companies}
                                    onChange={e => updateMentorDetails(e, mentorshipScrQues, setMentorshipScrQues)}
                                />
                            </Grid>
                            <Typography variant='h4' className={classes.mb3}>Social Media Handles</Typography>
                            <Grid item className={classes.mb3}>
                                <TextField
                                    className={classes.margin}
                                    id="input-with-icon-textfield"
                                    name='linkedin'
                                    fullWidth
                                    variant='outlined'
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img src={LinkedIn} width={20} height={20} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={mentorshipScrQues.linkedin}
                                    onChange={e => updateMentorDetails(e, mentorshipScrQues, setMentorshipScrQues)}

                                />
                            </Grid>
                            <Grid item className={classes.mb3}>
                                <TextField
                                    className={classes.margin}
                                    id="input-with-icon-textfield"
                                    name='instagram'
                                    fullWidth
                                    variant='outlined'
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img src={Instagram} width={20} height={20} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={mentorshipScrQues.instagram}
                                    onChange={e => updateMentorDetails(e, mentorshipScrQues, setMentorshipScrQues)}
                                />
                            </Grid>
                            <Grid item className={classes.mb3}>
                                <TextField
                                    className={classes.margin}
                                    id="input-with-icon-textfield"
                                    name='facebook'
                                    fullWidth
                                    variant='outlined'
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img src={Facebook} width={20} height={20} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={mentorshipScrQues.facebook}
                                    onChange={e => updateMentorDetails(e, mentorshipScrQues, setMentorshipScrQues)}
                                />
                            </Grid>

                        </Grid>
                        <Button variant='contained' color='secondary' className={`${classes.ttc} ${classes.my2}`} onClick={() => {
                            // setIsMentee(false);
                            // setIsMentor(true);
                            // setOpenBecomeMentorDialog(false);
                            registerAsMentor();
                        }} >Submit</Button>
                    </Grid>
                </DialogContent>
            </Dialog>

            {/* send request success dialog */}
            <Dialog open={openRequestSuccessAlert}>
                <DialogContent>
                    <Grid container direction='column' alignItems='center'>
                        <Typography className={classes.my2} variant='h4'>Success!</Typography>
                        <Typography className={classes.textAlignCenter} variant='body2'>{!isUpdateRequestClicked ? 'You have successfully send the Mentorship request' : 'Request updated successfully'}</Typography>
                        <div className={classes.horizontalLineBreak}></div>
                        <Button color='primary' variant='contained' onClick={() => setOpenRequestSuccesAlert(false)}>OK</Button>
                    </Grid>
                </DialogContent>
            </Dialog>
            {/* be mentor success dialog */}
            {/* <Dialog open={openRequestSuccessAlert}>
                <DialogContent>
                    <Grid container direction='column' alignItems='center'>
                        <Typography className={classes.my2} variant='h4'>Success!</Typography>
                        <Typography className={classes.textAlignCenter} variant='body2'>'Thanks for registering for mentor. Our team will verify your profile once your details are checked. Till then you can continue exploring our app further.</Typography>
                        <div className={classes.horizontalLineBreak}></div>
                        <Button color='primary' variant='contained' onClick={() => setOpenBecomeMentorSuccesAlert(false)}>OK</Button>
                    </Grid>
                </DialogContent>
            </Dialog> */}
            {/* feedback form */}
            <Dialog fullScreen open={isFeedbackFormOpen} classes={{ paper: classes.dialogPaper }} >
                <DialogTitle style={{ padding: '10px', boxShadow: '0px 1px 6px 0px #00000012' }}>
                    <Grid wrap='nowrap' container alignItems='center'>
                        <ArrowBackIcon className={`${classes.cursor_pointer} ${classes.mr2}`} onClick={() => setIsFeedbackFormOpen(false)} />
                        <Typography variant='h3'>Give Feedback to Akshita Jain</Typography>
                    </Grid>
                </DialogTitle>
                <DialogContent className={classes.px2}>
                    <Grid container direction='column' style={{ boxShadow: "0px 1px 6px 0px #00000012" }}>
                        <Grid item container style={{ backgroundColor: '#55C6A933' }} className={`${classes.px2} ${classes.py3}`} direction='column'>
                            <img src='https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png' id="my-image" width={30} height={30} />
                            <Typography className={classes.my1} variant="h3">Neha Bohre</Typography>
                            <Typography variant="caption">Area Sales Manager at RECEX Pvt. Ltd. </Typography>
                        </Grid>
                        <Grid className={classes.px2} item container direction='column'>
                            <Typography variant="h4" className={`${classes.mt3} ${classes.mb2}`} >Please rate your mentor on the following aspects:</Typography>
                            <Grid item container direction='column'>
                                <Grid item container className={classes.my2} direction='column'>
                                    <Typography variant="body1"> <span style={{ fontSize: '1rem' }}>&#x2022;</span> Knowledge and Expertise</Typography>
                                    <Rating name="simple-controlled" />
                                </Grid>
                                <Grid item container className={classes.my2} direction='column'>
                                    <Typography variant="body1"> <span style={{ fontSize: '1rem' }}>&#x2022;</span> Approachability and Support</Typography>
                                    <Rating name="simple-controlled" />
                                </Grid>
                                <Grid item container className={classes.my2} direction='column'>
                                    <Typography variant="body1"> <span style={{ fontSize: '1rem' }}>&#x2022;</span>  Overall Experience</Typography>
                                    <Rating name="simple-controlled" />
                                </Grid>
                            </Grid>
                        </Grid>
                        <div className={`${classes.horizontalLineBreak}`}></div>
                        <Grid className={classes.px2} item container direction='column'>
                            <Typography variant="h4" className={`${classes.mt3} ${classes.mb2}`} >Please select all tags that best describe your experience with your mentor. You can select multiple tags.</Typography>
                            <Grid
                                item
                                container
                            >
                                <Chip className={`${classes.my1} ${classes.mr1}`} variant="outlined" label="Knowledgeable" />
                                <Chip className={`${classes.my1} ${classes.mr1}`} variant="outlined" label="Helpful" />
                                <Chip className={`${classes.my1} ${classes.mr1}`} variant="outlined" label="Clear Communication" />
                                <Chip className={`${classes.my1} ${classes.mr1}`} variant="outlined" label="Encouraging" />
                                <Chip className={`${classes.my1} ${classes.mr1}`} variant="outlined" label="Friendly" />
                                <Chip className={`${classes.my1} ${classes.mr1}`} variant="outlined" label="Insightful" />
                                <Chip className={`${classes.my1} ${classes.mr1}`} variant="outlined" label="Supportive" />
                                <Chip className={`${classes.my1} ${classes.mr1}`} variant="outlined" label="Resourceful" />
                                <Chip className={`${classes.my1} ${classes.mr1}`} variant="outlined" label="Understanding" />
                                <Chip className={`${classes.my1} ${classes.mr1}`} variant="outlined" label="Approachable" />
                                <Chip className={`${classes.my1} ${classes.mr1}`} variant="outlined" label="Motivating" />
                            </Grid>
                        </Grid>
                        <Grid className={classes.px2} item container direction='column'>
                            <Typography variant="h4" className={`${classes.mt3} ${classes.mb2}`} >Please share your experience with your mentor</Typography>
                            <TextareaAutosize
                                style={{ width: '100%' }}
                                minRows={8}
                            />
                        </Grid>
                        <Grid className={`${classes.px2} ${classes.my2}`} item container direction='column'>
                            <Button variant='contained' color='primary' className={classes.ttc}>Submit</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default MentorshipHome