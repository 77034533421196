import React, { useEffect, useState } from 'react'
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormGroup, FormLabel, Grid, TextField, Typography } from '@material-ui/core'
import { useStyles } from './styles';
import { useTheme } from '@material-ui/core/styles';
import LaunchIcon from '@material-ui/icons/Launch';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import ApiHandler from '../../ApiHandler';
import StarIcon from '@material-ui/icons/Star';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import DoneIcon from '@material-ui/icons/Done';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { initClient, authenticate, createEvent, checkIfUserIsSignedIn, signOutUser } from '../../googleApi';
import { DateTimePicker, DatePicker, TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import MeetingScheduler from './MeetingScheduler';

const setInitials = (data) => {
  var full_name = data.user_first_name ? data.user_first_name + " " + data.user_last_name : data.full_name ? data.full_name : '';
  var full_name_array = full_name.split(" ");
  var k = 0;
  var initials = "";
  for (; k < (full_name_array.length > 2 ? 2 : full_name_array.length); k++) {
    initials = initials + full_name_array[k].charAt(0).toUpperCase();
  }
  return initials;
}

//google login auth
const handleAuthClick = (userEmail, setForm, handleSnackBar, setOpenMeetingScheduler, setGoogleLoginStatus) => {
  authenticate().then((googleUser) => {
    const profile = googleUser.getBasicProfile();
    const email = profile.getEmail();
      setGoogleLoginStatus(true);
      setForm(false);
      setOpenMeetingScheduler(true);
      handleSnackBar(`Signed in as ${email}`);
  });
};

//Recommended mentors list
const RecommendedMentorsList = (props) => {
  const { user_data } = props;
  const classes = useStyles();

  return (
    <>
      <div style={{ width: 'max-content', minHeight: 160, margin: 0 }} className={`${classes.tileContainer} ${classes.mr2} ${classes.p0}`}>
        <Grid className={classes.sliderContainerMentors} container direction='column' justifyContent='space-between'
          onClick={() => {
            props.setOpenFullProfileDialog(true);
            props.setCurrentMentorId(user_data.mentor_id);
          }}
        >
          <Grid item container alignItems='center' wrap='nowrap' direction='column'>
            <Grid
              item
              xs={12}
              container
              justifyContent="space-between"
              alignItems="flex-end"
              style={{ minHeight: 40, backgroundColor: '#55c6a9', position: 'relative', borderRadius: '6px 6px 0 0' }}
            >
              {user_data?.user_details?.user_image_url ? <img src={user_data?.user_details?.user_image_url} width={45} height={45} style={{ marginRight: 10, position: 'absolute', bottom: -18, left: '50%', transform: 'translateX(-50%)', borderRadius: '50%' }} /> :
                <p className={`${classes.nameInitials} ${classes.nameInitialsAbs}`}> {setInitials(user_data.user_details)} </p>}

            </Grid>
            <Grid style={{ marginTop: 25 }} item container alignItems='center' direction='column'>
              <Grid item container justifyContent='center' wrap='nowrap'>
                <Typography variant='body1' component='h3' className={`${classes.mr1} ${classes.ttc}`} >{user_data?.user_details?.full_name}</Typography>
                {/* <Grid container xs={4} item wrap='nowrap' style={{ marginBottom: 4 }}>
                  <StarIcon
                    fontSize={'inherit'}
                    style={{ color: '#FFAD0D', marginRight: 4 }} />
                  <Typography variant="body2" >
                    4.9
                  </Typography>
                </Grid> */}
              </Grid>
              {/* <Typography className={`${classes.colorLight} ${classes.textAlignCenter}`} style={{ width: '90%', margin: '0 auto 45px auto' }} variant='body2' component='p'>Area Sales Manager at RECEX Pvt. Ltd. | IndiaMART InterMESH Ltd. | Tridev Vision Infratech </Typography> */}
              {user_data.user_experiences.user_post && <Typography className={classes.colorLight} variant='body2' component='p'>{user_data.user_experiences.user_post} | {user_data.user_experiences.company_name}</Typography>}
              <Grid item style={{ position: 'absolute', bottom: '4%' }} container justifyContent='center' >
                <Button style={{ padding: '2px 10px', border: user_data?.status === 'pending' ? 'none' : user_data?.status === 'accepted' ? 'none' : '1px solid' }} className={`${classes.mt2} ${classes.ttc}`} startIcon={user_data.status === 'pending' ? <ScheduleIcon fontSize='small' color='primary' /> : user_data?.status === 'accepted' ? <DoneIcon fontSize='small' color='primary' /> : <PersonAddOutlinedIcon color='primary' />} variant='outlined' color='primary'
                  onClick={() => {
                    if (!user_data?.status || user_data?.status === 'declined') {
                      props.setOpenMentorshipReqDialog(true);
                      props.setCurrentMentorId(user_data._id);
                    }
                  }} >
                  {user_data?.status === 'pending' ? 'Pending' : user_data?.status === 'accepted' ? 'Accepted' : 'Connect'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid className={`${classes.px2} ${classes.py3}`} item>
            <Typography className={`${classes.colorLight} ${classes.mb1}`} variant='body2' component='p'>Offering help in</Typography>
            {user_data.topics && user_data.topics.map((topic, index) => {
              return <Typography key={index} variant='caption' component='span'>
                {user_data.topics.indexOf(topic) === user_data.topics.length - 1 ? topic : topic + ', '}
              </Typography>
            })}
          </Grid> */}

        </Grid>
      </div>
    </>
  )
}

export default React.memo(RecommendedMentorsList)

//Mentors for different roles list
export const SectorTiles = ({ data, searchByJobRole }) => {
  const classes = useStyles();

  return (
    <>
      <Grid direction='column' onClick={() => searchByJobRole(data.job_role)} style={{ border: '1px solid #d9d9d9' }} container className={`${classes.sectorTiles} ${classes.mr2} ${classes.cursor_pointer}`} >
        <img src={data.illustration_img_url} width={150} height={120} />
        <Typography className={`${classes.textAlignCenter} ${classes.py1}`} variant='body1'>{data.job_role}</Typography>
      </Grid>
    </>
  )
}

//list of mentors that mentee has requested to connect with
export const RequestSent = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [requestData, setRequestData] = useState([]);

  const getRequestedMentorsList = () => {
    let payload = {
      mentee_id: localStorage.getItem('jobseeker_id'),
      offset: 0,
      limit: 100
    }
    ApiHandler.getRequestedMentors(payload, res => {
      setRequestData(res.data[0].results)
    })
  }

  React.useEffect(() => {
    getRequestedMentorsList();
  }, [props.openRequestSuccess]);

  return (
    <>
      {requestData.map((item) => {
        return <Grid key={item._id} className={`${classes.tileContainer} ${classes.my1} ${classes.cursor_pointer}`} direction='column' container
          onClick={() => {
            props.setOpenFullProfileDialog(true);
            props.setCurrentMentorId(item.mentor_id);
          }}
        >
          <Grid wrap='nowrap' container item>
            {item?.user_details?.user_image_url ? <img className={`${classes.mr3} ${classes.border_rounded}`} src={item?.user_details?.user_image_url} width={45} height={45} /> :
              <p className={classes.nameInitials}> {setInitials(item.user_details)} </p>}
            <Grid item container direction='column' xs={8}>
              <Typography variant='body1' className={classes.ttc} component='h3'>{item.user_details.full_name}</Typography>
              {Object.keys(item.user_experiences).length > 0 && <Typography className={classes.colorLight} variant='body2' component='p'>{item.user_experiences.user_post} at {item.user_experiences.company_name}</Typography>}
            </Grid>
            <Grid item className={classes.reqPending}>
              <Typography style={{ color: '#FFAD0D', backgroundColor: '#FFE6033B', padding: 5, borderRadius: 18 }} variant='caption'> Request Pending</Typography>
            </Grid>
          </Grid>
          <div className={classes.horizontalLineBreak}></div>
          <Grid item container direction='column' xs={8}>
            {item.request_role && <Typography variant='caption'><span className={classes.colorLight}>Domain: </span>{item.request_role}</Typography>}
            {item.request_note && <Typography variant='caption'><span className={classes.colorLight}>Reason: </span> {item.request_note}</Typography>}
          </Grid>
          <Grid item container justifyContent='flex-end' >
            <Button className={`${classes.ttc} ${classes.requestEditBtn} ${classes.mt3}`} color='primary' variant='outlined'
              onClick={(e) => {
                e.stopPropagation();
                let role = { job_role: item.request_role }
                props.setIsUpdateRequestClicked(true);
                props.setOpenMentorshipReqDialog(true);
                props.setRole(role);
                props.setNote(item.request_note);
                props.setConnectionId(item._id);
              }}
            >Edit Request</Button>
          </Grid>
        </Grid>
      })}
      {requestData.length === 0 && <Grid container justifyContent='center' className={classes.my3} alignItems='center'>
        <Typography variant='h4' >No sent request</Typography>
      </Grid>}
    </>
  )
}

//connection request received from mentors to mentee
export const RequestReceived = (props) => {
  const classes = useStyles();
  const [requestData, setRequestData] = useState([]);

  React.useEffect(() => {
    getMentorsRequest();
  }, []);

  const getMentorsRequest = () => {
    let payload = {
      mentee_id: localStorage.getItem('jobseeker_id'),
      offset: 0,
      limit: 100
    }
    ApiHandler.getAllMentorsRequest(payload, res => {
      if (res.status == 200) {
        setRequestData(res.data[0].results)
      }
    })
  }

  const acceptDeclineRequest = (e, id, status) => {
    e.stopPropagation();
    ApiHandler.updateRequestStatus(id, { "status": status }, res => {
      if (res.status == 200) {
        status === 'accepted' ? props.handleSnackBar("Request accepted successfully") : props.handleSnackBar("Request rejected");
        getMentorsRequest();
      }
    });
  }

  return (
    <>
      {requestData.map((user, index) => {
        return <Grid key={user._id} container wrap='nowrap' className={`${classes.tileContainer} ${classes.my2} ${classes.cursor_pointer}`}
          onClick={() => {
            props.setOpenFullProfileDialog(true);
            props.setCurrentMentorId(user.mentor_id)
          }}
        >
          <Grid container direction='column'>
            <Grid item container wrap='nowrap'>
              <Grid wrap='nowrap' item container xs={8}>
                {user?.user_details?.user_image_url ? <img className={classes.mr3} src={user?.user_details?.user_image_url} width={40} height={40} /> :
                  <p className={classes.nameInitials}> {setInitials(user.user_details)} </p>}
                <Grid item container direction='column' >
                  <Typography variant='body1' className={classes.ttc} component='h3'>{user?.user_details?.full_name}</Typography>
                  <Typography className={classes.colorLight} variant='caption' component='p'>{user?.user_experiences?.curr_role}, {user?.user_experiences?.curr_org}</Typography>
                </Grid>
              </Grid>
              <Grid item container xs={4} alignItems='center' justifyContent='flex-end'>
                <HighlightOffIcon fontSize='large' className={`${classes.mr2} ${classes.colorLight}`} onClick={(e) => acceptDeclineRequest(e, user?._id, "declined")} />
                <CheckCircleOutlineIcon fontSize='large' className={classes.mr3} color='primary' onClick={(e) => acceptDeclineRequest(e, user?._id, "accepted")} />
              </Grid>
            </Grid>
            <div className={classes.horizontalLineBreak}></div>
            <Typography variant='caption'><span className={classes.colorLight}>Request Note: </span>{user?.request_note}</Typography>
          </Grid>
        </Grid>
      })}
      {requestData.length === 0 && <Grid container justifyContent='center' className={classes.my3} alignItems='center'>
        <Typography variant='h4' >No request received</Typography>
      </Grid>}

    </>
  )
}
export const RequestSentByMentors = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [requestData, setRequestData] = useState([]);
  let id = localStorage.getItem('jobseeker_id');

  const getRequestedMenteesList = () => {
    let payload = `limit=10&offset=0`
    ApiHandler.getRequestedMentees(id, payload, res => {
      setRequestData(res.data.data);
    })
  }

  React.useEffect(() => {
    getRequestedMenteesList();
  }, [props.openRequestSuccess]);

  return (
    <>
      {requestData.length > 0 ? requestData.map((user, index) => {
        return <Grid key={index} container wrap='nowrap' className={`${classes.tileContainer} ${classes.my2} ${classes.cursor_pointer}`}
          onClick={() => {
            props.setOpenFullProfileDialog(true);
            props.setCurrentMentorId(user.mentee_details._id);
          }}
        >
          <Grid container direction='column'>
            <Grid item container wrap='nowrap' alignItems='center' justifyContent='space-between'>
              <Grid wrap='nowrap' item container xs={8}>
                {user?.mentee_details?.user_image_url ? <img className={`${classes.mr3} ${classes.border_rounded}`} src={user?.mentee_details?.user_image_url} width={45} height={45} /> :
                  <p className={`${classes.nameInitials}`}> {setInitials(user.mentee_details)} </p>}
                <Grid item xs={8} container direction='column' >
                  <Typography variant='body1' className={classes.ttc} component='h3'>{user?.mentee_details?.user_first_name} {user?.mentee_details?.user_last_name}</Typography>
                  {/* <Typography className={classes.colorLight} variant='caption' component='p'>Sales Executive, Magicstep...</Typography> */}
                </Grid>
              </Grid>
              <Grid item style={{ position: 'absolute', right: '4%', top: '10%' }}>
                <Typography style={{ color: '#FFAD0D', backgroundColor: '#FFE6033B', padding: 5, borderRadius: 18 }} variant='caption'> Request Pending</Typography>
              </Grid>
            </Grid>
            <div className={classes.horizontalLineBreak}></div>
            {user.request_note && <Typography variant='caption'><span className={classes.colorLight}>Request Note: </span>{user.request_note}</Typography>}
          </Grid>
        </Grid>
      }) : <Grid container justifyContent='center' className={classes.my3} alignItems='center'>
        <Typography variant='h4' >No sent request</Typography>
      </Grid>}
    </>
  )
}

//connection request received from mentees to mentors
export const RequestReceivedByMentors = (props) => {
  const classes = useStyles();
  const [requestData, setRequestData] = useState([]);
  let id = localStorage.getItem('jobseeker_id');

  React.useEffect(() => {
    getMenteesRequest();
  }, []);

  const getMenteesRequest = () => {
    let payload = `limit=10&offset=0`
    ApiHandler.getAllMenteesRequest(id, payload, res => {
      if (res.status == 200) {
        setRequestData(res.data.data);
      }
    });
  }

  const acceptDeclineRequest = (e, id, status) => {
    e.stopPropagation();
    ApiHandler.updateRequestStatus(id, { "status": status }, res => {
      if (res.status == 200) {
        status === 'accepted' ? props.handleSnackBar("Request accepted successfully") : props.handleSnackBar("Request rejected");
        getMenteesRequest();
      }
    })
  }

  return (
    <>
      {requestData?.map((item) => {
        return <Grid key={item._id} className={`${classes.tileContainer} ${classes.my1} ${classes.cursor_pointer}`} direction='column' container
          onClick={() => {
            props.setOpenFullProfileDialog(true);
            props.setCurrentMentorId(item.mentee_details._id);
          }}
        >
          <Grid wrap='nowrap' container item>
            <img className={`${classes.mr3} ${classes.border_rounded}`} src={item?.mentee_details?.user_image_url ? item?.mentee_details?.user_image_url : 'https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png'} width={50} height={50} />
            <Grid item container direction='column'>
              <Typography variant='body1' component='h3'>{item?.mentee_details?.user_first_name} {item?.mentee_details?.user_last_name}</Typography>
              {/* <Typography className={classes.colorLight} variant='body2' component='p'>{item?.user_experiences?.user_post} at {item?.user_experiences?.company_name}</Typography> */}
            </Grid>

            <Grid item container xs={4} alignItems='center' wrap='nowrap' justifyContent='flex-end'>
              <HighlightOffIcon fontSize='large' className={`${classes.mr2} ${classes.colorLight}`} onClick={(e) => acceptDeclineRequest(e, item._id, "declined")} />
              <CheckCircleOutlineIcon fontSize='large' className={classes.mr3} color='primary' onClick={(e) => acceptDeclineRequest(e, item._id, "accepted")} />
            </Grid>
          </Grid>
          <div className={classes.horizontalLineBreak}></div>
          <Grid item container direction='column'>
            <Typography variant='caption'><span className={classes.colorLight}>Domain: </span>{item.request_role}</Typography>
            <Typography variant='caption'><span className={classes.colorLight}>Message: </span> {item.request_note}</Typography>
          </Grid>
        </Grid>
      })} {requestData.length === 0 && <Grid container justifyContent='center' className={classes.my3} alignItems='center'>
        <Typography variant='h4' >No request received</Typography>
      </Grid>}
    </>
  )
}

//list of connected mentors
export const ConnectionsList = (props) => {
  const classes = useStyles();
  const [connectionsList, setConnectionsList] = useState([]);
  const [openConsentForm, setOpenConsentForm] = useState(false);
  const [isSignedInWithGoogle, setIsSignedInWithGoogle] = useState(false);
  const [openMeetingScheduler, setOpenMeetingScheduler] = useState(false);
  const [currentUser, setCurrentUser] = useState('');
  const [attendeeEmail, setAttendeeEmail] = useState('');
  let id = localStorage.getItem('jobseeker_id');

  const connectedMentors = () => {
    let payload = {
      mentee_id: id,
      offset: 0,
      limit: 100
    }
    ApiHandler.getConnectedMentors(payload, res => {
      if (res.status == 200) {
        setConnectionsList(res.data[0].results)
      }
    });
  }

  React.useEffect(() => {
    connectedMentors();
    setIsSignedInWithGoogle(checkIfUserIsSignedIn());
    // getFullProfile();
  }, []);

  return (
    <>
      {connectionsList.map((user, index) => {
        return <Grid key={user._id} item container wrap='nowrap' alignItems='center' className={`${classes.singleChat} ${classes.mt2} ${classes.px3} ${classes.py3} ${classes.tileContainer} ${classes.cursor_pointer}`}
          onClick={() => {
            props.setOpenFullProfileDialog(true);
            props.setCurrentMentorId(user.mentor_id);
          }}
        >
          <Grid xs={9} wrap='nowrap' item container alignItems='center'>
            {user?.user_details?.user_image_url ? <img className={`${classes.mr3} ${classes.border_rounded}`} src={user?.user_details?.user_image_url} width={45} height={45} /> :
              <p className={`${classes.nameInitials}`}> {setInitials(user?.user_details)} </p>}
            <Grid container item direction='column'>
              <Typography className={`${classes.mb1} ${classes.ttc}`} variant='h4'>{user.user_details.full_name}</Typography>
              {user?.curr_role && <Typography className={classes.colorLight} variant='caption'>{user.curr_role}, {user.curr_org}</Typography>}
            </Grid>
          </Grid>
          <Grid xs={3} item container alignItems='center' direction='column'>
            <Button className={`${classes.ttc} ${classes.font_non_bold}`} variant='contained' color='primary' size='small'
              onClick={(e) => {
                e.stopPropagation();
                isSignedInWithGoogle ?
                  setOpenMeetingScheduler(true) :
                  setOpenConsentForm(true);
                setCurrentUser(user.user_details);
                setAttendeeEmail(user.user_email);
              }}
            >Schedule</Button>
          </Grid>
        </Grid>
      })}
      {connectionsList.length === 0 && <Grid container justifyContent='center' className={classes.my3} alignItems='center'>
        <Typography variant='h4' >You do not have any connection yet</Typography>
      </Grid>}
      <Dialog open={openConsentForm}>
        <DialogTitle>
          Google sign in required !
        </DialogTitle>
        <DialogContent>
          You need to sign in with google to schedule meeting with mentor. Do you want to continue?
        </DialogContent>
        <DialogActions>
          <Button className={`${classes.ttc} ${classes.font_non_bold}`} variant='outlined' color='primary' size='small'
            onClick={() => setOpenConsentForm(false)}
          >Cancel</Button>
          <Button className={`${classes.ttc} ${classes.font_non_bold}`} variant='contained' color='primary' size='small'
            onClick={() => handleAuthClick(currentUser.user_email, setOpenConsentForm, props.handleSnackBar, setOpenMeetingScheduler, setIsSignedInWithGoogle)}
          >Continue</Button>
        </DialogActions>
      </Dialog>
      <MeetingScheduler openMeetingScheduler={openMeetingScheduler} setOpenMeetingScheduler={setOpenMeetingScheduler} classes={classes} currentUser={currentUser} setInitials={setInitials} handleSnackBar={props.handleSnackBar} type='menteeToMentor' attendee_email={attendeeEmail} matchesWidth={props.matchesWidth} />
    </>
  )
}

export const ConnectedMenteesList = (props) => {
  const classes = useStyles();
  const [connectionsList, setConnectionsList] = useState([]);

  const [openConsentForm, setOpenConsentForm] = useState(false);
  const [isSignedInWithGoogle, setIsSignedInWithGoogle] = useState(false);
  const [openMeetingScheduler, setOpenMeetingScheduler] = useState(false);
  const [currentUser, setCurrentUser] = useState('');
  const [attendeeEmail, setAttendeeEmail] = useState('');

  let id = localStorage.getItem('jobseeker_id');

  const connectedMentees = () => {
    let payload2 = `?limit=100&offset=0`
    ApiHandler.getConnectedMentees(id, payload2, res => {
      if (res.status == 200) {
        setConnectionsList(res.data.data)
      }
    })
  }

  React.useEffect(() => {
    connectedMentees();
  }, []);

  return (
    <>
      {connectionsList?.map((user, index) => {
        return <Grid key={user.mentee_id} item container wrap='nowrap' alignItems='center' className={`${classes.singleChat} ${classes.mt2} ${classes.px1} ${classes.py3} ${classes.tileContainer} ${classes.cursor_pointer}`}
        onClick={() => {
          props.setOpenFullProfileDialog(true);
          props.setCurrentMentorId(user.mentee_id);
        }}
        >
          <Grid xs={9} wrap='nowrap' alignItems='center' item container>
            {user?.mentee_details?.user_image_url ? <img className={`${classes.mr3} ${classes.border_rounded}`} src={user?.mentee_details?.user_image_url} width={45} height={45} /> :
              <p className={`${classes.nameInitials}`}> {setInitials(user.mentee_details)} </p>}
            <Grid container item direction='column'>
              <Typography className={`${classes.mb1} ${classes.ttc}`} variant='h4'>{user?.mentee_details?.user_first_name} {user?.mentee_details?.user_last_name}</Typography>
              {/* {user.user_experiences.user_post && <Typography className={classes.colorLight} variant='caption'>{user.user_experiences.user_post}, {user.user_experiences.company_name}</Typography>} */}
            </Grid>
          </Grid>
          <Grid xs={3} item container alignItems='center' direction='column'>
            <Button className={`${classes.ttc} ${classes.font_non_bold}`} variant='contained' color='primary' size='small'
              onClick={(e) => {
                e.stopPropagation();
                isSignedInWithGoogle ?
                  setOpenMeetingScheduler(true) :
                  setOpenConsentForm(true);
                setCurrentUser(user.mentee_details);
                setAttendeeEmail(user.mentee_details.user_email);
              }}
            >Schedule</Button>
          </Grid>
        </Grid>
      })}
      {connectionsList.length === 0 && <Grid container justifyContent='center' className={classes.my3} alignItems='center'>
        <Typography variant='h4' >You do not have any connection yet</Typography>
      </Grid>}
      {/* <Grid xs=s */}
      <Dialog open={openConsentForm}>
        <DialogTitle>
          Google sign in required !
        </DialogTitle>
        <DialogContent>
          You need to sign in with google to schedule meeting with mentor. Do you want to continue?
        </DialogContent>
        <DialogActions>
          <Button className={`${classes.ttc} ${classes.font_non_bold}`} variant='outlined' color='primary' size='small'
            onClick={() => setOpenConsentForm(false)}
          >Cancel</Button>
          <Button className={`${classes.ttc} ${classes.font_non_bold}`} variant='contained' color='primary' size='small'
            onClick={() => handleAuthClick(currentUser.user_email, setOpenConsentForm, props.handleSnackBar, setOpenMeetingScheduler, setIsSignedInWithGoogle)}
          >Continue</Button>
        </DialogActions>
      </Dialog>
      <MeetingScheduler openMeetingScheduler={openMeetingScheduler} setOpenMeetingScheduler={setOpenMeetingScheduler} classes={classes} currentUser={currentUser} setInitials={setInitials} handleSnackBar={props.handleSnackBar} type='mentorToMentee' attendee_email={attendeeEmail} matchesWidth={props.matchesWidth} />
    </>
  )
}

//list of mentors when mentee search for them
export const SearchedMentorsList = (props) => {
  const { setOpenFullProfileDialog, user_data } = props;
  const classes = useStyles();
  return (
    <>
      <div className={`${classes.tileContainer} ${classes.my2} ${classes.cursor_pointer}`} onClick={(e) => {
        setOpenFullProfileDialog(true);
        user_data?.mentor_id ? props.setCurrentMentorId(user_data.mentor_id) : props.setCurrentMentorId(user_data._id);
      }}>
        <Grid container>
          <Grid item container direction='column' wrap='nowrap'>
            <Grid item container wrap='nowrap'>
              <Grid item >
                {user_data?.user_details?.user_image_url ? <img src={user_data?.user_details?.user_image_url} width={45} height={45} style={{ marginRight: 10, borderRadius: '50%' }} /> :
                  <p className={`${classes.nameInitials}`}> {setInitials(user_data.user_details)} </p>}
              </Grid>
              <Grid item xs={8} wrap='nowrap' container>
                <Grid container direction='column' item wrap='nowrap'>
                  <Grid className={classes.ml2} container xs item wrap='nowrap'>
                    <Typography className={`${classes.mr2} ${classes.ttc}`} variant='body1' component='h3'>{user_data.user_details.full_name}</Typography>
                  </Grid>
                  <Grid container item xs wrap='nowrap'>
                    {/* <Grid container xs={6} item wrap='nowrap' className={classes.mb1}>
                      <StarIcon
                        fontSize={'inherit'}
                        style={{ color: '#FFAD0D', marginRight: 4 }} />
                      <Typography variant="body2" >
                        4.9
                      </Typography>
                    </Grid> */}
                    {user_data?.work_exp && <Grid container item justifyContent="flex-start" wrap='nowrap' className={`${classes.mb1} ${classes.ml2}`}>
                      <BusinessCenterOutlinedIcon
                        fontSize={'inherit'}
                        style={{ color: '#737373', marginRight: 4 }} />
                      <Typography variant="body2" >
                        {user_data?.work_exp.includes('year') ? user_data?.work_exp : user_data?.work_exp + ' years'}
                      </Typography>
                    </Grid>}
                    <Grid container item justifyContent="flex-start" wrap='nowrap' className={`${classes.mb1} ${classes.ml2}`}>
                      <PersonOutlineOutlinedIcon
                        fontSize={'inherit'}
                        style={{ color: '#737373', marginRight: 4 }} />
                      <Typography variant="body2" >
                        {user_data?.mentor_role && user_data?.mentor_role[0]}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {/* {user_data.user_experiences.user_post && <Typography className={classes.colorLight} variant='body2' component='p'>{user_data.user_experiences.user_post} at {user_data.user_experiences.company_name}</Typography>} */}
              </Grid>
              <Grid item xs={4} wrap='nowrap' container justifyContent='flex-end' alignItems='flex-start'>
                <div id='connect_btn' style={{ display: 'flex', alignItems: 'center' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!user_data.status || user_data.status === 'declined') {
                      props.setOpenMentorshipReqDialog(true);
                      user_data.mentor_id ? props.setCurrentMentorId(user_data.mentor_id) : props.setCurrentMentorId(user_data._id);
                    } else {
                      return
                    }
                  }}
                >
                  {user_data.status === 'pending' ? <ScheduleIcon className={classes.mr1} fontSize='small' color='primary' /> : user_data.status === 'accepted' ? <DoneIcon className={classes.mr1} fontSize='small' color='primary' /> : <PersonAddOutlinedIcon className={classes.mr1} fontSize='small' color='primary' />}

                  <Typography variant='body2' color='primary'>{user_data.status === 'pending' ? 'Pending' : user_data.status === 'accepted' ? 'Connected' : 'Connect'}</Typography>
                </div>
              </Grid>
            </Grid>
            {user_data?.curr_role && <Grid style={{ paddingLeft: 50 }} className={`${classes.colorLight} ${classes.ml2}`} container item>
              <Typography variant='caption'>
                {user_data.curr_role} | {user_data.curr_org}
              </Typography>
            </Grid>}
          </Grid>
          {user_data.mentor_role && <div className={classes.horizontalLineBreak}></div>}
          {user_data.mentor_role && <Grid
            item
            container
            justifyContent="space-between"
            // alignItems="flex-end"
            wrap='nowrap'
            direction='column'
            className={classes.mb1}
          >
            <Typography variant="h4">Topics</Typography>
          </Grid>}
          {user_data.mentor_role && <Grid
            item
            container
          >
            {user_data?.mentor_role?.map((topic, index) => {
              return <Chip key={index} size='small' className={`${classes.my1} ${classes.mr1}`} variant="outlined" label={topic} />
            })}
          </Grid>}
          {/* <Grid item container justifyContent='flex-end' >
            <Button style={{ padding: '2px 10px' }} className={`${classes.mt2} ${classes.ttc} ${classes.font_non_bold}`} startIcon={<LaunchIcon color='primary' />} variant='outlined' color='primary'>View full profile</Button>
          </Grid> */}
        </Grid>
      </div>
    </>
  )
}

export const RecommendedMenteesList = (props) => {
  const { user_data } = props;
  const classes = useStyles();
  return (
    <>
      <div className={`${classes.mr2} ${classes.tileContainer} ${classes.py3} ${classes.cursor_pointer}`} style={{ width: 171, margin: 0 }} >
        <Grid container direction='column' alignItems='center' justifyContent='center'
          onClick={() => {
            props.setOpenFullProfileDialog(true);
            props.setCurrentMentorId(user_data._id);
          }} className={classes.sliderContainerMentees}>
          <img className={`${classes.border_rounded} ${classes.mr2} ${classes.boxShadowImg}`} src={user_data?.user_image_url} width={40} height={40} alt='user_avatar' />
          <Typography variant='body1' className={`${classes.ttc} ${classes.mt2}`} component='h3'>{user_data?.full_name}</Typography>
          {/* <Typography className={classes.colorLight} variant='body2' component='p'>UI/UX Designer, Meet</Typography> */}
          <Grid item container style={{ marginTop: 25 }} >
            <Button style={{ padding: '2px 10px' }} fullWidth className={`${classes.mt2} ${classes.ttc} ${classes.tilesConnectBtn}`} startIcon={user_data.status === 'pending' ? <ScheduleIcon fontSize='small' color='primary' /> : user_data.status === 'accepted' ? <DoneIcon fontSize='small' color='primary' /> : <LaunchIcon fontSize='small' color='primary' />} variant='outlined' color='primary'
              onClick={(e) => {
                e.stopPropagation();
                if (!user_data.status || user_data.status === 'declined') {
                  props.setOpenMentorshipReqDialog(true);
                  props.setCurrentMentorId(user_data._id);
                }
              }} >
              <Typography variant='body2' color='primary'>{user_data.status === 'pending' ? 'Pending' : user_data.status === 'accepted' ? 'Connected' : 'Connect'}</Typography>
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export const SearchedMenteesList = (props) => {
  const { setOpenFullProfileDialog, setOpenMentorshipReqDialog, user_data } = props
  const classes = useStyles();
  return (
    <>
      <div className={`${classes.tileContainer} ${classes.my2} ${classes.cursor_pointer}`}>
        <Grid container onClick={() => {
          setOpenFullProfileDialog(true);
          props.setCurrentMentorId(user_data._id);
        }}>
          <Grid item container wrap='nowrap' alignItems='center'>
            {user_data?.user_details?.user_image_url ? <img src={user_data?.user_details?.user_image_url} className={classes.border_rounded} width={45} height={45} style={{ marginRight: 10 }} /> :
              <p className={`${classes.nameInitials}`}> {setInitials(user_data?.user_details)} </p>}
            <Grid item xs={6} container direction='column'>
              <Typography variant='body1' className={classes.ttc} component='h3'>{user_data.user_details.full_name}</Typography>
              {user_data?.user_experiences?.user_post && <Typography className={classes.colorLight} variant='body2' component='p'>{user_data?.user_experiences?.user_post}, {user_data?.user_experiences?.company_name}</Typography>}
            </Grid>
            <Grid item xs={3} wrap='nowrap' container justifyContent='flex-end' alignItems='center'
              onClick={(e) => {
                if (!user_data.status || user_data.status === 'declined') {
                  e.stopPropagation();
                  setOpenMentorshipReqDialog(true);
                  props.setCurrentMentorId(user_data._id);
                } else {
                  return
                }
              }} >
              {user_data.status === 'pending' ? <ScheduleIcon className={classes.mr1} fontSize='small' color='primary' /> : user_data.status === 'accepted' ? <DoneIcon className={classes.mr1} fontSize='small' color='primary' /> : <PersonAddOutlinedIcon className={classes.mr1} fontSize='small' color='primary' />}
              <Typography variant='body2' color='primary'>{user_data.status === 'pending' ? 'Pending' : user_data.status === 'accepted' ? 'Connected' : 'Connect'}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export const RecentMenteesList = (props) => {
  const { user_data } = props;
  const classes = useStyles();
  return (
    <>
      <div className={`${classes.mr2} ${classes.tileContainer} ${classes.py3} ${classes.cursor_pointer}`} style={{ width: 171, margin: 0 }} >
        <Grid container direction='column' alignItems='center' justifyContent='center'
          onClick={() => {
            props.setOpenFullProfileDialog(true);
            props.setCurrentMentorId(user_data._id);
          }}
          className={`${classes.sliderContainerMentees}`}>
          {user_data?.user_image_url ? <img src={user_data?.user_image_url} className={`${classes.border_rounded} ${classes.mr2} ${classes.boxShadowImg}`} width={40} height={40} alt='avatar' /> :
            <p className={`${classes.nameInitials}`}> {setInitials(user_data)} </p>}
          <Typography variant='body1' className={`${classes.ttc} ${classes.mt2}`} component='h3'>{user_data?.full_name}</Typography>
          {/* <Typography className={classes.colorLight} variant='body2' component='p'>UI/UX Designer, Meet</Typography> */}
          <Grid item container style={{ marginTop: 25 }} >
            <Button style={{ padding: '2px 10px' }} fullWidth className={`${classes.mt2} ${classes.ttc} ${classes.tilesConnectBtn}`} startIcon={user_data.status === 'pending' ? <ScheduleIcon fontSize='small' color='primary' /> : user_data.status === 'accepted' ? <DoneIcon fontSize='small' color='primary' /> : <LaunchIcon fontSize='small' color='primary' />} variant='outlined' color='primary'
              onClick={(e) => {
                e.stopPropagation();
                if (!user_data.status || user_data.status === 'declined') {
                  props.setOpenMentorshipReqDialog(true);
                  props.setCurrentMentorId(user_data._id);
                }
              }} >
              <Typography variant='body2' color='primary'>{user_data.status === 'pending' ? 'Pending' : user_data.status === 'accepted' ? 'Connected' : 'Connect'}</Typography>
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  )
}
export const RecentMentorsList = (props) => {
  const { user_data } = props;
  const classes = useStyles();
  return (
    <>
      <div className={`${classes.mr2} ${classes.tileContainer} ${classes.py3} ${classes.cursor_pointer}`} style={{ width: 171, margin: 0 }} >
        <Grid container direction='column' alignItems='center' justifyContent='center'
          onClick={() => {
            props.setOpenFullProfileDialog(true);
            props.setCurrentMentorId(user_data._id);
          }} className={classes.sliderContainerMentees}>
          {user_data?.user_details?.user_image_url ? <img src={user_data?.user_details?.user_image_url} className={`${classes.boxShadowImg} ${classes.border_rounded}`} width={40} height={40} style={{ marginRight: 10 }} alt='avatar' /> :
            <p className={`${classes.nameInitials}`}> {setInitials(user_data?.user_details)} </p>}
          <Typography variant='body1' className={`${classes.ttc} ${classes.mt2}`} component='h3'>{user_data?.user_details?.full_name}</Typography>
          {user_data?.curr_role && <Typography className={classes.colorLight} variant='body2' component='p'>{user_data?.curr_role}, {user_data?.curr_org}</Typography>}
          <Grid item container style={{ marginTop: 25 }} >
            <Button style={{ padding: '2px 10px' }} fullWidth className={`${classes.mt2} ${classes.ttc} ${classes.tilesConnectBtn}`} startIcon={user_data.status === 'pending' ? <ScheduleIcon fontSize='small' color='primary' /> : user_data.status === 'accepted' ? <DoneIcon fontSize='small' color='primary' /> : <LaunchIcon fontSize='small' color='primary' />} variant='outlined' color='primary'
              onClick={(e) => {
                e.stopPropagation();
                if (!user_data.status || user_data.status === 'declined') {
                  props.setOpenMentorshipReqDialog(true);
                  props.setCurrentMentorId(user_data._id);
                }
              }} >
              <Typography variant='body2' color='primary'>{user_data.status === 'pending' ? 'Pending' : user_data.status === 'accepted' ? 'Connected' : 'Connect'}</Typography>
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  )
}
