import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import TextField from '@material-ui/core/TextField';
import { useHistory } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

import OtpInput from 'react-otp-input';

import ApiHandler from '../ApiHandler';
import AnalyticsHandler from '../AnalyticsHandler';

const useStyles = makeStyles(theme => ({
  flexContainer: {
    height: 60,
    flexGrow: 1,
  },
  drawerPaper: {
    borderRadius: '20px 20px 0px 0px',
  },
  drawer: {
    width: 'auto',
    height: 'auto',
    minHeight: 100,
    paddingTop: 40,
    paddingBottom: 40,
    background: '#FFFFFF',
    boxShadow: ' 0px -4px 8px rgba(0, 0, 0, 0.12)',
    borderRadius: '20px 20px 0px 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  drawerCloseBtn: {
    alignSelf: 'flex-end',
    margin: 8,
  },
  drawerBackBtn: {
    position: 'absolute',
    alignSelf: 'flex-start',
    margin: 8,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 300,
    marginTop: 20,
  },
  TfRoot: {
    width: '100%',
    marginBottom: 45,
    //marginBottom: 25,
    background: theme.palette.common.greyLight,
    '& input': {
      height: 48,
      '&::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      },
      '&::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      }
    },
    '& fieldset': {
      border: 'transparent',
    },
  },
  TfBlockRoot: {
    //width: '100%',
    //marginBottom: 20,
    borderRadius: 4,
    background: theme.palette.common.greyLight,
    '& input': {
      height: 48,
      width: 48,
      textAlign: 'center',
      '&:focus': {
        background: theme.palette.primary.light,
      },
      '&::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      },
      '&::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      }
    },
    '& fieldset': {
      border: 'transparent',
    },
  },
  drawerActionButton: {
    width: '100%',
    maxWidth: 200,
    height: 36,
    // marginBottom: 15,
    backgroundColor: '#14967B',
    color: 'white',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#14967B',
    },
  },
  linkButton: {
    textTransform: 'unset',
    color: '#14967B',
  },
  buttonRed: {
    textTransform: 'unset',
    color: theme.palette.common.redDark
  },
  inputRootAc: {
    padding: '0 9px!important',
  },
  checkboxLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 12,
  },
  fcRoot: {
    marginRight: 3,
  },
  hrefLink: {
    color: theme.palette.primary.dark,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.dark,
    }
  },
  button: {
    textTransform: "none",
    width: 140,
  },
  button_pri: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    borderRadius: 5,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  button_sec: {
    backgroundColor: '#fff',
    color: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 4,
    color: theme.palette.common.greyMedium,
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.common.grey,
    textDecoration: 'none',
    padding: '0 10px'
  },
  drawerPanel: {
    padding: 10,
    width: '50%',
    borderTop: '1px solid',
    borderColor: theme.palette.common.grey,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }
}));

export default function LogoutDrawer(props) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const clearLocalStorageAndSignOutFE = () => {
    localStorage.removeItem('is_logged_in');
    localStorage.removeItem('jobseeker_id');
    localStorage.removeItem('profile_completed');
    localStorage.removeItem('is_mentee');
    localStorage.removeItem('is_mentor');
    props.setIsSignedIn(false);
    setTimeout(() => { // to reset the form values if session expires while form filling
      window.location.reload();
    }, 1000)
  }

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  }

  //dialog box for delete--starts
  const [isConfDialogOpen, setIsConfDialogOpen] = React.useState(false);
  const handleConfDialogOpen = () => {
    setIsConfDialogOpen(true);
  }
  const handleConfDialogClose = () => {
    setIsConfDialogOpen(false);
  }

  const handleLogout = (caseParam) => {
    ApiHandler.logout(function (res) {
      if (res.status === 200) {
        AnalyticsHandler.track('Profile_logout_Success');
        AnalyticsHandler.reset();
        clearLocalStorageAndSignOutFE();
        if (caseParam === "onDeleteAccount") {
          handleDialogClose();
          // props.handleSnackBar("Your account has been deleted successfully.");
          props.handleSnackBar("Done");
          return
        }
        handleConfDialogClose();
        props.handleSnackBar("You have logged out successfully.")
      } else if (res.response) { // on error
        if (res.response.data.message === "Session Expire") {
          clearLocalStorageAndSignOutFE();
          props.handleSnackBar("Your Session expired. Please login again.", "error")
        } else {
          props.handleSnackBar("Something went wrong. Please try again.", "error")
        }
        if (process.env.REACT_APP_NODE_ENV === "development") { // for development env do the logout without auth on click
          clearLocalStorageAndSignOutFE();
          if (caseParam === "onDeleteAccount") {
            handleDialogClose();
            props.handleSnackBar("Your account has been deleted successfully.");
            return
          } else if (caseParam === "logoutOnSessionExpire") {
            props.handleSnackBar("Your Session expired. Please login again.", "error");
            return
          }
          handleConfDialogClose();
          props.handleSnackBar("You have logged out successfully.")
        }
      }
    })
  }

  const handleCloseDrawerAndRemain = () => {
    props.setCanDrawerOpen(false);
    props.doRefresh(prev => !prev);
  }

  let jobseekerID = localStorage.getItem("jobseeker_id");

  // const deleteAccount = () => {
  //   AnalyticsHandler.track('Profile_deleteAccount_2_BtnClick');
  //   let data = { jobseeker_id: jobseekerID }
  //   ApiHandler.deleteAccount(data, (response) => {
  //     if (response.status === 200) {
  //       AnalyticsHandler.track('Profile_deleteAccount_Success');
  //       handleLogout("onDeleteAccount");
  //     } else if (response.response) {
  //       if (response.response.data.message === "Session Expire") {
  //         handleLogout("logoutOnSessionExpire");
  //       }
  //     } else {
  //       props.handleSnackBar("Something went wrong. Please try again.", "error");
  //     }
  //   })
  // }

  //Drawer starts
  const [drawerState, setDrawerState] = React.useState({
    bottom: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    // if (open === false) {
    //   setMobileInput("");
    //   setCheckedTc(true);
    //   setCheckedNotifs(true);
    //   setToSignIn(true);
    //   setIsOTPStage(false);
    //   setSignInStage(false);
    //   resetOTPTimer();
    //   resetErrorMsg();
    // }
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerState({ ...drawerState, [anchor]: open });
  };
  //Drawer ends

  // open drawer on trigger from parent component
  useEffect(() => {
    if (props.canDrawerOpen === true) {
      setDrawerState({ ...drawerState, ['bottom']: true });
    }
    else {
      setDrawerState({ ...drawerState, ['bottom']: false });
    }
  }, [props.refresh]);

  return (
    <div>
      <Drawer anchor='bottom'
        open={drawerState['bottom']}
        onClose={toggleDrawer('bottom', false)}
        style={{ background: 'none' }} classes={{ paper: classes.drawerPaper }}>
        <div className={classes.drawer} id="logout_section" role="presentation">

          <Button
            id="logout_btn_id"
            variant="contained"
            onClick={handleConfDialogOpen}
            className={classes.drawerActionButton}
          >
            Logout
          </Button>

          <Button
            id="deleteProfile_id"
            aria-label="save"
            className={classes.buttonRed}
            style={{ margin: '20px 0 40px' }}
            // onClick={() => { setIsDialogOpen(true) }}
            onClick={() => { history.push({
              pathname: '/delete-account'
            }) }}
          >
            Delete Account
          </Button>

          <Grid container justifyContent="center" className={classes.drawerPanel}>
            <a className={classes.link} target="_blank" href="./terms" rel="noopener noreferrer"><Typography variant='body1'>Terms & Conditions </Typography></a>
            <Typography variant='body1'> . </Typography>
            <a className={classes.link} target="_blank" href="./privacy-policy" rel="noopener noreferrer"><Typography variant='body1'>Privacy Policy </Typography></a>
            <Typography variant='body1'> . </Typography>
            <Typography className={classes.link} onClick={() => history.push({pathname: '/support'})} variant='body1'>Support </Typography>
          </Grid>
        </div>
      </Drawer>

      {/* Delete Account Dialog box */}
      {/* <Dialog
        onClose={handleDialogClose}
        aria-labelledby="delete-account-dialog"
        open={isDialogOpen}
        maxWidth={'sm'}
        fullScreen
      >

        <IconButton aria-label="close" className={classes.closeButton} onClick={handleDialogClose}>
          <CloseIcon />
        </IconButton>

        <DialogContent dividers style={{ paddingTop: 50 }}>
          <Grid container justifyContent="center" alignItems="center" direction="column" >

            <WarningRoundedIcon style={{ fontSize: '8rem', color: theme.palette.common.yellowDark }} />

            <Typography gutterBottom variant="h3" style={{ lineHeight: '1.5', textAlign: 'center' }}>
              हम आपकी सहायता कैसे कर सकते हैं?
            </Typography>

            <Button
              id="profile_whatsapp_button"
              variant="contained"
              className={`${classes.pageButton} ${classes.button_pri}`}
              style={{ textTransform: 'unset', width: 'auto' }}
              onClick={() => {
                window.location.href = "https://api.whatsapp.com/send?phone=919580899412&text=Hi Meet team"
              }}
            >
              हमें WhatsApp पे 'Hi' भेजें
            </Button>
          </Grid>

        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Grid container justifyContent="center" alignItems="center" direction="column">
            <Typography gutterBottom variant="h3" style={{ lineHeight: '1.5', textAlign: 'center' }}>
            मुझे अपना Meeत account delete करना हैं. मैं समझता हूँ कि ऐसा करने से मुझे new opportunities और companies नहीं मिलेगी।
            </Typography>
            <Button
              color="primary"
              size="medium"
              style={{ color: '#fff', backgroundColor: theme.palette.common.red, textTransform: 'unset', width: 'auto' }}
              onClick={deleteAccount}
            >
             Delete Account
            </Button>
          </Grid>
        </DialogActions>
      </Dialog> */}

      {/* Confirmation Dialog box */}
      <Dialog onClose={handleConfDialogClose} aria-labelledby="customized-dialog-title" open={isConfDialogOpen} maxWidth={'sm'}>

        <IconButton aria-label="close" className={classes.closeButton} onClick={handleConfDialogClose}>
          <CloseIcon />
        </IconButton>

        <DialogContent dividers style={{ paddingTop: 50 }}>
          <Typography gutterBottom variant="h3" style={{ lineHeight: '1.5' }}>
            क्या आप sure हैं कि आप logout करना चाहते हैं?
          </Typography>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>

          <Button
            color="primary"
            size="medium"
            className={`${classes.button} ${classes.button_sec}`}
            onClick={() => {
              AnalyticsHandler.track('Profile_logout_BtnClick');
              handleLogout();
            }
            }
          >
            Yes
          </Button>

          <Button autoFocus
            color="primary"
            size="medium"
            className={`${classes.button} ${classes.button_pri}`}
            onClick={handleConfDialogClose}
          >
            No
          </Button>

        </DialogActions>
      </Dialog>

    </div>
  );
}