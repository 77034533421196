import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import PhoneIcon from '@material-ui/icons/Phone';
import CakeOutlinedIcon from '@material-ui/icons/CakeOutlined';
import { Button, Chip, Grid, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Rating } from '@material-ui/lab';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import ApiHandler from '../../ApiHandler';

const MenteeFullProfile = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [userDetails, setUserDetails] = useState([]);
    const [userExperience, setUserExperience] = useState([]);
    const [userSkills, setUserSkills] = useState([]);
    const [userEdu, setUserEdu] = useState([]);

    const getFullProfile = () => {
        ApiHandler.getProfile({ jobseeker_id: props.currentMentorId }, res => {
            if (res.status == 200) {
                setUserDetails(res.data)
            }
        })
    }

    const getUserExperience = () => {
        ApiHandler.getExperience({ jobseeker_id: props.currentMentorId }, res => {
            if(res.status == 200){
                setUserExperience(res.data);
            }
        })
    }
    const getUserQual = () => {
        ApiHandler.getQualifications({ jobseeker_id: props.currentMentorId }, res => {
            if(res.status == 200){
                setUserEdu(res.data);
            }
        })
    }
    const getUserSkills = () => {
        ApiHandler.getUserSkills(props.currentMentorId, res => {
            if(res.status == 200){
                setUserSkills(res.data);
            }
        })
    }

    useEffect(() => {
        getFullProfile();
        getUserExperience();
        getUserQual();
        getUserSkills();
    }, [])

    var full_name = userDetails.user_first_name ? userDetails.user_first_name + " " + userDetails.user_last_name : "";
    var full_name_array = full_name.split(" ");
    var k = 0;
    var initials = "";
    for (; k < (full_name_array.length > 2 ? 2 : full_name_array.length); k++) {
        initials = initials + full_name_array[k].charAt(0).toUpperCase();
    }

    return (
        <>

            <Grid
                container
                id={"info_id"}
                className={`${classes.sectionMain} ${classes.profileSectionContainer} ${classes.mb2} ${classes.mt2}`}
                justifyContent='space-between'
            >
                <Grid
                    item
                    container
                    justifyContent="space-between"
                    alignItems="flex-end"
                    wrap='nowrap'
                    className={classes.mb2}
                >
                    <Typography variant="h3" className={classes.colorLight}>Personal Information</Typography>
                </Grid>
                <div className={classes.imgWrapper}
                    style={{ width: 80, height: 80 }}
                    id="imgWrapper">
                    {userDetails?.user_image_url ? <img className={`${classes.img} ${classes.border_rounded}`} src={userDetails?.user_image_url} id="my-image" width='100%' height={80} /> :
                        <div className="imgPholder">
                            <p style={{
                                fontSize: 50, fontWeight: 400, backgroundColor: '#fff', width: 90, height: 90, lineHeight: '90px', textAlign: 'center', borderRadius: '50%', color: '#55c6a9', boxShadow: '0px 1px 6px 0px #00000012'
                            }}> {initials} </p>
                        </div>}
                    {/* {isImgLoading && <CircularProgress color="primary" style={{ position: 'absolute' }} />} */}
                </div>

                <Grid container item xs={8} md={9} direction="column" style={{ padding: '0 0 0px', marginTop: 10, position: 'relative' }}>
                    <Typography variant="h3" className={`${classes.ttc}`} style={{ marginBottom: 4 }}>
                        {userDetails?.user_first_name} {userDetails?.user_last_name}
                    </Typography>
                    <Grid container item justifyContent="flex-start" style={{ flexWrap: "nowrap", marginBottom: 4 }}>
                        <LocationOnOutlinedIcon
                            fontSize={'inherit'}
                            style={{ color: theme.palette.primary.main, marginRight: 4 }} />
                        <Typography variant="body2" >
                            {userDetails?.user_city}
                        </Typography>
                    </Grid>
                    <Grid container item justifyContent="flex-start" style={{ flexWrap: "nowrap", marginBottom: 4 }}>
                        <EmailOutlinedIcon
                            fontSize={'inherit'}
                            style={{ color: theme.palette.primary.main, marginRight: 4 }} />
                        <Typography variant="body2" style={{ lineBreak: 'anywhere' }}>
                            {userDetails?.user_email}
                        </Typography>
                    </Grid>

                    {/* <Grid container item justifyContent="flex-start" style={{ flexWrap: "nowrap", marginBottom: 4 }}>
                        <PhoneIcon
                            fontSize={'inherit'}
                            style={{ color: theme.palette.primary.main, marginRight: 4 }} />
                        <Typography variant="body2">
                            {userDetails?.user_mobile}
                        </Typography>
                    </Grid> */}
                    {/* <Grid container item justifyContent="flex-start" style={{ flexWrap: "nowrap", marginBottom: 4 }}>
                        <CakeOutlinedIcon
                            fontSize={'inherit'}
                            style={{ color: theme.palette.primary.main, marginRight: 4 }} />
                        <Typography variant="body2">
                            06, Sep, 1996
                        </Typography>
                    </Grid> */}
                </Grid>
            </Grid>

            {/* Education */}
            <Grid className={`${classes.profileSectionContainer} ${classes.py3} ${classes.px2}`} container direction='column'>
                <Grid
                    item
                    container
                    justifyContent="space-between"
                    alignItems="flex-end"
                    direction="row"
                    className={classes.pageHeading}
                >
                    <Grid
                        item
                        container
                        justifyContent="space-between"
                        alignItems="flex-end"
                        wrap='nowrap'
                    >
                        <Typography variant="h3" className={classes.colorLight}>Education</Typography>
                    </Grid>
                </Grid>
                <ul>
                    {userEdu.map((item, index) => {
                        return <li key={item.qual_obj_id} className={`${classes.my1} ${classes.position_relative}`}>
                        <Grid item xs={10} container direction='column'>
                            <Typography variant='h4'>{item?.course_type}—{item?.course_name}, {item?.specialization_name}</Typography>
                            <Typography variant='caption'>{item?.user_college} | {item?.user_passing_year}</Typography>
                        </Grid>
                    </li>
                    })}
                </ul>
            </Grid>


            {/* Experience */}
            <Grid className={`${classes.my2} ${classes.profileSectionContainer} ${classes.py3} ${classes.px2}`} container direction='column'>
                <Grid
                    item
                    container
                    justifyContent="space-between"
                    alignItems="flex-end"
                    direction="row"
                    className={classes.pageHeading}
                >
                    <Grid
                        item
                        container
                        justifyContent="space-between"
                        alignItems="flex-end"
                        wrap='nowrap'
                    >
                        <Typography variant="h3" className={classes.colorLight}>Experience</Typography>
                    </Grid>
                </Grid>
                <ul>
                    {userExperience.map((item, index) => {
                        return <li key={item?.experience_id} className={`${classes.my1} ${classes.position_relative}`}>
                        <Grid item xs={11} container direction='column'>
                            <Typography variant='h4'>{item?.user_post}</Typography>
                            <Typography className={classes.mt1} variant='caption'>{item?.company_name} | {item?.experience_type} </Typography>
                            <Typography className={classes.mb1} variant='caption'>{item?.company_starting_date} - {item?.company_ending_date} </Typography>
                            {/* <Typography variant='caption'>I have one year's experience in telecalling in vidwaan coaching classes from gomti nagar vistar and three year's experience in coaching classes . I got learn so many things , to enhance my professional skills , capabilities and knowledge in an organisation which recognize the Hard work and trust me with responsibility and challenges . I seek challenging opportunities where I can fully use my skills for the success for your organization. </Typography> */}
                        </Grid>
                    </li>
                    })}
                </ul>
            </Grid>

            {/* Skills */}
            <Grid className={`${classes.my2} ${classes.profileSectionContainer} ${classes.py3} ${classes.px2}`} container direction='column'>
                <Grid
                    item
                    container
                    justifyContent="space-between"
                    alignItems="flex-end"
                    direction="row"
                    className={classes.pageHeading}
                >
                    <Grid
                        item
                        container
                        justifyContent="space-between"
                        alignItems="flex-end"
                        wrap='nowrap'
                    ><Typography variant="h3" className={classes.colorLight}>Skills
                        </Typography>
                    </Grid>
                </Grid>
                <ul>
                    {userSkills.map((item, index) => {
                        return <li key={item._id} className={classes.px1}>
                        <Grid container alignItems='center' justifyContent='space-between' wrap='nowrap' >
                            <Typography className={classes.ttc} variant='body1'>{item?.skill_name}</Typography>
                            <Rating size='small' name="read-only" value={item?.rating} readOnly />
                        </Grid>
                    </li>
                    })}
                </ul>
            </Grid>
            {/* <Button variant='contained' color='secondary' className={`${classes.ttc} ${classes.py1} ${classes.px3} ${classes.sendReqBtn}`} onClick={() => {
                props.setOpenMentorshipReqDialog(true);
                props.setCurrentMentorId(userDetails._id);
            }} >
                <PersonAddOutlinedIcon className={classes.mr1} fontSize='small' />
                Connect
            </Button> */}

        </>
    )
}

export default MenteeFullProfile