import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import GpsFixedRoundedIcon from '@material-ui/icons/GpsFixedRounded';
import LanguageIcon from '@material-ui/icons/Language';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CircularProgress from '@material-ui/core/CircularProgress';

import SnackBarGeneric from '../../components/SnackBarGeneric';

var axios = require('axios');
var BASE_URL = process.env.REACT_APP_API_ROOT;
const autocompleteService = { current: null };
const useStyles = makeStyles((theme) => ({
    TfRoot: {
        width: '100%',
        marginBottom: 30,
        //background: theme.palette.common.greyLight,
        '& input': {
            height: 48,
            '&::-webkit-outer-spin-button': {
                WebkitAppearance: 'none',
                margin: 0
            },
            '&::-webkit-inner-spin-button': {
                WebkitAppearance: 'none',
                margin: 0
            }
        },
        '& fieldset': {
            border: 'transparent',
        },
    },
    inputRootAc: {
        padding: '0 9px!important',
        background: theme.palette.common.greyLight,
    },
    icon: {
        //color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
        color: '#999',
        fontSize: 16,
        marginTop: 5,
    },
    gpsIcon: {
        paddingRight: 16,
        //color: '#5BC2A8',
        color: '#999',
        fontSize: 16,
        marginTop: 4,
    },
    popperAC: {
        zIndex: 99999
    },
    followersInput: {
        '& div': {
            '& input': {
                fontSize: '0.9em'
            }
        }
    }
}));

export default function Location(props) {
    const classes = useStyles();
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [isNearMeResultLoading, setIsNearMeResultLoading] = React.useState(false);

    const [geographical_type, setGeographicalType] = useState(null);
    const [flag_your_location, setFlagYourLocation] = useState(false);

    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                autocompleteService.current.getPlacePredictions(request, callback);
            }, 200),
        [],
    );

    var option_to_add_single =
    {
        description: "Current Location",
        matched_substrings: [{ length: 0, offset: 0, }],
        structured_formatting:
        {
            main_text: "Current Location",
            main_text_matched_substrings: [{ length: 0, offset: 0 }],
            terms: [{ offset: 0, value: "Current Location" }],
            types: ["locality", "political", "geocode"]
        },
    }

    var option_to_add =
    {
        description: "Current Location",
        matched_substrings: [{ length: 0, offset: 0, }],
        structured_formatting:
        {
            main_text: "Current Location",
            main_text_matched_substrings: [{ length: 0, offset: 0 }],
            terms: [{ offset: 0, value: "Current Location" }],
            types: ["locality", "political", "geocode"]
        },
    }

    var option_to_add_anywhere =
    {
        description: "Anywhere/ Work-from-Home",
        matched_substrings: [{ length: 0, offset: 0 }],
        structured_formatting: {
            main_text: "Anywhere/ Work-from-Home",
            main_text_matched_substrings: [{ length: 0, offset: 0 }],
            terms: [{ offset: 0, value: "Anywhere/ Work-from-Home" }],
            types: ["locality", "political", "geocode"]
        }
    }

    var optionAssociatedWithWfh =
    {
        description: "Anywhere/ Work-from-Home",
        matched_substrings: [
            {
                length: 0,
                offset: 0,

            }],
        structured_formatting:
        {
            main_text: "Anywhere/ Work-from-Home",
            main_text_matched_substrings:
                [{
                    length: 0,
                    offset: 0
                }
                ],
            terms:
                [
                    {
                        offset: 0,
                        value: "wfh"
                    }
                ],
            types:
                [
                    "locality", "political", "geocode"
                ]
        }
        ,
    }

    function change_display(address) {
        var option_to_display =
        {
            description: address,
            matched_substrings: [{ length: 2, offset: 0, }],
            structured_formatting:
            {
                main_text: address,
                main_text_matched_substrings: [{ length: 2, offset: 0 }],
                terms: [{ offset: 0, value: address }],
                types: ["locality", "political", "geocode"]
            }
        }
        return option_to_display;
    }

    useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            if (props.anywhere) {
                setOptions([option_to_add_anywhere, option_to_add_single])
            } else {
                setOptions([option_to_add_single])
            }
            return undefined;
        }

        if (inputValue === "Anywhere/ Work-from-Home") {
            setOptions([option_to_add_single])
            return undefined;
        }

        // if (inputValue === "Anywhere/ Work-from-Home") {
        //     setOptions([option_to_add_single])
        //     return undefined;
        // }

        let wfhTerms = ['wfh', 'work ', 'work from', 'work from home', 'workfromhome', 'anywhere', 'remote'];
        if (wfhTerms.includes(inputValue.toLowerCase())) {
            setOptions([optionAssociatedWithWfh]);
            return undefined;
        }

        fetch({
            input: inputValue,
            componentRestrictions: { country: "in" }
        }, (results) => {
            if (active) {
                let newOptions = [];
                if (results) {
                    if (props.anywhere && flag_your_location) {
                        results = [option_to_add_anywhere]
                    }

                    newOptions = [...newOptions, ...results];
                }
                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    // when cancel button is clicked the value is handled by parent
    useEffect(() => {
        setValue(props.locationValue)
    }, [props.locationValue])

    const locationRef = useRef(true);

    useEffect(() => {
        if (locationRef.current) {
            locationRef.current = false;
            return
        }
        props.setLocationValue(value && value.description ? value.description : value);
        props.setLatitude(latitude);
        props.setLongitude(longitude);
        // props.setIsSaveBtnDisabled(false);
    }, [value, latitude, longitude])

    // snackbar callback starts
    const [snackBarMessage, setSnackBarMessage] = React.useState('')
    const [refreshSnackBar, doRefreshSnackBar] = React.useState(false);
    const [canSnackBarOpen, setCanSnackBarOpen] = React.useState(false);
    const handleSnackBar = (message) => {
        setCanSnackBarOpen(true);
        setSnackBarMessage(message);
        doRefreshSnackBar(prev => !prev)
    }
    // snackbar callback ends

    return (
        <>
            <Autocomplete
                id="location_id"
                ref={locationRef}
                // openOnFocus={!props.locationValue ? true : false} // open the popper if no location added. This will show the current location option.
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                filterOptions={(x) => x}
                options={options}
                autoComplete
                includeInputInList
                filterSelectedOptions
                // loading={isNearMeResultLoading}
                value={value}
                // value={props.data ? props.data.company_address : value}
                onChange={(event, newValue) => {
                    props.setErrors && props.setErrors({ ...props.errors, location: "" })
                    setFlagYourLocation(false);

                    var display = newValue;
                    if (newValue && newValue.types && newValue.types[0]) { // when location is selected from the dropdown
                        setGeographicalType(newValue.types[0]);
                        //props.setIsLocationAutocompletePageOpen();
                        setValue(newValue);
                        props.setLocationValue(newValue && newValue.description ? newValue.description : newValue);
                    }

                    if (newValue && newValue.terms) {
                        var location = newValue.terms[0].value;
                        //console.log(newValue)

                        if (newValue.place_id) { //place_id used instead of terms as for places like medha newValue.terms[0] returns no results
                            if (location !== "Current Location" && location != "Anywhere/ Work-from-Home") {
                                axios.get('https://maps.googleapis.com/maps/api/geocode/json?place_id=' + newValue.place_id + '&key=AIzaSyCjskHW4g3dSHkiL-mdD3bf8iPhkJP3KX0').then((result) => {
                                    if (result) {
                                        setLatitude(result.data.results[0].geometry.location.lat)
                                        setLongitude(result.data.results[0].geometry.location.lng)
                                    }
                                }).then(() => {
                                    setValue(newValue);
                                    //props.setIsLocationAutocompletePageOpen();
                                })
                            }
                        }
                    }
                    if (JSON.stringify(newValue) == JSON.stringify(option_to_add) || JSON.stringify(newValue) == JSON.stringify(option_to_add_single)) { // when current location is selected
                        setIsNearMeResultLoading(true);
                        var lat, lng;
                        setValue("");
                        //setInputValue("");
                        navigator.geolocation.getCurrentPosition(function (position) { //on success
                            lat = position.coords.latitude;
                            lng = position.coords.longitude;
                            setLatitude(lat);
                            setLongitude(lng);

                            axios.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + "," + lng + '&key=AIzaSyCjskHW4g3dSHkiL-mdD3bf8iPhkJP3KX0')
                                .then((response) => {
                                    console.log(response)
                                    var address = response.data.results;
                                    var address_1 = address[1].formatted_address;
                                    display = change_display(address_1);
                                    setIsNearMeResultLoading(false);
                                }).then(() => {
                                    setValue(display);
                                    setFlagYourLocation(true);
                                    //props.setIsLocationAutocompletePageOpen();
                                })
                        }, function () { // onerror
                            setInputValue("") // to remove current location text from location box on error in geolocation API
                            setIsNearMeResultLoading(false);
                            handleSnackBar("You need to enable Location access services from your Browser settings to use this feature.")
                        });
                    }
                    if (JSON.stringify(newValue) === JSON.stringify(option_to_add_anywhere)) {
                        setLatitude(null);
                        setLongitude(null);
                        display = change_display("Anywhere/ Work-from-Home");
                        setValue(display);
                        props.setLocationValue(display.description)
                        //props.setIsLocationAutocompletePageOpen();
                    }

                    if (newValue == optionAssociatedWithWfh) {
                        setLatitude(null);
                        setLongitude(null);
                        setValue(optionAssociatedWithWfh);
                    }
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                    if (newInputValue === "") {
                        setValue("");
                        setLatitude(null);
                        setLongitude(null);
                        setFlagYourLocation(false);
                    }
                    if (newInputValue.length === 1) { //so that if after clicking near me the user selects the input and writes a letter, the map location options should show up without pressing the backspace
                        setFlagYourLocation(false);
                    }
                }}

                renderOption={(option) => {
                    if (option.structured_formatting) {
                        const matches = option.structured_formatting.main_text_matched_substrings;
                        if (matches) {
                            const parts = parse(
                                option.structured_formatting.main_text,
                                matches.map((match) => [match.offset, match.offset + match.length]),
                            );
                            return (
                                <Grid container alignItems="flex-start" direction="column">
                                    {/* {option.description === "Anywhere/ Work-from-Home" &&
                                        (<Grid container alignItems="flex-start">
                                            <Grid item style={{ padding: '0 0 10px' }}>
                                                <Typography variant="body2" color="textSecondary">
                                                    Or Choose
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        )
                                    } */}
                                    <Grid container alignItems="flex-start">
                                        {option.description === "Anywhere/ Work-from-Home" &&
                                            (<Grid item>
                                                <LanguageIcon className={classes.gpsIcon} />
                                            </Grid>)}
                                        {option.description === "Current Location" &&
                                            (<Grid item>
                                                <GpsFixedRoundedIcon className={classes.gpsIcon} style={{ marginTop: '2px' }} />
                                            </Grid>)}
                                        {option.description !== "Anywhere/ Work-from-Home" && option.description !== "Current Location" &&
                                            (<Grid item>
                                                <LocationOnIcon className={classes.icon} />
                                            </Grid>)}
                                        <Grid item xs>
                                            {parts.map((part, index) => (
                                                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400, color: option.structured_formatting.main_text === "Detect Current Location" ? 'black' : '#333', fontSize: 14 }}>
                                                    {part.text}
                                                </span>
                                            ))}
                                            {option.description === "Current Location" ? null
                                                : <Typography variant="body2" color="textSecondary">
                                                    {option.structured_formatting.secondary_text}
                                                </Typography>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        }
                    }
                }
                }

                // classes={{ inputRoot: classes.inputRootAc, popper: classes.popperAC }}
                renderInput={(params) => (
                    <TextField {...params}
                        id="locationInput_id"
                        variant="outlined"
                        className={props.fontSizeClass ? classes.followersInput : ''}
                        placeholder={props.placeholder ? props.placeholder : "Start typing your location..."}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {isNearMeResultLoading ? <CircularProgress style={{ color: '#55c6a9' }} size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                        // classes={{ root: classes.TfRoot }}
                        helperText={props.errors?.location ? "Location is required" : ""}
                        error={props.errors?.location ? true : false}
                    />
                )}
            />

            <SnackBarGeneric canSnackBarOpen={canSnackBarOpen} refresh={refreshSnackBar} message={snackBarMessage} />
        </>
    )
}