import { Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import ApiHandler from '../ApiHandler';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router-dom';
import loader from '../assets/logo-loader.gif'
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    notifContainer: {
        '& div:first-child': {
            width: '100%'
        }
    }
}))

const Notifications = (props) => {
    const { classes } = props;
    const internalClasses = useStyles();
    const history = useHistory();

    const [allNotifications, setAllNotifications] = React.useState([]);
    const [totalNotificationCount, setTotalNotificationCount] = React.useState(0);
    let offset = 0;
    const jobseeker_id = props.localIsLoggedIn ? localStorage.getItem('jobseeker_id') : '';

    //fetch all notifications
    React.useEffect(() => {
        let jobseekerID = localStorage.getItem("jobseeker_id");
        let payload = {
            "offset": offset,
            "limit": 100,
            "jobseeker_id": jobseekerID
        }
        ApiHandler.getAllNotifications(payload, res => {
            let unreadNotif = 0;
            if (res.status == 200) {
                unreadNotif = res.data[0].results.filter(val => !val.is_read).length;
                props.setNotificationBadgeCount(unreadNotif);
                setAllNotifications(res.data[0].results);
                if (res.data[0].totalCount.length !== 0) {
                    setTotalNotificationCount(res.data[0].totalCount[0].count);
                }
                // else {
                //     setAllNotifications([ {
                //         "_id": "65e59240f9c2ce3510ecc5fa",
                //         "notification_title": "Give Feedback to Akash Ranolia",
                //         "notification_data": "Hi Govind, we hope your convercation with Akshita Jain was helpful. If satisfied, please fill out the mentorship feedback form for him. Your feedback is valuable and helps other mentees.",
                //         "status": true,
                //         "is_read": false,
                //         "notification_date": "2024-03-04T09:20:00.255Z",
                //         "notification_type": "request_accepted_mentor",
                //         "device_token": "dSAPeNi6Tias2AYw_aYQ-h:APA91bF6CKE9H5h_qUos_gbh3s6LFI0HEmhh203qytN5hP2fWf_iib1nPMIVqhzqKNJo3kWdPQpdD8HJtP1b0mIwycg4KWu6N8kLDHG5__PWaT3sXzhL0l0QQlB_aCNb_hAp-yWysd0B",
                //         "company_id": "1",
                //         "company_name": "Meet Abhishek",
                //         "jobseeker_id": "65cc40435982dc3fca8e9da9"
                //       }])
                // }
            }
        })
    }, []);

    //redirect to the page when user clicks on a notification
    const redirectToPage = (item) => {
        let currentPath = history.location.pathname;
        let type = item.notification_type;
        if (type === 'expr_review') {
            if (currentPath === '/profile') {
                props.setNotificationBar(false);
            } else {
                history.push({
                    pathname: '/profile'
                })
            }
        }
        else if (type === 'medha_alumnus_status') {
            if (currentPath === '/profile') {
                props.setNotificationBar(false);
            } else {

                history.push({
                    pathname: '/profile'
                })
            }
        }
        else if (type === 'InvitedJobStatus') {
            if (currentPath === '/my-jobs') {
                props.setNotificationBar(false);
            } else {

                history.push({
                    pathname: '/my-jobs'
                })
            }
        }
        else if (type === 'InvitedJobseekers') {
            if (currentPath === '/my-jobs') {
                props.setNotificationBar(false);
            } else {
                history.push({
                    pathname: '/my-jobs'
                })
            }
        }
        else if (type === 'AppliedJobStatus') {
            if (currentPath === '/my-jobs') {
                props.setNotificationBar(false);
            } else {

                history.push({
                    pathname: '/my-jobs'
                })
            }
        }
        else if (type === 'jobpost') {
            if (currentPath === '/all-jobs') {
                props.setNotificationBar(false);
            } else {

                history.push({
                    pathname: '/all-jobs'
                })
            }
        }
        else if (type === 'all_jobpostings') {
            if (currentPath === '/all-jobs') {
                props.setNotificationBar(false);
            } else {

                history.push({
                    pathname: '/all-jobs'
                })
            }
        }
        else if (type === 'feedback') {
            if (currentPath === '/mentorship') {
                props.setNotificationBar(false);
                props.setIsFeedbackFormOpen(true);
            } else {

                history.push({
                    pathname: '/mentorship',
                    state: {
                        "isFeedbackFormOpen": true
                    }
                })
            }
        }
        else if (type === 'request_received_mentor') {
            if (currentPath === '/mentorship') {
                props.setNotificationBar(false);
                props.setDisplayMentorProile(true);
                props.setDisplayMenteeProile(false);
                props.setTabVal(1);
                props.setRequestChipVal(0);
            } else {
                history.push({
                    pathname: '/mentorship',
                    state: {
                        "displayMentorProile": true,
                        "displayMenteeProile": false,
                        "tabVal": 1,
                        "requestedChipVal": 0
                    }
                })
            }
        }
        else if (type === 'request_accepted_mentor') {
            if (currentPath === '/mentorship') {
                props.setNotificationBar(false);
                props.setDisplayMentorProile(true);
                props.setDisplayMenteeProile(false);
                props.setTabVal(2);
            } else {
                history.push({
                    pathname: '/mentorship',
                    state: {
                        "displayMentorProile": true,
                        "displayMenteeProile": false,
                        "tabVal": 2,
                    }
                })
            }
        }
        else if (type === 'request_received_mentee') {
            if (currentPath === '/mentorship') {
                props.setNotificationBar(false);
                props.setDisplayMentorProile(false);
                props.setDisplayMenteeProile(true);
                props.setTabVal(1);
                props.setRequestChipVal(0);
            } else {
                history.push({
                    pathname: '/mentorship',
                    state: {
                        "displayMentorProile": false,
                        "displayMenteeProile": true,
                        "tabVal": 1,
                        "requestedChipVal": 0
                    }
                })
            }
        }
        else if (type === 'request_accepted_mentee') {
            if (currentPath === '/mentorship') {
                props.setNotificationBar(false);
                props.setDisplayMentorProile(false);
                props.setDisplayMenteeProile(true);
                props.setTabVal(2);
            } else {
                history.push({
                    pathname: '/mentorship',
                    state: {
                        "displayMentorProile": false,
                        "displayMenteeProile": true,
                        "tabVal": 2,
                    }
                })
            }
        }
        else if (type === 'news_created') {
            if (currentPath === '/newsfeeds') {
                history.push({
                    pathname: `/newsfeeds/${item.news_id}`
                })
                props.setNotificationBar(false);
            } else {

                history.push({
                    pathname: `/newsfeeds/${item.news_id}`
                })
            }
        }
        else {
            history.push({
                pathname: '/all-jobs'
            })
        }
    }

    //change read/unread status
    const markAsReadUnread = (e, index, item) => {
        let id = e.currentTarget.getAttribute('id');
        let payload = {
            "_id": id
        }
        if (!item.is_read) {
            ApiHandler.changeUnreadStatus(payload, res => {
                if (res.status == 200) {
                    redirectToPage(item.notification_type)
                }
            })
        } else {
            redirectToPage(item)
        }
    }

    const fetchMoreNotifications = () => {
        offset += 100;
        let payload = {
            "offset": offset,
            "limit": 100,
            "jobseeker_id": jobseeker_id
        }
        ApiHandler.getAllNotifications(payload, res => {
            setAllNotifications(allNotifications.concat(res.data[0].results));
        })
    }

    //set notificaton time
    const timeAgo = (date) => {
        let postedDate = new Date(date);
        var relativeTime = moment(postedDate).fromNow();
        return relativeTime;
    };

    return (
        <>
            <Grid container className={internalClasses.notifContainer} style={{ marginTop: props.variant === 'mobile' ? 60 : 0 }}>
                <InfiniteScroll
                    dataLength={allNotifications.length}
                    next={fetchMoreNotifications}
                    hasMore={allNotifications.length !== totalNotificationCount}
                    loader={<img src={loader} width={50} height={50} />}
                    scrollThreshold={0.7}
                    scrollableTarget='notificationDiv'
                >
                    {allNotifications.map((item, index) => {
                        return <Grid key={item._id} id={item._id} onClick={(e) => markAsReadUnread(e, index, item)} style={{ backgroundColor: !item.is_read ? 'rgb(85 198 169 / 20%)' : '#fff', minHeight: 80, padding: '10px 15px', borderBottom: '1px solid #afafaf', cursor: 'pointer' }} container item>
                            <Grid xs={1} item>
                                <div style={{ width: 10, height: 10, borderRadius: '50%', backgroundColor: !item.is_read ? '#5bc2a8' : '#AFAFAF', alignSelf: 'flex-start', marginTop: 5 }}></div>
                            </Grid>
                            <Grid xs={8} direction='column' container item style={{ paddingRight: props.variant === 'mobile' ? '1em' : 0 }}>
                                <Typography style={{ fontWeight: 700 }} variant='body2'>{item.notification_title}</Typography>
                                <Typography variant='body2'>{item.notification_data}</Typography>
                                {/* <Typography variant='body2'>hello</Typography> */}
                            </Grid>
                            <Grid item container justifyContent='flex-end' style={{ alignSelf: 'flex-start' }} xs={3}>
                                <span style={{ color: '#afafaf', fontSize: 12 }}>{timeAgo(new Date(item.notification_date))}</span>
                            </Grid>
                        </Grid>
                    })}
                </InfiniteScroll>
            </Grid>
        </>
    )
}

export default Notifications