import React, { useEffect, useState } from 'react'
import { checkIfUserIsSignedIn, initClient } from '../../googleApi';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import moment from 'moment';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createEvent } from '../../googleApi';
import DateFnsUtils from '@date-io/date-fns';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const setInitials = (displayName) => {
    var full_name = displayName;
    var full_name_array = full_name.split(" ");
    var k = 0;
    var initials = "";
    for (; k < (full_name_array.length > 2 ? 2 : full_name_array.length); k++) {
        initials = initials + full_name_array[k].charAt(0).toUpperCase();
    }
    return initials;
}

const AllEvents = (props) => {
    const classes = useStyles();
    const [allEvents, setAllEvents] = useState([]);
    const [eventToUpdate, setEventToUpdate] = useState({});
    const [openEditEvent, setOpenEditEvent] = useState(false);
    const [openDeleteEvent, setOpenDeleteEvent] = useState(false);
    const [refresh, doRefresh] = useState(false);

    const [summary, setSummary] = useState('');
    const [description, setDescription] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [duration, setDuration] = useState('');
    const [timeSlots, setTimeSlots] = useState([]);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState('');

    useEffect(() => {
        // Initialize form with event data when the component mounts
        if (eventToUpdate) {
            setSummary(eventToUpdate.summary || '');
            setDescription(eventToUpdate.description || '');
            setSelectedDate(formatDate(eventToUpdate?.start?.dateTime));
            setDuration(calculateDuration(eventToUpdate?.start?.dateTime, eventToUpdate?.end?.dateTime));

            // Generate time slots for the selected date
            generateTimeSlots(eventToUpdate?.start?.dateTime, duration, allEvents);
        }
    }, [eventToUpdate]);

    useEffect(() => {
        if (checkIfUserIsSignedIn()) {
            fetchUserEvents();
        }
    }, [refresh]);

    // Function to calculate the duration of the event
    const calculateDuration = (startTime, endTime) => {
        const start = new Date(startTime);
        const end = new Date(endTime);
        return (end - start) / (1000 * 60); // Returns duration in minutes
    };

    function fetchUserEvents() {
        const calendarId = 'primary';

        gapi.client.calendar.events.list({
            calendarId: calendarId,
            timeMin: (new Date()).toISOString(),
            showDeleted: false,
            singleEvents: true,
            maxResults: 10,
            orderBy: 'startTime'
        }).then(response => {
            const events = response.result.items;

            // Filter events by the unique identifier
            const myAppEvents = events.filter(event => {
                if (props.displayMentorProile) {
                    return event.extendedProperties && event.extendedProperties.private && event.extendedProperties.private.appId === 'meetMentorship' && event.extendedProperties.private.type === 'mentorToMentee';
                } else {
                    return event.extendedProperties && event.extendedProperties.private && event.extendedProperties.private.appId === 'meetMentorship' && event.extendedProperties.private.type === 'menteeToMentor';
                }
            });

            if (myAppEvents.length > 0) {
                setAllEvents(myAppEvents);
                myAppEvents.forEach(event => {
                    const start = event.start.dateTime || event.start.date;
                });
            } else {
                console.log('No events found for MyApp.');
            }
        }).catch(error => {
            console.error('Error fetching events:', error);
        });
    }

    function deleteCalendarEvent(calendarId, eventId) {
        gapi.client.calendar.events.delete({
            calendarId: calendarId,
            eventId: eventId
        }).then(() => {
            props.handleSnackBar(`Event cancelled successfully.`);
            doRefresh(p => !p);
            setOpenDeleteEvent(false);
        }).catch(error => {
            console.error('Error deleting event:', error);
        });
    }

    function updateCalendarEvent(calendarId, eventId, updatedEventDetails) {
        // Fetch the existing event details first
        gapi.client.calendar.events.get({
            calendarId: calendarId,
            eventId: eventId
        }).then(response => {
            const event = response.result;

            // Modify the event details as needed
            event.summary = summary || event.summary;
            event.description = description || event.description;
            // event.start = updatedEventDetails.start || event.start;
            // event.end = updatedEventDetails.end || event.end;
            event.attendees = updatedEventDetails.attendees || event.attendees;
            event.start = {
                dateTime: combineDateTime(selectedDate, selectedTimeSlot.slotStart),
                timeZone: 'Asia/Kolkata', // Adjust timeZone accordingly
            },
                event.end = {
                    dateTime: combineDateTime(selectedDate, selectedTimeSlot.slotEnd),
                    timeZone: 'Asia/Kolkata',
                },

                // Now update the event with the modified details
                gapi.client.calendar.events.update({
                    calendarId: calendarId,
                    eventId: eventId,
                    resource: event
                }).then(updatedEvent => {
                    props.handleSnackBar(`Event updated successfully.`);
                    setOpenEditEvent(false);
                    doRefresh(p => !p);
                }).catch(error => {
                    console.error('Error updating event:', error);
                });
        }).catch(error => {
            console.error('Error fetching event:', error);
        });
    }

    // Function to generate time slots
    const generateTimeSlots = (selectedDate, duration, events) => {
        const slots = [];
        const currentDate = new Date();
        const isToday = new Date(selectedDate).toDateString() === currentDate.toDateString(); // Check if the selected date is today

        const startOfDay = new Date(selectedDate);
        startOfDay.setHours(9, 0, 0, 0); //starting time range
        const endOfDay = new Date(selectedDate);
        endOfDay.setHours(18, 0, 0, 0); // //ending time range
        let currentTime = new Date(startOfDay);

        // Helper function to check if a slot conflicts with any existing events
        const isSlotConflict = (slotStart, slotEnd, events) => {
            return events.some(event => {
                const eventStart = new Date(event.start.dateTime);
                const eventEnd = new Date(event.end.dateTime);
                return (slotStart < eventEnd && slotEnd > eventStart); // Check for overlap
            });
        };

        while (currentTime <= endOfDay) {
            const slotStart = new Date(currentTime);
            currentTime.setMinutes(currentTime.getMinutes() + duration);
            const slotEnd = new Date(currentTime);

            // Skip past times if scheduling for today
            if (isToday && slotStart < currentDate) {
                continue;
            }

            // Skip slots that conflict with existing events
            if (isSlotConflict(slotStart, slotEnd, events)) {
                continue;
            }


            if (slotEnd <= endOfDay) {
                slots.push({
                    start: slotStart.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                    end: slotEnd.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                    slotStart, // Keep the actual Date object for updates
                    slotEnd,
                });
            }
        }

        setTimeSlots(slots);
    };

    // Helper to format date into YYYY-MM-DD
    const formatDate = (dateTime) => {
        if (dateTime) {
            const date = new Date(dateTime);
            return date.toISOString().split('T')[0]; // Extract the date part
        }
    };

    // Combine the date and time into the RFC3339 format
    const combineDateTime = (date, time) => {
        const combined = new Date(date);
        const timeDate = new Date(time)
        combined.setHours(timeDate.getHours(), timeDate.getMinutes(), 0, 0);
        return combined.toISOString(); // Convert to RFC3339 format
    };

    return (
        <>
            <Grid container className={`${classes.mt3} ${classes.allEvents}`}>
                {allEvents?.map((item, index) => {
                    return <Grid key={index} container justifyContent='center' className={`${classes.position_relative} ${classes.eventContainer}`}>
                        <Grid wrap='nowrap' item container direction='column' alignItems='center'>
                            <Grid item container wrap='nowrap'>
                                {item.extendedProperties.private.imgUrl ? <img className={`${classes.mr3} ${classes.border_rounded}`} src={item.extendedProperties.private.imgUrl} width={45} height={45} /> :
                                    <p className={`${classes.nameInitials}`}>
                                        {setInitials(item.attendees[0].displayName)}
                                    </p>}
                                <Grid style={{ height: 'fit-content' }} container item direction='column'>
                                    <Typography className={`${classes.ttc} ${classes.font_semi_bold}`}>{item.attendees[0].displayName}</Typography>
                                    <Typography variant='caption' className={classes.colorLight}>{item.attendees[0].email}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item container direction='column'>
                                <Typography variant='body1' className={`${classes.mt2} ${classes.font_semi_bold}`}>{item.summary}</Typography>
                                <Typography variant='body2' className={`${classes.my1}`}>{item.description}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container justifyContent='flex-end' direction='column'>
                            <Grid className={classes.my1} container justifyContent='space-between' alignItems='center'>
                                <Typography variant='caption' className={`${classes.font_semi_bold} ${classes.colorLight}`}>{moment(item.start.dateTime).format('LL')}, {moment(item.start.dateTime).format('LT')} - {moment(item.end.dateTime).format('LT')}</Typography>
                                {/* <Typography className={`${classes.font_semi_bold}`}></Typography> */}
                            </Grid>
                            <Grid className={classes.mt3} container justifyContent='space-around'>
                                <Button color='primary' className={`${classes.ttc} ${classes.eventBtns} ${classes.eventJoinBtn}`} variant='outlined'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.open(item.hangoutLink)
                                    }}
                                    startIcon={<CheckCircleOutlineOutlinedIcon color='blue' />}
                                >Join</Button>
                                <Button color='primary' className={`${classes.ttc} ${classes.eventBtns} ${classes.eventCancelBtn}`} variant='outlined'
                                    onClick={() => {
                                        setOpenDeleteEvent(true);
                                        setEventToUpdate(item);
                                    }}
                                    startIcon={<CancelOutlinedIcon color='#DA2932' />}
                                >Cancel</Button>
                            </Grid>
                        </Grid>
                        <EditOutlinedIcon style={{ position: 'absolute', right: '5%', top: '8%' }} onClick={() => {
                            let slotStart = new Date(item.start.dateTime).toString();
                            let slotEnd = new Date(item.end.dateTime).toString();
                            setEventToUpdate(item);
                            setOpenEditEvent(true);
                            setSelectedTimeSlot({ slotStart, slotEnd })
                        }} className={`${classes.editProfileDetailsIcon}`} />
                    </Grid>
                })}
            </Grid>

            {/* Update event */}
            <Dialog classes={{ paper: classes.updateEventDialogPaper }} fullScreen={!props.matchesWidth ? true : false} open={openEditEvent}>
                <DialogTitle>
                    {Object.keys(eventToUpdate).length > 0 && <Grid container alignItems='center'>
                        <ArrowBackIcon onClick={() => setOpenEditEvent(false)} className={`${classes.mr3} ${classes.cursor_pointer}`} />
                        {eventToUpdate.extendedProperties.private.imgUrl ? <img className={`${classes.mr3} ${classes.border_rounded}`} src={eventToUpdate.extendedProperties.private.imgUrl} width={45} height={45} /> :
                            <p className={`${classes.nameInitials}`}> {setInitials(eventToUpdate.attendees[0].displayName)} </p>}
                        <Typography className={classes.ttc}>{eventToUpdate.attendees[0].displayName}</Typography>
                    </Grid>}
                </DialogTitle>
                <DialogContent style={{ minWidth: '50vw' }}>
                    <Grid item container direction='column' >
                        {/* <Typography className={classes.mb1} variant='body2' >Update Event</Typography> */}
                        <FormControl variant="outlined" className={classes.mb3}>
                            <TextField label='Event Title*' fullWidth variant='outlined' className={`${classes.my2} ${classes.eventTextFields}`} value={summary} onChange={(e) => setSummary(e.target.value)} />
                            <TextField label='Event Description*' multiline minRows={4} fullWidth variant='outlined' className={`${classes.my3} ${classes.eventTextFields}`} value={description} onChange={(e) => setDescription(e.target.value)} />
                            <TextField className={`${classes.my2} ${classes.eventTextFields}`} fullWidth placeholder='Duration*' label='Event duration (minutes)' variant='outlined' value={duration}
                                onChange={(e) => {
                                    setDuration(e.target.value);
                                    setTimeout(() => {
                                        e.target.value > 0 && generateTimeSlots(selectedDate, e.target.value, allEvents); // Regenerate time slots based on duration
                                    }, 2000);
                                }} />
                        </FormControl>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid className={classes.mb2} item xs={12}>
                                <FormControl component="fieldset" style={{ width: '100%' }}>
                                    <DatePicker
                                        autoOk
                                        openTo="date"
                                        variant="inline"
                                        format="dd MMM yyyy"
                                        views={["date", "month", "year"]}
                                        id={"dob_id"}
                                        label='Event Date*'
                                        // value={dob ? dob : null}
                                        // minDate={new Date("2000-01-01")}
                                        value={selectedDate}
                                        onChange={(date) => {
                                            let newItem = moment(date).format();
                                            // newItem = moment(date).format('DD-MMM-YYYY');
                                            setSelectedDate(date);
                                            generateTimeSlots(date, duration, allEvents);
                                        }}
                                        inputVariant="outlined"
                                    />
                                </FormControl>
                            </Grid>
                        </MuiPickersUtilsProvider>
                        {timeSlots.length > 0 && <FormControl variant="outlined" fullWidth margin="normal">
                            <InputLabel id="time-slot-label">Available Time Slot*</InputLabel>
                            <Select
                                labelId="time-slot-label"
                                id="time-slot"
                                value={selectedTimeSlot}
                                onChange={(e) => setSelectedTimeSlot(e.target.value)}
                                label="Select Time Slot"
                            >
                                {timeSlots.map((slot, index) => (
                                    <MenuItem key={index} value={slot}>
                                        {`${slot.start} - ${slot.end}`}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>}
                        <Grid container justifyContent='flex-end'>
                            <Button color='primary' variant='contained' style={{ width: 100 }} className={`${classes.ttc} ${classes.my3}`} onClick={() => updateCalendarEvent('primary', eventToUpdate.id, eventToUpdate)}>Update</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            {/* Delete an event */}
            <Dialog open={openDeleteEvent}>
                <DialogTitle>{eventToUpdate.summary}</DialogTitle>
                <DialogContent>
                    <Typography className={classes.mb1} variant='body2' >Are you sure want to delete this event?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.ttc} color='primary' variant='outlined' onClick={() => setOpenDeleteEvent(false)}>No</Button>
                    <Button className={classes.ttc} color='primary' variant='contained' onClick={() => deleteCalendarEvent('primary', eventToUpdate.id)}>Yes</Button>
                </DialogActions>
            </Dialog>
            {allEvents.length === 0 && <Grid>
                <Typography variant='body1' className={`${classes.textAlignCenter} ${classes.my3} ${classes.font_semi_bold}`}>You have not scheduled meeting with any {props.displayMentorProile ? 'mentee' : 'mentor'}</Typography>
            </Grid>}
        </>
    )
}

export default AllEvents