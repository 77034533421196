import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputAdornment, MenuItem, Radio, RadioGroup, Select, TextField, TextareaAutosize, Typography, useMediaQuery } from '@material-ui/core';
import { useStyles } from './styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';
import ApiHandler from '../../ApiHandler';
import { Autocomplete, createFilterOptions, Rating } from '@material-ui/lab';
import Location from './Location';
import moment from 'moment';
import LinkedIn from '../../assets/linkedin.png';
import Instagram from '../../assets/insta.png';
import Facebook from '../../assets/fb.png';

const filter = createFilterOptions();

export const EditPersonalInfo = (props) => {
    const matchesWidth = useMediaQuery('(min-width:700px)');
    const { user_data } = props;
    const classes = useStyles();
    const [selectedMentorImg, setSelectedMentorImg] = useState(null);
    const [locationValue, setLocationValue] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [roles, setRoles] = useState([]);
    const [errors, setErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [userDetails, setUserDetails] = useState({
        first_name: '',
        last_name: '',
        email: ''
    });
    const [screeningDetails, setScreeningDetails] = useState({
        "mentor_id": props.mentor_id,
        "topics": "",
        "mentor_role": [],
        "work_exp": "",
        "curr_org": "",
        "curr_role": "",
        "industry": "",
        "prev_companies": "",
        "facebook": "",
        "instagram": "",
        "linkedin": "",
        "frequency":"",
        "no_of_hours":"",
        "days_of_week": [],
        "portfolio_url": ""
    });


    useEffect(() => {
        if (user_data) {
            setUserDetails({
                first_name: user_data.user_first_name,
                last_name: user_data.user_last_name,
                email: user_data.user_email
            });
            setScreeningDetails({
                "mentor_id": props.mentor_id,
                "topics": user_data.mentor_details?.topics,
                "mentor_role": user_data.mentor_details?.mentor_role,
                "work_exp": user_data.mentor_details?.work_exp,
                "curr_org": user_data.mentor_details?.curr_org,
                "curr_role": user_data.mentor_details?.curr_role,
                "industry": user_data.mentor_details?.industry,
                "prev_companies": user_data.mentor_details?.prev_companies,
                "facebook": user_data.mentor_details?.facebook,
                "instagram": user_data.mentor_details?.instagram,
                "linkedin": user_data.mentor_details?.linkedin,
                "frequency": user_data.mentor_details?.frequency ? user_data.mentor_details?.frequency : "",
                "no_of_hours": user_data.mentor_details?.no_of_hours ? user_data.mentor_details?.no_of_hours : "",
                "days_of_week": user_data.mentor_details?.days_of_week ? user_data.mentor_details?.days_of_week : [],
                "portfolio_url": user_data.mentor_details?.portfolio_url ? user_data.mentor_details?.portfolio_url : ""
            });
            setLocationValue(user_data.user_city);
        }
    }, [props.openEditPersonalInfo]);

    useEffect(() => {
        ApiHandler.getAllJobRolesandCount("AllJobs", response => {
            if (response.status == 200) {
                setRoles(response.data);
            }
        })
    }, []);

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmit) {
            updateDetails();
        }
    },[errors])

    const setSelectedImg = (e) => {
        if (e.target.files[0]) {
            if (!(e.target.files[0].type === "image/jpg" || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/png")) {
                alert("Please upload a valid file");
            } else {
                setSelectedMentorImg(e.target.files[0]);
            }
        }
    }

    const updateDetails = () => {
        let payload = {
            "mentor_id": props.mentor_id,
            ...userDetails
        }
        ApiHandler.updateMentor(payload, res => {
            if (res.status == 200) {
                props.setOpenEditPersonalInfo(false);
                props.setIsProfileDetailsUpdated(p => !p);
                props.handleSnackBar("Profile updated sucessfully");
            }
        })
        let scrData = {
            "mentor_id": props.mentor_id,
            ...screeningDetails
        }
        ApiHandler.updateScreeningQuesForMentors(scrData, res => {
            console.log(res)
        })
        if (selectedMentorImg) {
            var form_data = new FormData();
            form_data.append('jobseeker_id', props.mentor_id)
            form_data.append('user_image', selectedMentorImg)
            ApiHandler.uploadProfilePic(form_data, res => {
                console.log(res)
            })
        }
        ApiHandler.addEditLocation({
            "jobseeker_id": props.mentor_id,
            "user_city": locationValue,
            "user_latitude": latitude,
            "user_longitude": longitude
        }, res => {
            console.log(res);
        })
    }

    const updateMentorDetails = (e, state, setState) => {
        setState({ ...state, [e.target.name]: e.target.value });
    }

    const validatePersonalDetails = () => {
        const errors = {}
        if (!userDetails.first_name) {
            errors.first_name = 'First name is required*'
        }
        if (!userDetails.last_name) {
            errors.last_name = 'Last name is required*'
        }
        if (!userDetails.email) {
            errors.email = 'Email is required*'
        }
        if (!locationValue) {
            errors.location = 'Location is required*'
        }
        if (!screeningDetails.mentor_role.length === 0) {
            errors.mentor_role = 'Please add atleast one role*'
        }
        if (!screeningDetails.topics) {
            errors.topics = 'Please add atleast one role*'
        }
        if (!screeningDetails.work_exp) {
            errors.work_exp = 'Work experience is required*'
        }
        // if (typeof (screeningDetails.work_exp) !== 'number') {
        //     errors.work_exp = 'Please add a valid number*'
        // }
        if (!screeningDetails.curr_org) {
            errors.curr_org = 'This field is required*'
        }
        if (!screeningDetails.curr_role) {
            errors.curr_role = 'This field is required*'
        }
        if (!screeningDetails.industry) {
            errors.industry = 'This field is required*'
        }
        return errors
    }

    const handleDays = (e) => {
        if (e.target.checked) {
            setScreeningDetails({ ...screeningDetails, days_of_week: [...screeningDetails.days_of_week, e.target.id] })
        } else {
            setScreeningDetails({ ...screeningDetails, days_of_week: screeningDetails.days_of_week.filter(item => item !== e.target.id) })
        }
    }

    return (
        <>
            <Dialog fullScreen={!matchesWidth ? true : false} open={props.openEditPersonalInfo}>
                <DialogTitle className={classes.dialogHeader}>
                    <Grid wrap='nowrap' container alignItems='center'>
                        <ArrowBackIcon className={`${classes.cursor_pointer} ${classes.mr2}`}
                            onClick={() => props.setOpenEditPersonalInfo(false)}
                        />
                        <Typography variant='h3'>Personal info</Typography>
                    </Grid>
                </DialogTitle>
                <DialogContent className={classes.min_width_50}>
                    <Grid container direction='column'>
                        <Grid container justifyContent='space-between' className={classes.my2} wrap='nowrap'>
                            <Grid item xs={4} container justifyContent='center' alignItems='center' className={`${classes.profilePicUpload} ${classes.position_relative}`}>
                                {!selectedMentorImg ? <div className={classes.imgWrapper}
                                    style={{ width: 'inherit', height: '100%' }}
                                    id="imgWrapper">
                                </div> :
                                    <img src={URL.createObjectURL(selectedMentorImg)} width='100%' />
                                }
                                <input accept="image/*" style={{ display: 'none' }} onChange={(e) => setSelectedImg(e)} id="contained-button-file" type="file" />
                                <label htmlFor="contained-button-file">
                                    <Button className={classes.ttc} variant="outlined" color="primary" component="span">
                                        <PublishOutlinedIcon />
                                        <span>Upload Photo</span>
                                    </Button>
                                </label>
                            </Grid>
                            <Grid item xs={6} container direction='column'>
                                <Grid item className={classes.mb3}>
                                    <Typography variant='body1' className={classes.mb1}>First Name<span className={classes.requiredFields}>*</span></Typography>
                                    <TextField
                                        required
                                        id="firstInput_id"
                                        inputProps={{
                                            maxLength: 30,
                                            autoComplete: 'new-password'//to disable autofill
                                        }}
                                        variant="outlined"
                                        onChange={(e) => {
                                            setErrors({ ...errors, first_name: '' });
                                            setUserDetails({ ...userDetails, first_name: e.target.value });
                                        }}
                                        placeholder="First Name*"
                                        value={userDetails.first_name}
                                        classes={{ root: classes.tfRoot2 }}
                                        error={errors?.first_name}
                                        helperText={errors?.first_name}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography variant='body1' className={classes.mb1}>Last Name<span className={classes.requiredFields}>*</span></Typography>
                                    <TextField
                                        required
                                        id="firstInput_id"
                                        inputProps={{
                                            maxLength: 30,
                                            autoComplete: 'new-password'//to disable autofill
                                        }}
                                        variant="outlined"
                                        onChange={(e) => {
                                            setErrors({ ...errors, last_name: '' });
                                            setUserDetails({ ...userDetails, last_name: e.target.value });
                                        }}
                                        placeholder="Last Name*"
                                        value={userDetails.last_name}
                                        classes={{ root: classes.tfRoot2 }}
                                        error={errors?.last_name}
                                        helperText={errors?.last_name}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container direction='column' className={classes.my3}>
                            <Typography variant='body1' className={classes.mb1}>Email<span className={classes.requiredFields}>*</span></Typography>
                            <TextField
                                required
                                id="firstInput_id"
                                inputProps={{
                                    maxLength: 30,
                                    autoComplete: 'new-password'//to disable autofill
                                }}
                                variant="outlined"
                                onChange={(e) => {
                                    setErrors({ ...errors, email: '' });
                                    setUserDetails({ ...userDetails, email: e.target.value });
                                }}
                                placeholder="Email*"
                                value={userDetails.email}
                                classes={{ root: classes.tfRoot2 }}
                                error={errors?.email}
                                helperText={errors?.email}
                            />
                        </Grid>
                        {/* <Grid container direction='column' className={classes.my3}>
                            <Typography variant='body1' className={classes.mb1}>Contact Number</Typography>
                            <TextField
                                required
                                id="firstInput_id"
                                inputProps={{
                                    maxLength: 30,
                                    autoComplete: 'new-password'//to disable autofill
                                }}
                                variant="outlined"
                                // onChange={(e) => { setFirstName(e.target.value); aboutMeErrorReset(); setIsSaveBtnDisabled(false); showSaveCancelBtns(); }}
                                placeholder="Email*"
                                disabled
                                value={user_data.user_mobile}
                                classes={{ root: classes.tfRoot2 }}
                            />
                        </Grid> */}
                        <Grid item xs={12}>
                            <FormControl component="fieldset" style={{ width: '100%' }}>
                                <FormLabel component="legend" className={classes.mb2}>Location<span className={classes.requiredFields}>*</span></FormLabel>
                                <Location
                                    locationValue={locationValue}
                                    setLocationValue={setLocationValue}
                                    setLatitude={setLatitude}
                                    setLongitude={setLongitude}
                                    errors={errors}
                                    setErrors={setErrors}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item className={classes.my3}>
                            <Typography variant='body1' className={classes.mb1}>Areas of expertise<span className={classes.requiredFields}>*</span></Typography>
                            <Autocomplete
                                multiple
                                id="select-sector"
                                options={roles}
                                className={classes.mt1}
                                getOptionLabel={(option) => option.job_role ? option.job_role : option}
                                value={screeningDetails.mentor_role}
                                onChange={(e, newValue) => {
                                    setErrors({ ...errors, mentor_role: '' });
                                    if (newValue) {
                                        let sectorVal = [];
                                        newValue.map((item, index) => {
                                            sectorVal.push(item.job_role ? item.job_role : item)
                                        });
                                        setScreeningDetails({ ...screeningDetails, mentor_role: sectorVal });
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        error={errors?.mentor_role}
                                        helperText={errors?.mentor_role}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item className={classes.mb3}>
                            <Typography variant='body1' className={classes.mb1}>Mention topics in which you can mentor<span className={classes.requiredFields}>*</span></Typography>
                            <TextField
                                style={{ width: '100%' }}
                                variant='outlined'
                                name='topics'
                                multiline
                                minRows={6}
                                placeholder='Example: Get your Resume/CV reviewed, Change Career'
                                value={screeningDetails.topics}
                                onChange={(e) => {
                                    setErrors({ ...errors, topics: '' });
                                    updateMentorDetails(e, screeningDetails, setScreeningDetails);
                                }}
                                error={errors?.topics}
                                helperText={errors?.topics}
                            />
                        </Grid>
                        <Grid item className={classes.mb3}>
                                <Typography variant='body1' className={classes.mb1}>Availability<span className={classes.requiredFields}>*</span></Typography>
                                <RadioGroup style={{ flexDirection: 'row' }} aria-label="gender" name="gender1"
                                value={screeningDetails.frequency} onChange={e => setScreeningDetails({...screeningDetails, frequency: e.target.value})}
                                >
                                    <FormControlLabel value="Weekly" control={<Radio />} label="Weekly" />
                                    <FormControlLabel value="Fortnightly" control={<Radio />} label="Fortnightly" />
                                    <FormControlLabel value="Monthly" control={<Radio />} label="Monthly" />
                                </RadioGroup>

                            </Grid>
                            <Grid item className={classes.mb3}>
                                <Typography variant='body1' className={classes.mb1}>No. of hours<span className={classes.requiredFields}>*</span></Typography>
                                <RadioGroup style={{ flexDirection: 'row' }} aria-label="gender" name="gender1"
                                value={screeningDetails.no_of_hours} onChange={e => setScreeningDetails({...screeningDetails, no_of_hours: e.target.value})}
                                >
                                    <FormControlLabel value="1 hour" control={<Radio />} label="1 hour" />
                                    <FormControlLabel value="2 hours" control={<Radio />} label="2 hours" />
                                    <FormControlLabel value="3 hours" control={<Radio />} label="3 hours" />
                                    <FormControlLabel value="4 hours" control={<Radio />} label="4 hours" />
                                </RadioGroup>
                            </Grid>
                            <Grid item className={classes.mb3}>
                                <Typography variant='body1' className={classes.mb2}>Days available<span className={classes.requiredFields}>*</span></Typography>
                                <FormControl className={`${classes.flexbox} ${classes.timeFormControl}`} >
                                    <FormGroup className={`${classes.days} ${classes.flexboxCol}`} >
                                        <input type="checkbox" id='Monday' checked={screeningDetails?.days_of_week?.includes("Monday")} onChange={(e, index) => handleDays(e, index)} />
                                        <label htmlFor='Monday' >M</label>
                                    </FormGroup>
                                    <FormGroup className={`${classes.days} ${classes.flexboxCol}`} >
                                        <input type="checkbox" id='Tuesday' checked={screeningDetails?.days_of_week?.includes("Tuesday")} onChange={(e, index) => handleDays(e, index)} />
                                        <label htmlFor='Tuesday' >T</label>
                                    </FormGroup>
                                    <FormGroup className={`${classes.days} ${classes.flexboxCol}`} >
                                        <input type="checkbox" id='Wednesday' checked={screeningDetails?.days_of_week?.includes("Wednesday")} onChange={(e, index) => handleDays(e, index)} />
                                        <label htmlFor='Wednesday' >W</label>
                                    </FormGroup>
                                    <FormGroup className={`${classes.days} ${classes.flexboxCol}`} >
                                        <input type="checkbox" id='Thursday' checked={screeningDetails?.days_of_week?.includes("Thursday")} onChange={(e, index) => handleDays(e, index)} />
                                        <label htmlFor='Thursday' >T</label>
                                    </FormGroup>
                                    <FormGroup className={`${classes.days} ${classes.flexboxCol}`} >
                                        <input type="checkbox" id='Friday' checked={screeningDetails?.days_of_week?.includes("Friday")} onChange={(e, index) => handleDays(e, index)} />
                                        <label htmlFor='Friday' >F</label>
                                    </FormGroup>
                                    <FormGroup className={`${classes.days} ${classes.flexboxCol}`} >
                                        <input type="checkbox" id='Saturday' checked={screeningDetails?.days_of_week?.includes("Saturday")} onChange={(e, index) => handleDays(e, index)} />
                                        <label htmlFor='Saturday' >S</label>
                                    </FormGroup>
                                    <FormGroup className={`${classes.days} ${classes.flexboxCol}`} >
                                        <input type="checkbox" id='Sunday' checked={screeningDetails?.days_of_week?.includes("Sunday")} onChange={(e, index) => handleDays(e, index)} />
                                        <label htmlFor='Sunday' >S</label>
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                        <Typography variant='h4' className={classes.mb3}>Work Experience</Typography>
                        <Grid item className={classes.mb3}>
                            <Typography variant='body1' className={classes.mb1}>Total year of Work Experience<span className={classes.requiredFields}>*</span></Typography>
                            <TextField
                                fullWidth
                                required
                                id="firstInput_id"
                                name="work_exp"
                                variant="outlined"
                                classes={{ root: classes.tfRoot2 }}
                                value={screeningDetails.work_exp}
                                onChange={e => {
                                    setErrors({ ...errors, work_exp: '' });
                                    updateMentorDetails(e, screeningDetails, setScreeningDetails);
                                }}
                                error={errors?.work_exp}
                                helperText={errors?.work_exp}
                            />
                        </Grid>
                        <Grid item className={classes.mb3}>
                            <Typography variant='body1' className={classes.mb1}>Current Organisation<span className={classes.requiredFields}>*</span></Typography>
                            <TextField
                                fullWidth
                                required
                                id="firstInput_id"
                                name='curr_org'
                                variant="outlined"
                                classes={{ root: classes.tfRoot2 }}
                                value={screeningDetails.curr_org}
                                onChange={e => {
                                    setErrors({ ...errors, curr_org: '' });
                                    updateMentorDetails(e, screeningDetails, setScreeningDetails);
                                }}
                                error={errors?.curr_org}
                                helperText={errors?.curr_org}

                            />
                        </Grid>
                        <Grid item className={classes.mb3}>
                            <Typography variant='body1' className={classes.mb1}>Current Role<span className={classes.requiredFields}>*</span></Typography>
                            <TextField
                                fullWidth
                                required
                                id="firstInput_id"
                                name='curr_role'
                                variant="outlined"
                                classes={{ root: classes.tfRoot2 }}
                                value={screeningDetails.curr_role}
                                onChange={e => {
                                    setErrors({ ...errors, curr_role: '' });
                                    updateMentorDetails(e, screeningDetails, setScreeningDetails);
                                }}
                                error={errors?.curr_role}
                                helperText={errors?.curr_role}
                            />
                        </Grid>
                        <Grid item className={classes.mb3}>
                            <Typography variant='body1' className={classes.mb1}>Industry<span className={classes.requiredFields}>*</span></Typography>
                            <TextField
                                fullWidth
                                required
                                id="firstInput_id"
                                name='industry'
                                variant="outlined"
                                classes={{ root: classes.tfRoot2 }}
                                value={screeningDetails.industry}
                                onChange={e => {
                                    setErrors({ ...errors, industry: '' });
                                    updateMentorDetails(e, screeningDetails, setScreeningDetails);
                                }}
                                error={errors?.industry}
                                helperText={errors?.industry}
                            />
                        </Grid>
                        <Grid item className={classes.mb3}>
                            <Typography variant='body1' className={classes.mb1}>Previous Companies</Typography>
                            <TextField
                                fullWidth
                                required
                                id="firstInput_id"
                                name='prev_companies'
                                variant="outlined"
                                classes={{ root: classes.tfRoot2 }}
                                value={screeningDetails.prev_companies}
                                onChange={e => updateMentorDetails(e, screeningDetails, setScreeningDetails)}
                            />
                        </Grid>
                        <Typography variant='h4' className={classes.mb3}>Social Media Handles</Typography>
                        <Grid item className={classes.mb3}>
                            <TextField
                                className={classes.margin}
                                id="input-with-icon-textfield"
                                name='linkedin'
                                fullWidth
                                variant='outlined'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={LinkedIn} width={20} height={20} />
                                        </InputAdornment>
                                    ),
                                }}
                                value={screeningDetails.linkedin}
                                onChange={e => updateMentorDetails(e, screeningDetails, setScreeningDetails)}

                            />
                        </Grid>
                        <Grid item className={classes.mb3}>
                            <TextField
                                className={classes.margin}
                                id="input-with-icon-textfield"
                                name='instagram'
                                fullWidth
                                variant='outlined'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={Instagram} width={20} height={20} />
                                        </InputAdornment>
                                    ),
                                }}
                                value={screeningDetails.instagram}
                                onChange={e => updateMentorDetails(e, screeningDetails, setScreeningDetails)}
                            />
                        </Grid>
                        <Grid item className={classes.mb3}>
                            <TextField
                                className={classes.margin}
                                id="input-with-icon-textfield"
                                name='facebook'
                                fullWidth
                                variant='outlined'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={Facebook} width={20} height={20} />
                                        </InputAdornment>
                                    ),
                                }}
                                value={screeningDetails.facebook}
                                onChange={e => updateMentorDetails(e, screeningDetails, setScreeningDetails)}
                            />
                        </Grid>

                        <Grid className={classes.my3} item container justifyContent='flex-end'>
                            <Button className={`${classes.ttc} ${classes.mr2}`} color='primary' variant='outlined' onClick={() => props.setOpenEditPersonalInfo(false)}>Cancel</Button>
                            <Button className={classes.ttc} color='primary' variant='contained' onClick={() => {
                                setErrors(validatePersonalDetails());
                                setIsSubmit(true);
                            }}>Save</Button>
                        </Grid>
                    </Grid>


                </DialogContent>
            </Dialog>
        </>
    )
}


export const EditQualification = (props) => {
    const classes = useStyles();
    const matchesWidth = useMediaQuery('(min-width:700px)');
    const [institutes, setInstitutes] = useState([]);
    const [courses, setCourses] = useState([]);
    const [courseTypes, setCourseTypes] = useState([]);
    const [coursesListOnly, setCoursesListOnly] = useState([]);
    const [specializations, setSpecializations] = useState([]);
    const [errors, setErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [userQualifications, setUserQualifications] = useState({
        "user_passing_year": '',
        "user_college": null,
        "course_name": null,
        "course_type": null,
        "specialization_name": null
    });

    useEffect(() => {
        ApiHandler.getInstitutes_V2(result => {
            setInstitutes(result.data);
        });

    }, []);

    useEffect(() => {
        if (props.qualData) {
            setUserQualifications({
                "user_passing_year": props.qualData.user_passing_year,
                "user_college": props.qualData.user_college,
                "course_name": props.qualData.course_name,
                "course_type": props.qualData.course_type,
                "specialization_name": props.qualData.specialization_name
            })
        }
    }, [props.openEditQualification]);

    useEffect(() => {
        ApiHandler.getCourses_V2((Result) => {
            setCourses(Result.data)
            let list = Result.data.map((item, i) => {
                return item.course_name
            })
            setCoursesListOnly(list)
        })

    }, [])
    // Courses ends 

    // Course Types starts
    useEffect(() => {
        ApiHandler.getCourseTypes_V2((Result) => {
            let arr = Result.data;
            setCourseTypes(arr);
        })
    }, [])
    // CourseTypes  ends 

    // Specializations starts v2
    useEffect(() => {
        ApiHandler.getSpecializations_V2((Result) => {
            setSpecializations(Result.data)
        })
    }, []);

    useEffect(() => {
        if ((Object.keys(errors).length === 0 && isSubmit)) {
            if (Object.keys(props.qualData).length > 0) {
                updateQualification();
            } else {
                addNewQualification();
            }
        }

    }, [errors])

    const validateQualDetails = () => {
        const errors = {}
        if (!userQualifications.user_college) {
            errors.user_college = 'This field is required*'
        }
        if (!userQualifications.course_name) {
            errors.course_name = 'This field is required*'
        }
        if (!userQualifications.course_type) {
            errors.course_type = 'This field is required*'
        }
        if (!userQualifications.specialization_name) {
            errors.specialization_name = 'This field is required*'
        }
        if (!userQualifications.user_passing_year) {
            errors.user_passing_year = 'This field is required*'
        }
        return errors
    }

    const addNewQualification = () => {
        ApiHandler.addMentorQualification({
            "mentor_id": props.mentor_id,
            ...userQualifications
        }, res => {
            if (res.status == 200) {
                props.getMentorQualification();
                props.setOpenEditQualification(false);
                resetQualFields();
                props.handleSnackBar("Details added sucessfully");
            }
        })
    }

    const updateQualification = () => {
        ApiHandler.updateMentorQualification({
            "mentor_id": props.mentor_id,
            "qual_obj_id": props.qualData.qual_obj_id,
            ...userQualifications
        }, res => {
            if (res.status == 200) {
                props.getMentorQualification();
                props.setOpenEditQualification(false);
                resetQualFields();
                props.handleSnackBar("Profile updated sucessfully");
            }
        })
    }

    const resetQualFields = () => {
        setUserQualifications({
            "user_passing_year": '',
            "user_college": null,
            "course_name": null,
            "course_type": null,
            "specialization_name": null
        });
    }

    return (
        <>
            <Dialog fullScreen={!matchesWidth ? true : false} open={props.openEditQualification}>
                <DialogTitle className={classes.dialogHeader}>
                    <Grid wrap='nowrap' container alignItems='center'>
                        <ArrowBackIcon className={`${classes.cursor_pointer} ${classes.mr2}`}
                            onClick={() => {
                                props.setOpenEditQualification(false);
                                resetQualFields();
                            }}
                        />
                        <Typography variant='h3'>Education</Typography>
                    </Grid>
                </DialogTitle>
                <DialogContent className={classes.min_width_50}>
                    <Grid container direction='column'>
                        <Grid item container direction='column'>
                            <Typography variant='body2' className={classes.mb1}>Institute Name<span className={classes.requiredFields}>*</span></Typography>
                            <Autocomplete
                                id="select-roles"
                                options={institutes}
                                className={classes.mt1}
                                freeSolo
                                // inputValue={props.qualData.user_college || ''}
                                value={userQualifications.user_college}
                                getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    // Regular option
                                    return option.name;
                                }}
                                onChange={(e, newValue) => {
                                    setErrors({ ...errors, user_college: '' });
                                    if (newValue) {
                                        // setUser_institute(newValue);
                                        setUserQualifications({ ...userQualifications, "user_college": newValue.name });
                                    } else {
                                        setUserQualifications({ ...userQualifications, "user_college": "" });
                                        // setUser_institute(null);
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder='Search or add new'
                                        error={errors?.user_college}
                                        helperText={errors?.user_college}
                                    />
                                )}
                                getOptionSelected={(option, value) => option === value}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    // Suggest the creation of a new value
                                    if (params.inputValue !== '') {
                                        filtered.push({
                                            name: `${params.inputValue}`,
                                        });
                                    }

                                    return filtered;
                                }}
                            />
                        </Grid>
                        <Grid item container direction='column' className={classes.my3}>
                            <Typography variant='body2' className={classes.mb1}>Year of completion<span className={classes.requiredFields}>*</span></Typography>
                            <TextField
                                id="demo-simple-select"
                                variant='outlined'
                                value={userQualifications.user_passing_year}
                                onChange={(e) => {
                                    setErrors({ ...errors, user_passing_year: '' });
                                    setUserQualifications({ ...userQualifications, "user_passing_year": e.target.value });
                                    // setPassingYear(e.target.value);
                                }}
                                error={errors?.user_passing_year}
                                helperText={errors?.user_passing_year}
                            />
                        </Grid>
                        {/* </Grid> */}
                        <Grid item container direction='column' className={classes.mb3} >
                            <Typography variant='body2' className={classes.mb1}>Degree Type<span className={classes.requiredFields}>*</span></Typography>
                            <Autocomplete
                                id="select-roles"
                                options={courseTypes}
                                className={classes.mt1}
                                value={userQualifications.course_type}
                                getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    // Regular option
                                    return option.course_type;
                                }}
                                onChange={(e, newValue) => {
                                    setErrors({ ...errors, course_type: '' });
                                    if (newValue) {
                                        setUserQualifications({ ...userQualifications, "course_type": newValue.course_type });
                                    } else {
                                        setUserQualifications({ ...userQualifications, "course_type": "" });
                                        // setUser_course_type(null);
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder='Search'
                                        error={errors?.course_type}
                                        helperText={errors?.course_type}
                                    />
                                )}
                                getOptionSelected={(option, value) => option === value}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    // Suggest the creation of a new value
                                    if (params.inputValue !== '') {
                                        filtered.push({
                                            name: `${params.inputValue}`,
                                        });
                                    }

                                    return filtered;
                                }}
                            />
                        </Grid>
                        <Grid item container direction='column'>
                            <Typography variant='body2' className={classes.mb1}>Degree<span className={classes.requiredFields}>*</span></Typography>
                            <Autocomplete
                                id="select-roles"
                                options={courses}
                                className={classes.mt1}
                                value={userQualifications.course_name}
                                getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    // Regular option
                                    return option.course_name;
                                }}
                                onChange={(e, newValue) => {
                                    setErrors({ ...errors, course_name: '' });
                                    if (newValue) {
                                        setUserQualifications({ ...userQualifications, "course_name": newValue.course_name });
                                    } else {
                                        setUserQualifications({ ...userQualifications, "course_name": "" });
                                        // setUser_degree(null);
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder='Search'
                                        error={errors?.course_name}
                                        helperText={errors?.course_name}
                                    />
                                )}
                                getOptionSelected={(option, value) => option === value}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    // Suggest the creation of a new value
                                    if (params.inputValue !== '') {
                                        filtered.push({
                                            name: `${params.inputValue}`,
                                        });
                                    }

                                    return filtered;
                                }}
                            />
                        </Grid>
                        <Grid item container direction='column' className={classes.my3}>
                            <Typography variant='body2' className={classes.mb1}>Stream<span className={classes.requiredFields}>*</span></Typography>
                            <Autocomplete
                                id="select-roles"
                                options={specializations}
                                className={classes.mt1}
                                value={userQualifications.specialization_name}
                                getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    // Regular option
                                    return option.specialization_name;
                                }}
                                onChange={(e, newValue) => {
                                    setErrors({ ...errors, specialization_name: '' });
                                    if (newValue) {
                                        // setUser_stream(newValue);
                                        setUserQualifications({ ...userQualifications, "specialization_name": newValue.specialization_name });
                                    } else {
                                        setUserQualifications({ ...userQualifications, "specialization_name": "" });
                                        // setUser_stream(null);
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder='Search'
                                        error={errors?.specialization_name}
                                        helperText={errors?.specialization_name}
                                    />
                                )}
                                getOptionSelected={(option, value) => option === value}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    // Suggest the creation of a new value
                                    if (params.inputValue !== '') {
                                        filtered.push({
                                            name: `${params.inputValue}`,
                                        });
                                    }

                                    return filtered;
                                }}
                            />
                        </Grid>
                        <Grid className={classes.my3} item container justifyContent='flex-end'>
                            <Button className={`${classes.ttc} ${classes.mr2}`} color='primary' variant='outlined' onClick={() => props.setOpenEditQualification(false)}>Cancel</Button>
                            <Button className={classes.ttc} color='primary' variant='contained' onClick={() => {
                                setErrors(validateQualDetails());
                                setIsSubmit(true);
                            }}>Save</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}

export const EditExperience = (props) => {
    const matchesWidth = useMediaQuery('(min-width:700px)');
    const classes = useStyles();
    const [companies, setAllCompanies] = useState([]);
    const [experienceType, setExperienceType] = useState([]);
    const [allJobRoles, setAllJobRoles] = useState([]);
    const [locationValue, setLocationValue] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [errors, setErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [userExperience, setUserExperience] = useState({
        "user_post": '',
        "company_name": '',
        "experience_type": '',
        "job_desc": '',
        "company_starting_date": '',
        "company_ending_date": '',
        "latitude": '',
        "longitude": '',
        "isWorkFromHome": false,
        "job_experience_or_internship_status": true
    });

    useEffect(() => {
        if (props.expData) {
            setUserExperience({
                "user_post": props.expData.user_post,
                "company_name": props.expData.company_name,
                "experience_type": props.expData.experience_type,
                "job_desc": props.expData.job_desc,
                "company_starting_date": props.expData.company_starting_date,
                "company_ending_date": props.expData.company_ending_date,
                "latitude": props.expData.latitude,
                "longitude": props.expData.longitude,
                "isWorkFromHome": props.expData.company_starting_date,
                "job_experience_or_internship_status": true,
                "city": props.expData.city || ''
            })
        }
    }, [props.openEditExperience]);

    useEffect(() => {
        ApiHandler.getCompanyNames(res => {
            setAllCompanies(res.data);
        })
        ApiHandler.getEmploymentTypes(res => {
            setExperienceType(res.data);
        })
        ApiHandler.getJobRoles_V2(res => {
            setAllJobRoles(res.data);
        })
    }, []);

    useEffect(() => {
        setUserExperience({ ...userExperience, latitude: latitude, longitude: longitude, city: locationValue })
    }, [latitude, longitude]);

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmit) {
            if (Object.keys(props.expData).length > 0) {
                updateExperience();
            } else {
                addNewExperience();
            }
        }
    }, [errors]);

    const addNewExperience = () => {
        ApiHandler.addMentorExperience({
            "mentor_id": props.mentor_id,
            ...userExperience
        }, res => {
            if (res.status == 200) {
                props.getMentorExperience();
                props.setOpenEditExperience(false);
                // resetQualFields();
                props.handleSnackBar("Details added sucessfully");
            }
        })
    }

    const updateExperience = () => {
        ApiHandler.updateMentorExperience({
            "mentor_id": props.mentor_id,
            "experience_id": props.expData.experience_id,
            ...userExperience
        }, res => {
            if (res.status == 200) {
                props.getMentorQualification();
                props.setOpenEditQualification(false);
                resetExpFields();
                props.handleSnackBar("Profile updated sucessfully");
            }
        })
    }

    const resetExpFields = () => {
        setUserExperience({
            "user_post": '',
            "company_name": '',
            "experience_type": '',
            "job_desc": '',
            "company_starting_date": '',
            "company_ending_date": '',
            "latitude": '',
            "longitude": '',
            "isWorkFromHome": false,
            "job_experience_or_internship_status": true
        });
    }

    const validateExpDetails = () => {
        const errors = {}
        if (!userExperience.user_post) {
            errors.user_post = 'This field is required*'
        }
        if (!userExperience.company_name) {
            errors.company_name = 'This field is required*'
        }
        if (!userExperience.experience_type) {
            errors.experience_type = 'This field is required*'
        }
        if (!userExperience.company_starting_date) {
            errors.company_starting_date = 'This field is required*'
        }
        if (!userExperience.company_ending_date) {
            errors.company_ending_date = 'This field is required*'
        }
        if (!userExperience.isWorkFromHome && (!userExperience.latitude || userExperience.longitude)) {
            errors.location = 'This field is required*'
        }
        return errors
    }

    return (
        <>
            <Dialog fullScreen={!matchesWidth ? true : false} open={props.openEditExperience}>
                <DialogTitle className={classes.dialogHeader}>
                    <Grid wrap='nowrap' container alignItems='center'>
                        <ArrowBackIcon className={`${classes.cursor_pointer} ${classes.mr2}`}
                            onClick={() => {
                                props.setOpenEditExperience(false);
                            }}
                        />
                        <Typography variant='h3'>Experience</Typography>
                    </Grid>
                </DialogTitle>
                <DialogContent className={classes.min_width_50}>
                    <Grid container direction='column'>
                        <Grid item container direction='column'>
                            <Typography variant='body2' className={classes.mb1}>Designation/Title<span className={classes.requiredFields}>*</span></Typography>
                            <Autocomplete
                                id="select-roles"
                                options={allJobRoles}
                                className={classes.mt1}
                                freeSolo
                                value={userExperience.user_post}
                                getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    // Regular option
                                    return option.job_role_name;
                                }}
                                onChange={(e, newValue) => {
                                    if (newValue) {
                                        setErrors({ ...errors, user_post: '' });
                                        setUserExperience({ ...userExperience, "user_post": newValue.job_role_name });
                                    } else {
                                        setUserExperience({ ...userExperience, "user_post": "" });
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder='Search or add new'
                                        error={errors?.user_post}
                                        helperText={errors?.user_post}
                                    />
                                )}
                                getOptionSelected={(option, value) => option === value}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    // Suggest the creation of a new value
                                    if (params.inputValue !== '') {
                                        filtered.push({
                                            job_role_name: `${params.inputValue}`,
                                        });
                                    }

                                    return filtered;
                                }}
                            />
                        </Grid>
                        <Grid item container direction='column' className={classes.my3}>
                            <Typography variant='body2' className={classes.mb1}>Company Name<span className={classes.requiredFields}>*</span></Typography>
                            <Autocomplete
                                id="select-roles"
                                options={companies}
                                className={classes.mt1}
                                freeSolo
                                value={userExperience.company_name}
                                getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    // Regular option
                                    return option.company_name;
                                }}
                                onChange={(e, newValue) => {
                                    if (newValue) {
                                        setErrors({ ...errors, company_name: '' });
                                        setUserExperience({ ...userExperience, "company_name": newValue.company_name });
                                    } else {
                                        setUserExperience({ ...userExperience, "company_name": "" });
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder='Search or add new'
                                        error={errors?.company_name}
                                        helperText={errors?.company_name}
                                    />
                                )}
                                getOptionSelected={(option, value) => option === value}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    // Suggest the creation of a new value
                                    if (params.inputValue !== '') {
                                        filtered.push({
                                            company_name: `${params.inputValue}`,
                                        });
                                    }

                                    return filtered;
                                }}
                            />
                        </Grid>
                        <Grid item container direction='column'>
                            <Typography variant='body2' className={classes.mb1}>Experience Type<span className={classes.requiredFields}>*</span></Typography>
                            {/* <TextField
                                inputProps={{ maxLength: 0 }}
                                variant="outlined"
                                placeholder="UI/UX Designer"
                                classes={{ root: classes.tfRoot2 }}
                            /> */}
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                // value={experienceType}
                                onChange={(e) => {
                                    setErrors({ errors, experience_type: '' });
                                    setUserExperience({ ...userExperience, experience_type: e.target.value });
                                }}
                                variant='outlined'
                                error={errors?.experience_type}
                                helperText={errors?.experience_type}
                            >
                                {experienceType.map((item, index) => {
                                    return <MenuItem key={index} value={item} >{item}</MenuItem>
                                })}
                            </Select>
                        </Grid>
                        <Grid item container direction='column' className={classes.my3}>
                            <Typography variant='body2' className={classes.mb1}>Job Description</Typography>
                            <TextField
                                minRows={5}
                                multiline
                                variant="outlined"
                                classes={{ root: classes.tfRoot2 }}
                                onChange={(e) => {
                                    setUserExperience({ ...userExperience, "job_desc": e.target.value })
                                }}
                            />
                        </Grid>
                        <Grid item container wrap='nowrap' className={classes.my3}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid item xs={5} className={classes.mr3}>
                                    <FormControl component="fieldset" style={{ width: '100%' }}>
                                        <FormLabel component="legend" className={classes.tfLabel}>Start Date<span className={classes.requiredFields}>*</span></FormLabel>
                                        <DatePicker
                                            autoOk
                                            disableFuture
                                            openTo="year"
                                            variant="inline"
                                            format="dd MMM yyyy"
                                            error={errors?.company_starting_date}
                                            helperText={errors?.company_starting_date}
                                            views={["year", "month", "date"]}
                                            onChange={(date) => {
                                                setErrors({ errors, company_starting_date: '' });
                                                let newItem = moment(date).format('DD-MMM-YYYY');
                                                setUserExperience({ ...userExperience, "company_starting_date": newItem })

                                            }}
                                            inputVariant="outlined"
                                            className={classes.datePickerInput}
                                        //   helperText={isErrorExp.startDt ? "required" : ""}
                                        //   error={isErrorExp.startDt}
                                        />
                                    </FormControl>
                                </Grid>
                                <React.Fragment>
                                    <Grid item xs={5}>
                                        <FormControl component="fieldset" style={{ width: '100%' }}>
                                            <FormLabel component="legend" className={classes.tfLabel}>End Date<span className={classes.requiredFields}>*</span></FormLabel>
                                            <DatePicker
                                                autoOk
                                                disableFuture
                                                openTo="year"
                                                variant="inline"
                                                format="dd MMM yyyy"
                                                error={errors?.company_ending_date}
                                                helperText={errors?.company_ending_date}
                                                views={["year", "month", "date"]}
                                                //   id={"endDate_id" + index}
                                                //   value={jobEndDate[index] && jobEndDate[index] !== 'Present' ? jobEndDate[index] : null}
                                                onChange={(date) => {
                                                    setErrors({ errors, company_ending_date: '' });
                                                    let newItem = moment(date).format('DD-MMM-YYYY');
                                                    setUserExperience({ ...userExperience, "company_ending_date": newItem })

                                                }}
                                                inputVariant="outlined"
                                                className={classes.datePickerInput}
                                            //   helperText={isErrorExp.endDt ? "required" : isErrorExp.endDtGreater ? "end date must be after start date" : ""}
                                            //   error={isErrorExp.endDt || isErrorExp.endDtGreater}
                                            />
                                        </FormControl>
                                    </Grid>
                                </React.Fragment>
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item container direction='column'>
                            <Typography variant='body2' className={classes.mb1}>Work Location<span className={classes.requiredFields}>*</span></Typography>
                            <Location
                                locationValue={locationValue}
                                setLocationValue={setLocationValue}
                                setLatitude={setLatitude}
                                setLongitude={setLongitude}
                                errors={errors}
                                setErrors={setErrors}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        // checked={state.checkedB}
                                        // onChange={handleChange}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Work from home"
                            />
                        </Grid>
                    </Grid>
                    <Grid className={classes.my3} item container justifyContent='flex-end'>
                        <Button className={`${classes.ttc} ${classes.mr2}`} color='primary' variant='outlined' onClick={() => props.setOpenEditExperience(false)}>Cancel</Button>
                        <Button className={classes.ttc} color='primary' variant='contained' onClick={() => {
                            setErrors(validateExpDetails());
                            setIsSubmit(true);
                        }}>Save</Button>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}


export const EditSkills = (props) => {
    const matchesWidth = useMediaQuery('(min-width:700px)');
    const classes = useStyles();
    const [allSkills, setAllSkills] = useState([]);
    const [sectors, setSectors] = useState([]);
    const [errors, setErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [userSkill, setUserSkill] = useState({
        "skill_name": null,
        "rating": 0,
        "domain": ""
    })

    useEffect(() => {
        ApiHandler.getAllSkills(res => {
            setAllSkills(res.data)
        });
        ApiHandler.getAllSectors(res => {
            setSectors(res.data)
        });

    }, []);

    useEffect(() => {
        if (props.skillData) {
            setUserSkill({
                "skill_name": props.skillData.skill_name,
                "rating": props.skillData.rating,
                "domain": props.skillData.domain
            })
        }
    }, [props.openEditSkill]);

    useEffect(() => {
        if(Object.keys(errors).length === 0 && isSubmit){
            if(Object.keys(props.skillData).length > 0){
                updateSkill();
            } else {
                addNewSkill();
            }
        }
    }, [errors])

    const addNewSkill = () => {
        ApiHandler.addMentorSkill({
            "mentor_id": props.mentor_id,
            ...userSkill
        }, res => {
            if (res.status == 200) {
                props.getMentorSkills();
                props.setOpenEditSkill(false);
                resetSkillFields();
                props.handleSnackBar("Details added sucessfully");
            }
        })
    }

    const updateSkill = () => {
        ApiHandler.updateMentorSkill({
            "skill_id": props.skillData._id,
            ...userSkill
        }, res => {
            if (res.status == 200) {
                props.getMentorSkills();
                props.setOpenEditSkill(false);
                resetSkillFields();
                props.handleSnackBar("Profile updated sucessfully");
            }
        })
    }

    const validateSkillDetails = () => {
        const errors = {};
        if(!userSkill.skill_name){
            errors.skill_name = 'Skill name is required'
        }
        return errors
    }

    const resetSkillFields = () => {
        setUserSkill({
            "skill_name": null,
            "rating": 0,
            "domain": ""
        });
    }

    return (
        <>
            <Dialog fullScreen={!matchesWidth ? true : false} open={props.openEditSkill}>
                <DialogTitle className={classes.dialogHeader}>
                    <Grid wrap='nowrap' container alignItems='center'>
                        <ArrowBackIcon className={`${classes.cursor_pointer} ${classes.mr2}`}
                            onClick={() => {
                                props.setOpenEditSkill(false);
                                resetSkillFields();
                            }}
                        />
                        <Typography variant='h3'>Skills</Typography>
                    </Grid>
                </DialogTitle>
                <DialogContent className={classes.min_width_50}>
                    <Grid item container direction='column' className={classes.my3}>
                        <Typography variant='body2' className={classes.mb1}>Skill Name</Typography>
                        <Autocomplete
                            id="select-roles"
                            options={allSkills}
                            className={classes.mt1}
                            value={userSkill.skill_name}
                            getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                // Regular option
                                return option.skill_name;
                            }}
                            onChange={(e, newValue) => {
                                setErrors({...errors, skill_name: ''});
                                if (newValue) {
                                    setUserSkill({ ...userSkill, "skill_name": newValue.skill_name });
                                } else {
                                    setUserSkill({ ...userSkill, "skill_name": "" });
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder='Search or add new'
                                    error={errors?.skill_name}
                                    helperText={errors?.skill_name}
                                />
                            )}
                            getOptionSelected={(option, value) => option === value}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);

                                // Suggest the creation of a new value
                                if (params.inputValue !== '') {
                                    filtered.push({
                                        skill_name: `${params.inputValue}`,
                                    });
                                }

                                return filtered;
                            }}
                        />
                    </Grid>
                    <Grid item container direction='column' className={classes.my3}>
                        <Typography variant='body2' className={classes.mb1}>Proficiency</Typography>
                        <Rating
                            name="simple-controlled"
                            value={userSkill.rating}
                            onChange={(event, newValue) => {
                                setUserSkill({ ...userSkill, rating: newValue });
                            }}
                        />
                    </Grid>
                    <Grid item container direction='column' className={classes.my3}>
                        <Typography variant='body2' className={classes.mb1}>Domain</Typography>
                        <Autocomplete
                            id="select-roles"
                            options={sectors}
                            className={classes.mt1}
                            value={userSkill.domain}
                            getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                // Regular option
                                return option.sector_name;
                            }}
                            onChange={(e, newValue) => {
                                if (newValue) {
                                    setUserSkill({ ...userSkill, "domain": newValue.sector_name });
                                } else {
                                    setUserSkill({ ...userSkill, "domain": "" });
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder='Search or add new'
                                />
                            )}
                            getOptionSelected={(option, value) => option === value}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);

                                // Suggest the creation of a new value
                                if (params.inputValue !== '') {
                                    filtered.push({
                                        sector_name: `${params.inputValue}`,
                                    });
                                }

                                return filtered;
                            }}
                        />
                    </Grid>
                    <Grid className={classes.my3} item container justifyContent='flex-end'>
                        <Button className={`${classes.ttc} ${classes.mr2}`} color='primary' variant='outlined' onClick={() => props.setOpenEditSkill(false)}>Cancel</Button>
                        <Button className={classes.ttc} color='primary' variant='contained' onClick={() => {
                            setErrors(validateSkillDetails());
                            setIsSubmit(true);
                        }}>Save</Button>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}
export const EditAchievement = (props) => {
    const matchesWidth = useMediaQuery('(min-width:700px)');
    const classes = useStyles();
    const [userAchievement, setUserAchievement] = useState({
        "mentor_achievements": ""
    })
    useEffect(() => {
        if (props.achievementData) {
            setUserAchievement({
                "mentor_achievements": props.achievementData.mentor_achievements
            })
        }
    }, [props.openEditAchievement]);

    const addNewAchievement = () => {
        ApiHandler.addMentorAchievement({
            "mentor_id": props.mentor_id,
            ...userAchievement
        }, res => {
            if (res.status == 200) {
                props.getMentorAchievement();
                props.setOpenEditAchievement(false);
                resetAchievementFields();
                props.handleSnackBar("Details added sucessfully");
            }
        })
    }

    const updateAchievement = () => {
        ApiHandler.updateMentorAchievement({
            "achievement_id": props.achievementData._id,
            ...userAchievement
        }, res => {
            if (res.status == 200) {
                props.getMentorAchievement();
                props.setOpenEditAchievement(false);
                resetAchievementFields();
                props.handleSnackBar("Profile updated sucessfully");
            }
        })
    }

    const resetAchievementFields = () => {
        setUserAchievement({
            "mentor_achievements": ""
        });
    }

    return (
        <>
            <Dialog fullScreen={!matchesWidth ? true : false} open={props.openEditAchievement}>
                <DialogTitle className={classes.dialogHeader}>
                    <Grid wrap='nowrap' container alignItems='center'>
                        <ArrowBackIcon className={`${classes.cursor_pointer} ${classes.mr2}`}
                            onClick={() => {
                                props.setOpenEditAchievement(false);
                                resetAchievementFields();
                            }}
                        />
                        <Typography variant='h3'>Notable Achievements</Typography>
                    </Grid>
                </DialogTitle>
                <DialogContent className={classes.min_width_50}>
                    <Grid item container direction='column' className={classes.my3}>
                        <Typography variant='body2' className={classes.mb1}>Notable Achievement</Typography>
                        <TextField
                            minRows={5}
                            multiline
                            variant="outlined"
                            classes={{ root: classes.tfRoot2 }}
                            value={userAchievement.mentor_achievements}
                            onChange={(e) => {
                                setUserAchievement({ ...userAchievement, "mentor_achievements": e.target.value })
                            }}
                        />
                    </Grid>
                    <Grid className={classes.my3} item container justifyContent='flex-end'>
                        <Button className={`${classes.ttc} ${classes.mr2}`} color='primary' variant='outlined' onClick={() => props.setOpenEditAchievement(false)}>Cancel</Button>
                        <Button className={classes.ttc} color='primary' variant='contained' onClick={Object.keys(props.achievementData).length > 0 ? updateAchievement : addNewAchievement}>Save</Button>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}



