import React, { useEffect } from 'react';
import { useStyles } from './styles';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import PhoneIcon from '@material-ui/icons/Phone';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import { Button, Chip, Dialog, DialogActions, DialogContent, Grid, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Rating } from '@material-ui/lab';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import { useState } from 'react';
import { EditAchievement, EditExperience, EditPersonalInfo, EditQualification, EditSkills } from './EditProfile';
// import StarIcon from '@material-ui/icons/Star';
import ApiHandler from '../../ApiHandler';
import DoneIcon from '@material-ui/icons/Done';
import ScheduleIcon from '@material-ui/icons/Schedule';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';

const FullProfile = (props) => {
    const classes = useStyles();
    const theme = useTheme();

    // const pcVal = localStorage.getItem('profile_completed') === "true" ? true : false;
    // const [isProfileCompleted, setIsProfileCompleted] = React.useState(pcVal);
    const [mentorDetails, setMentorDetails] = useState({});
    const [openEditPersonalInfo, setOpenEditPersonalInfo] = useState(false);
    const [openEditQualification, setOpenEditQualification] = useState(false);
    const [openEditExperience, setOpenEditExperience] = useState(false);
    const [openEditSkill, setOpenEditSkill] = useState(false);
    const [openEditAchievement, setOpenEditAchievement] = useState(false);
    const [userQual, setUserQual] = useState([]);
    const [userExp, setUserExp] = useState([]);
    const [userSkills, setUserSkills] = useState([]);
    const [userAchievements, setUserAchievements] = useState([]);
    const [selectedQual, setSelectedQual] = useState({});
    const [selectedExp, setSelectedExp] = useState({});
    const [selectedSkill, setSelectedSkill] = useState({});
    const [selectedAchievement, setSelectedAchievement] = useState({});
    const mentor_id = props.currentMentorId ? props.currentMentorId : localStorage.getItem("jobseeker_id");
    const [confirmDeletePopup, setConfirmDeletePopup] = useState(false);
    const [deleteItemType, setDeleteItemType] = useState('');
    const [screeningResponse, setScreeningResponse] = useState({});
    const [isProfileDetailsUpdated, setIsProfileDetailsUpdated] = useState(false);

    useEffect(() => {
        getMentorProfile();
        getScreeningResponse();
    }, [isProfileDetailsUpdated]);

    useEffect(() => {
        getMentorQualification();
        getMentorExperience();
        getMentorSkills();
        getMentorAchievement();
    }, []);


    const getMentorProfile = () => {
        let payload = mentor_id + '/' + (props.currentMentorId ? localStorage.getItem("jobseeker_id") : '0')
        ApiHandler.getMentorFullProfile(payload, res => {
            if (res.status == 200) {
                setMentorDetails(res.data.data)
            }
        })
    }

    const getMentorQualification = () => {
        ApiHandler.getMentorQualification(mentor_id, res => {
            if (res.status == 200) {
                setUserQual(res.data);
            }
        })
    }
    const getMentorExperience = () => {
        ApiHandler.getMentorExperience(mentor_id, res => {
            if (res.status == 200) {
                setUserExp(res.data);
            }
        })
    }
    const getMentorSkills = () => {
        ApiHandler.getMentorSkill(mentor_id, res => {
            if (res.status == 200) {
                setUserSkills(res.data.data);
            }
        })
    }
    const getMentorAchievement = () => {
        ApiHandler.getMentorAchievement(mentor_id, res => {
            if (res.status == 200) {
                setUserAchievements(res.data.data);
            }
        })
    }

    const getScreeningResponse = () => {
        ApiHandler.getMentorScreeningResponse(mentor_id, res => {
            if (res.status == 200) {
                setScreeningResponse(res.data.data[0]);
            }
        })
    }

    const deleteCurrentQualification = (qual) => {
        let payload = {
            "qual_obj_id": qual.qual_obj_id,
            "mentor_id": mentor_id
        }
        ApiHandler.deleteMentorQualification(payload, res => {
            setConfirmDeletePopup(false);
            getMentorQualification();
        })
    };

    const deleteCurrentExperience = (exp) => {
        let payload = exp.experience_id
        ApiHandler.deleteMentorExperience(payload, res => {
            setConfirmDeletePopup(false);
            getMentorExperience()
        })
    };

    const deleteCurrentSkill = (skill) => {
        let payload = {
            "skill_id": skill._id
        }
        ApiHandler.deleteMentorSkill(payload, res => {
            setConfirmDeletePopup(false);
            getMentorSkills();
        })
    };
    const deleteCurrentAchievement = (data) => {
        let payload = {
            "achievement_id": data._id
        }
        ApiHandler.deleteMentorAchievement(payload, res => {
            setConfirmDeletePopup(false);
            getMentorAchievement();
        })
    };

    var full_name = mentorDetails.user_first_name ? mentorDetails.user_first_name + " " + mentorDetails.user_last_name : "";
    var full_name_array = full_name.split(" ");
    var k = 0;
    var initials = "";
    for (; k < (full_name_array.length > 2 ? 2 : full_name_array.length); k++) {
        initials = initials + full_name_array[k].charAt(0).toUpperCase();
    }

    return (
        <div className={classes.desktopContainer}>
            <Grid
                item
                xs={12}
                container
                justifyContent="space-between"
                alignItems="flex-end"
                style={{ minHeight: 60, backgroundColor: '#55c6a9' }}
            ></Grid>

            <Grid container item xs={4} md={3} style={{ background: 'white', padding: '0 0 0px', position: 'relative' }}>
                <div className={classes.imgWrapper}
                    style={{ width: 80, height: 80, position: 'absolute', top: '-45px', left: '15%' }}
                    id="imgWrapper">
                    {mentorDetails.user_image_url ? <img className={classes.img} style={{ borderRadius: '50%' }} src={mentorDetails.user_image_url} id="my-image" alt='user_avatar' width={80} height={80}/> :
                        <div className="imgPholder">
                            <p style={{
                                fontSize: 50, fontWeight: 400, backgroundColor: '#fff', width: 90, height: 90, lineHeight: '90px', textAlign: 'center', borderRadius: '50%', color: '#55c6a9', boxShadow: '0px 1px 6px 0px #00000012'
                            }}> {initials} </p>
                        </div>}
                </div>
            </Grid>
            <Grid
                container
                id={"info_id"}
                className={`${classes.sectionMain}`}
                style={{
                    padding: '50px 15px 10px 20px', background: 'white', marginBottom: 10, boxShadow: '0px 1px 6px 0px #00000012'
                }}
                justifyContent='space-between'
            >
                <Grid container item xs={8} md={9} direction="column" style={{ padding: '0 0 0px', marginTop: 10, position: 'relative' }}>
                    {mentorDetails.user_first_name && <Typography className={classes.ttc} variant="h3" style={{ marginBottom: 4 }}>
                        {mentorDetails.user_first_name + ' ' + mentorDetails.user_last_name}
                    </Typography>}
                    {/* <Grid container item justifyContent="flex-start" style={{ flexWrap: "nowrap", marginBottom: 4 }}>
                        <StarIcon
                            fontSize={'inherit'}
                            style={{ color: '#FFAD0D', marginRight: 4 }} />
                        <Typography variant="body2" >
                            4.9
                        </Typography>
                    </Grid> */}
                    {screeningResponse?.curr_role && <Typography variant="body2" style={{ marginBottom: 4, width: '80vw' }}>
                        {screeningResponse?.curr_role} at {screeningResponse?.curr_org}
                    </Typography>}
                    <Grid container item justifyContent="flex-start" style={{ flexWrap: "nowrap", marginBottom: 4 }}>
                        <LocationOnOutlinedIcon
                            fontSize={'inherit'}
                            className={`${classes.colorLight} ${classes.mr1}`} />
                        <Typography className={classes.colorLight} variant="body2" >
                            {mentorDetails.user_city}
                        </Typography>
                    </Grid>
                    <Grid container item justifyContent="flex-start" style={{ flexWrap: "nowrap", marginBottom: 4 }}>
                        <EmailOutlinedIcon
                            fontSize={'inherit'}
                            className={`${classes.colorLight} ${classes.mr1}`} />
                        <Typography className={classes.colorLight} variant="body2" style={{ lineBreak: 'anywhere' }}>
                            {mentorDetails.user_email}
                        </Typography>
                    </Grid>

                    {props.displayMentorProile && <Grid container item justifyContent="flex-start" style={{ flexWrap: "nowrap", marginBottom: 4 }}>
                        <PhoneIcon
                            fontSize={'inherit'}
                            className={`${classes.colorLight} ${classes.mr1}`} />
                        <Typography className={classes.colorLight} variant="body2">
                            {mentorDetails.user_mobile}
                        </Typography>
                    </Grid>}
                    {screeningResponse?.work_exp && <Grid container item justifyContent="flex-start" style={{ flexWrap: "nowrap", marginBottom: 4 }}>
                        <WorkOutlineIcon
                            fontSize={'inherit'}
                            className={`${classes.colorLight} ${classes.mr1}`}
                        />
                        <Typography className={classes.colorLight} variant="body2">
                            {/* {dob ? moment(dob).format('DD MMM YYYY') : <span style={{ color: 'lightgrey' }}>Date of Birth</span>} */}
                            {screeningResponse?.work_exp.includes('year') ? screeningResponse?.work_exp : screeningResponse?.work_exp + ' years'} of Work Experience
                        </Typography>
                    </Grid>}
                    <Grid container item justifyContent="flex-start" style={{ flexWrap: "nowrap", marginBottom: 4 }}>
                        <EventAvailableIcon
                            fontSize={'inherit'}
                            className={`${classes.colorLight} ${classes.mr1}`}
                        />
                        <Typography className={classes.colorLight} variant="body2">
                            Availability: {screeningResponse?.no_of_hours} - {screeningResponse?.frequency}
                        </Typography>
                    </Grid>
                    {screeningResponse?.days_of_week && <Grid container item className={classes.ml3} justifyContent="flex-start" style={{ flexWrap: "nowrap", marginBottom: 4 }}>
                        <Typography style={{display: 'flex'}} className={classes.colorLight} variant="body2">
                            {screeningResponse?.days_of_week.map(day => {
                                return <Chip className={`${classes.mr1} ${classes.mb1} ${classes.weekday_chip}`} variant='outlined' key={day} label={day} />
                            })}
                        </Typography>
                    </Grid>}
                </Grid>
                {props.displayMentorProile && <Typography style={{ display: 'flex' }} variant='body1'>Edit <EditOutlinedIcon onClick={() => setOpenEditPersonalInfo(true)} className={`${classes.editProfileDetailsIcon}`} /></Typography>}
                <div className={classes.horizontalLineBreak}></div>
                <Grid className={`${classes.profileSectionContainer} ${classes.py3} ${classes.px2} ${classes.w100}`} container direction='column'>
                    <Grid
                        item
                        container
                        justifyContent="space-between"
                        alignItems="flex-end"
                        direction="row"
                        className={classes.pageHeading}
                    >
                        <Grid
                            item
                            container
                            justifyContent="space-between"
                            // alignItems="flex-end"
                            wrap='nowrap'
                            direction='column'
                        >
                            <Typography variant="h3">Topics</Typography>
                        </Grid>
                        <Grid
                            item
                            container
                        >
                            {screeningResponse?.mentor_role?.map((role, index) => {
                                return <Chip key={index} className={`${classes.my1} ${classes.mr1}`} variant="outlined" label={role} />
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {/* Education */}
            <Grid className={`${classes.profileSectionContainer} ${classes.py3} ${classes.px2}`} container direction='column'>
                <Grid
                    item
                    container
                    justifyContent="space-between"
                    alignItems="flex-end"
                    direction="row"
                    className={classes.pageHeading}
                >
                    <Grid
                        item
                        container
                        justifyContent="space-between"
                        alignItems="flex-end"
                        wrap='nowrap'
                    >
                        <Typography variant="h3" color="primary">Education</Typography>
                        {props.displayMentorProile && <Grid className={classes.addProfileDetailIcon} xs={2} wrap='nowrap' onClick={() => {
                            setOpenEditQualification(true);
                            setSelectedQual({});
                        }}
                            item container alignItems='center' justifyContent='flex-end'>
                            <AddOutlinedIcon className={classes.mr1} color='primary' fontSize='small' />
                            <Typography variant="h4" color="primary"> Add</Typography>
                        </Grid>}
                    </Grid>
                </Grid>
                <ul>
                    {userQual.map((qual, index) => {
                        return <li key={qual.qual_obj_id} className={`${classes.my1} ${classes.position_relative}`}>
                            <Grid item xs={10} container direction='column'>
                                <Typography variant='h4'>{qual.course_type} — {qual.course_name}</Typography>
                                <Typography variant='caption'>{qual.user_college} | {qual.user_passing_year}</Typography>
                                {props.displayMentorProile && <Grid item container justifyContent='flex-end' className={classes.editDeleteDetailsContainer}>
                                    <EditOutlinedIcon className={classes.mr1}
                                        onClick={() => {
                                            setSelectedQual(qual)
                                            setOpenEditQualification(true);
                                        }} />
                                    <DeleteForeverOutlinedIcon onClick={() => {
                                        setSelectedQual(qual);
                                        setDeleteItemType('qual');
                                        setConfirmDeletePopup(true);
                                    }} />
                                </Grid>}
                            </Grid>
                        </li>
                    })}
                </ul>
            </Grid>

            {/* Experience */}
            <Grid className={`${classes.my2} ${classes.profileSectionContainer} ${classes.py3} ${classes.px2}`} container direction='column'>
                <Grid
                    item
                    container
                    justifyContent="space-between"
                    alignItems="flex-end"
                    direction="row"
                    className={classes.pageHeading}
                >
                    <Grid
                        item
                        container
                        justifyContent="space-between"
                        alignItems="flex-end"
                        wrap='nowrap'
                    >
                        <Typography variant="h3" color="primary">Experience</Typography>
                        {props.displayMentorProile && <Grid className={classes.addProfileDetailIcon} xs={2} item container alignItems='center' justifyContent='flex-end' onClick={() => {
                            setOpenEditExperience(true);
                            setSelectedExp({});
                        }}>
                            <AddOutlinedIcon className={classes.mr1} color='primary' fontSize='small' />
                            <Typography variant="h4" color="primary"> Add</Typography>
                        </Grid>}
                    </Grid>
                </Grid>
                <ul>
                    {userExp.map((exp, index) => {
                        return <li key={exp.experience_id} className={`${classes.my1} ${classes.position_relative}`}>
                            <Grid item xs={11} container direction='column'>
                                <Typography variant='h4'>{exp.user_post}</Typography>
                                <Typography className={classes.mt1} variant='caption'>{exp.company_name} | {exp.experience_type} </Typography>
                                <Typography className={classes.mb1} variant='caption'>{exp.company_starting_date} - {exp.company_ending_date} </Typography>
                                <Typography variant='caption'>{exp.job_desc} </Typography>
                                {props.displayMentorProile && <Grid item container justifyContent='flex-end' className={classes.editDeleteDetailsContainer}>
                                    <EditOutlinedIcon className={classes.mr1}
                                        onClick={() => {
                                            setSelectedExp(userExp[index]);
                                            setOpenEditExperience(true);
                                        }} />
                                    <DeleteForeverOutlinedIcon onClick={() => {
                                        setSelectedExp(exp);
                                        setDeleteItemType('exp');
                                        setConfirmDeletePopup(true);
                                    }} />
                                </Grid>}
                            </Grid>
                        </li>
                    })}
                </ul>
            </Grid>

            {/* Achievements */}
            {<Grid className={`${classes.profileSectionContainer} ${classes.py3} ${classes.px2}`} container direction='column'>
                <Grid
                    item
                    container
                    justifyContent="space-between"
                    alignItems="flex-end"
                    direction="row"
                    className={classes.pageHeading}
                >
                    <Grid
                        item
                        // xs={10}
                        container
                        justifyContent="space-between"
                        alignItems="flex-end"
                        wrap='nowrap'
                    >
                        <Typography variant="h3" color="primary">Notable Achievements</Typography>
                        {props.displayMentorProile && <Grid className={classes.addProfileDetailIcon} item container xs={4} alignItems='center' justifyContent='flex-end'
                            onClick={() => {
                                setOpenEditAchievement(true);
                                setSelectedAchievement({});
                            }}
                        >
                            <AddOutlinedIcon className={classes.mr1} color='primary' fontSize='small' />
                            <Typography variant="h4" color="primary"> Add</Typography>
                        </Grid>}
                    </Grid>
                </Grid>
                <ul>
                    {userAchievements?.map((item, index) => {
                        return <li className={classes.position_relative}>
                            <Typography style={{ width: '80%' }} variant='body2'>{item.mentor_achievements}</Typography>
                            {props.displayMentorProile && <Grid item container justifyContent='flex-end' className={classes.editDeleteDetailsContainer}>
                                <EditOutlinedIcon className={classes.mr1}
                                    onClick={() => {
                                        setSelectedAchievement(item)
                                        setOpenEditAchievement(true);
                                    }}
                                />
                                <DeleteForeverOutlinedIcon
                                    onClick={() => {
                                        setSelectedAchievement(item);
                                        setDeleteItemType('achievement');
                                        setConfirmDeletePopup(true);
                                    }}
                                />
                            </Grid>}
                        </li>
                    })}
                </ul>
            </Grid>}

            {/* Skills */}
            <Grid className={`${classes.my2} ${classes.profileSectionContainer} ${classes.py3} ${classes.px2}`} style={{ marginBottom: 65 }} container direction='column'>
                <Grid
                    item
                    container
                    justifyContent="space-between"
                    alignItems="flex-end"
                    direction="row"
                    className={classes.pageHeading}
                >
                    <Grid
                        item
                        container
                        justifyContent="space-between"
                        alignItems="flex-end"
                        wrap='nowrap'
                    ><Typography variant="h3" color="primary">Skills
                        </Typography>
                        {props.displayMentorProile && <Grid className={classes.addProfileDetailIcon} item container xs={4} alignItems='center' justifyContent='flex-end'
                            onClick={() => {
                                setOpenEditSkill(true);
                                setSelectedSkill({});
                            }}
                        >
                            <AddOutlinedIcon className={classes.mr1} color='primary' fontSize='small' />
                            <Typography variant="h4" color="primary"> Add</Typography>
                        </Grid>}
                    </Grid>
                </Grid>
                {!props.displayMentorProile ? <Grid
                    item
                    container
                >
                    {userSkills?.map((skill, index) => {
                        return <Chip key={index} className={`${classes.my1} ${classes.mr1} ${classes.ttc}`} variant="outlined" label={skill.skill_name} />
                    })}
                </Grid> :
                    <ul>
                        {userSkills?.map((skill, index) => {
                            return <li key={index} className={`${classes.px1} ${classes.position_relative} ${classes.mb2}`}>
                                <Grid container direction='column' alignItems='flex-start' justifyContent='space-between' wrap='nowrap' >
                                    <Typography className={classes.ttc} variant='body1'>{skill.skill_name}</Typography>
                                    <Rating size='small' name="read-only" value={skill.rating} readOnly />
                                </Grid>
                                {props.displayMentorProile && <Grid item container justifyContent='flex-end' className={classes.editDeleteDetailsContainer}>
                                    <EditOutlinedIcon className={classes.mr1}
                                        onClick={() => {
                                            setSelectedSkill(skill)
                                            setOpenEditSkill(true);
                                        }} />
                                    <DeleteForeverOutlinedIcon onClick={() => {
                                        setSelectedSkill(skill);
                                        setDeleteItemType('skill');
                                        setConfirmDeletePopup(true);
                                    }} />
                                </Grid>}
                            </li>
                        })}
                    </ul>}
            </Grid>

            {!props.displayMentorProile && <Button variant='contained' color='secondary' className={`${classes.ttc} ${classes.py1} ${classes.px2} ${classes.sendReqBtn}`}
                onClick={() => {
                    if(!mentorDetails?.status[0] || mentorDetails?.status[0] === 'declined'){
                        props.setOpenMentorshipReqDialog(true);
                        props.setCurrentMentorId(mentor_id);
                    }

                }} >
                {mentorDetails?.status && mentorDetails?.status[0] === 'pending' ? <ScheduleIcon className={classes.mr1} fontSize='small' /> : mentorDetails?.status && mentorDetails?.status[0] === 'accepted' ? <DoneIcon className={classes.mr1} fontSize='small' /> : <PersonAddOutlinedIcon className={classes.mr1} fontSize='small' />}

                <Typography variant='body2'>{mentorDetails?.status && mentorDetails?.status[0] === 'pending' ? 'Request Pending' : mentorDetails?.status && mentorDetails?.status[0] === 'accepted' ? 'Connected' : 'Send mentoring request'}</Typography>
            </Button>}

            <EditPersonalInfo mentor_id={mentor_id} user_data={mentorDetails} openEditPersonalInfo={openEditPersonalInfo} setOpenEditPersonalInfo={setOpenEditPersonalInfo} setIsProfileDetailsUpdated={setIsProfileDetailsUpdated} handleSnackBar={props.handleSnackBar} />
            <EditQualification mentor_id={mentor_id} openEditQualification={openEditQualification} setOpenEditQualification={setOpenEditQualification} qualData={selectedQual} getMentorQualification={getMentorQualification} handleSnackBar={props.handleSnackBar} />
            <EditExperience openEditExperience={openEditExperience} setOpenEditExperience={setOpenEditExperience} mentor_id={mentor_id} expData={selectedExp} getMentorExperience={getMentorExperience} handleSnackBar={props.handleSnackBar} />
            <EditSkills openEditSkill={openEditSkill} setOpenEditSkill={setOpenEditSkill} mentor_id={mentor_id} skillData={selectedSkill} getMentorSkills={getMentorSkills} handleSnackBar={props.handleSnackBar} />
            <EditAchievement openEditAchievement={openEditAchievement} setOpenEditAchievement={setOpenEditAchievement} mentor_id={mentor_id} achievementData={selectedAchievement} getMentorAchievement={getMentorAchievement} handleSnackBar={props.handleSnackBar} />

            {/* Dialog to confirm deletion */}
            <Dialog open={confirmDeletePopup}>
                <DialogContent>
                    Are you sure want to delete this field?
                </DialogContent>
                <DialogActions>
                    <Grid className={classes.my3} item container justifyContent='flex-end'>
                        <Button className={`${classes.ttc} ${classes.mr2}`} color='primary' variant='outlined' onClick={() => setConfirmDeletePopup(false)}>Cancel</Button>
                        <Button className={classes.ttc} color='primary' variant='contained' onClick={() => deleteItemType === 'qual' ? deleteCurrentQualification(selectedQual) : deleteItemType === 'exp' ? deleteCurrentExperience(selectedExp) : deleteItemType === 'skill' ? deleteCurrentSkill(selectedSkill) : deleteCurrentAchievement(selectedAchievement)} >Yes</Button>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default FullProfile